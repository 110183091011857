import { CheckboxGroupInput, TextInput } from 'react-admin'
import { ScopedRole } from '../../../GeneratedGraphQLTypes'

export const defaultRoles = [
  ScopedRole.Admin,
  ScopedRole.CreditApprover,
  ScopedRole.SalesRep,
  ScopedRole.SuperAdmin,
  ScopedRole.MerchantManager,
]

export const roles = [
  { id: 'admin', name: 'Admin' },
  { id: 'salesRep', name: 'Sales rep' },
  { id: 'creditApprover', name: 'Credit approver' },
  { id: 'financeBro', name: 'Finance Bro' },
  { id: 'superAdmin', name: 'Super admin' },
  { id: 'merchantManager', name: 'Merchant Manager' },
]
export const postFilters = [
  <CheckboxGroupInput
    variant="standard"
    label="Choose scoped roles"
    source="scopedRoles"
    alwaysOn
    choices={roles}
  />,
  <TextInput variant="standard" label="Search by name" source="q" alwaysOn />,
]
