import { VirtualWalletListWrapper } from './styles'
import VirtualWalletListItem from './VirtualWalletListItem'
import cardsStore from './store'

const searchItem = (item, searchString) => {
  return item?.cardHolderName.toLowerCase().includes(searchString)
}

const VirtualWalletList = ({ searchString }) => {
  const { cards } = cardsStore()
  return (
    <VirtualWalletListWrapper>
      {cards &&
        cards
          .filter((item) => searchItem(item, searchString.toLowerCase()))
          .map((card) => <VirtualWalletListItem key={card.id} card={card} />)}
    </VirtualWalletListWrapper>
  )
}

export default VirtualWalletList
