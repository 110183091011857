import React, { useEffect } from 'react'
import Select from '../../lib/components/Select'
import { CardSelectWrapper } from './styles'
import { SelectRow } from '../../lib/styles'
import { getOrganizationsDropdown } from './queries'
import cardsStore from './store'

const CardsSelector = () => {
  const {
    organizationsDropdown,
    setOrganizationsDropdown,
    selectOrganization,
    selectedOrganization,
  } = cardsStore()

  useEffect(() => {
    getOrganizationsDropdown().then((res) => {
      if (res) {
        const result = res.data.organizations.edges.map((item) => {
          return item.edge
        })
        setOrganizationsDropdown(result)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const organizationList = organizationsDropdown.map((item, index) => {
    return (
      <SelectRow key={`organization_${index}`} onClick={() => selectOrganization(item.id)}>
        {item.name}
      </SelectRow>
    )
  })

  const activeOrganization = organizationsDropdown.find(
    (organization) => organization.id === selectedOrganization,
  )

  return (
    <>
      <CardSelectWrapper>
        <Select
          options={organizationList}
          selected={activeOrganization ? activeOrganization.name : ''}
          placeholder="Select organization"
        />
      </CardSelectWrapper>
    </>
  )
}

export default CardsSelector
