import React from 'react'
import { HubInviteButtonWrapper } from '../styles'
import { ReactComponent as Union } from '../assets/icons/Union.svg'
import { hubUrl } from '../environments'

const KycInviteButton = ({ label, token }: { label: string; token: string }) => {
  return (
    <HubInviteButtonWrapper
      onClick={(e) => {
        e.stopPropagation()
        window.open(`${hubUrl}/business_invite/${token}`)
      }}
    >
      <Union style={{ marginRight: '.25rem' }} />
      {label}
    </HubInviteButtonWrapper>
  )
}

export default KycInviteButton
