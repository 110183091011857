import React, { forwardRef, HTMLProps, useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import tw from 'twin.macro'
import { DatePickerButton, PlanLabel } from './styles'
import { PlanCondition } from '../../GeneratedGraphQLTypes'
import { usePlanConditionsState } from './store'
import { roundDateToMonth } from '../../lib/utils'

const DatePickerWrapper = tw.div``

interface StartingDateProps {
  currentCondition: PlanCondition
  index: number
  isReadOnly?: boolean
}
export const StartingDate = ({ currentCondition, isReadOnly, index }: StartingDateProps) => {
  const [minDate, setMinDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined)
  const { updatePlanCondition, planConditions, setError } = usePlanConditionsState()
  const DatePickerCustom = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>(
    ({ value, onClick }, ref) => (
      <DatePickerButton onClick={onClick} ref={ref} isDisabled={isReadOnly}>
        {DateTime.fromFormat(value as string, 'MM/dd/yyyy').toFormat('dd-MM-yyyy')}
      </DatePickerButton>
    ),
  )

  const firstDayOfNextMonth = DateTime.now().plus({ months: 1 }).startOf('month').toJSDate()

  const calculateMinPossibleDate = (index: number) => {
    if (index === 0) {
      // For the first index, it's just the first day of next month
      return DateTime.now().plus({ months: 1 }).startOf('month').toJSDate()
    } else {
      // For any other index, it's at least one month after the previous plan's startDate
      const previousPlanStartDate = DateTime.fromJSDate(
        new Date(planConditions[index - 1].startDate),
      )
      return previousPlanStartDate.plus({ months: 1 }).startOf('month').toJSDate()
    }
  }

  const calculateMaxPossibleDate = (index: number) => {
    // If this is not the last item in the array, set maxDate to the day before the next condition's startDate
    if (index < planConditions.length - 1) {
      const nextPlanStartDate = DateTime.fromJSDate(new Date(planConditions[index + 1].startDate))
      return nextPlanStartDate.minus({ days: 1 }).toJSDate()
    }
    // If this is the last item, there is no maxDate
    return undefined
  }

  const handleChange = (date: Date) => {
    if (!date) {
      setError('Starting date is required.')
      return
    }

    const dateMonth = roundDateToMonth(date)

    if (!!planConditions.length && dateMonth < new Date()) {
      setError('Starting month should be later than a current month.')
      return
    }

    setError('')
    updatePlanCondition(currentCondition.startDate, {
      startDate: date,
    })
  }

  useEffect(() => {
    setMinDate(calculateMinPossibleDate(index))
    setMaxDate(calculateMaxPossibleDate(index))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, planConditions])
  return (
    <DatePickerWrapper>
      <PlanLabel>Starting date:</PlanLabel>
      <DatePicker
        disabled={isReadOnly}
        selected={currentCondition.startDate}
        onChange={handleChange}
        customInput={<DatePickerCustom />}
        showMonthYearPicker
        onKeyDown={(event) => {
          event.preventDefault()
        }}
        minDate={minDate}
        maxDate={index === 0 ? firstDayOfNextMonth : maxDate}
      />
    </DatePickerWrapper>
  )
}
