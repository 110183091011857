import { useState } from 'react'
import tw from 'twin.macro'
import { InfoWrapper } from '../../Prospects/styles'
import { TextInput } from '@zevoy/common/src/components/TextInput'
import { DetailsUnderline, DetailsHeader } from '../styles'
import { Button } from '@zevoy/common/src/components/Buttons'
import { ComponentVariants, Sizes } from '@zevoy/common/src/constants'
import DeleteConfirmModal from './DeleteConfirmModal'
import { useParams } from 'react-router-dom'
import {
  useAcubizClearOrganizationIdMutation,
  useAcubizSetOrganizationIdMutation,
  useGetOrganizationAcubizQuery,
} from '../../../GeneratedGraphQLTypes'
import { toast } from 'react-toastify'
import { useLoadingToast } from '../../../lib/utils'

const MAX_USER_ID_LENGTH = 16

const OrganizationAcubizInputWrapper = tw(InfoWrapper)`w-110 p-0 pt-8`
const AcubizInput = tw(TextInput)`my-0`
const RoleDescription = tw.div`text-greyBlue text-opacity-70`
const ButtonWrapper = tw.div`flex flex-row justify-start items-center pt-4 gap-2`
const SaveButton = tw(Button)``
const DeleteButton = tw(Button)``

const OrganizationAcubizInput = () => {
  const { id: orgID } = useParams()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [AcubizOrganizationId, setAcubizOrganiztionId] = useState<string>('')
  const [cancelDialog, toggleCancelDialog] = useState<boolean>(false)
  const [enrollAcubiz, { loading }] = useAcubizSetOrganizationIdMutation()
  const [clearAcubizId] = useAcubizClearOrganizationIdMutation()

  useLoadingToast(loading)
  const { data, refetch } = useGetOrganizationAcubizQuery({
    variables: {
      id: orgID,
    },
    onCompleted: (data) => {
      setAcubizOrganiztionId(data?.organization.acubizOrgID ?? '')
    },
  })

  const handleSave = () => {
    setErrorMessage(undefined)
    if (!AcubizOrganizationId) {
      setErrorMessage(
        `${'The input cannot be empty!'} ${
          data?.organization.acubizOrgID ? 'Click "Delete" button to delete User ID.' : ''
        }`,
      )
      return
    }
    enrollAcubiz({
      variables: {
        orgID,
        acubizOrganizationID: AcubizOrganizationId,
      },
      onCompleted: () => {
        toast.success('Saving Success')
        refetch()
      },
      onError: (error) => {
        console.error(error)
        toast.error('Saving fail')
      },
    })
  }

  const handleDelete = () => {
    setErrorMessage(undefined)
    clearAcubizId({
      variables: {
        orgID,
      },
      onCompleted: () => {
        toggleCancelDialog(false)
        toast.success('Delete Success')
        refetch()
      },
      onError: (error) => {
        console.error(error)
        toast.error('Fail to delete')
      },
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(undefined)

    // Trim leading and trailing spaces
    const trimmedValue = event.target.value.trim()

    // Check for special characters
    if (/[@#$%^&*()+=[\]{}|,;:"'´`<>/?.!]/.test(trimmedValue.replace('-', ''))) {
      return
    }

    // Check length
    if (trimmedValue.length >= MAX_USER_ID_LENGTH) {
      setErrorMessage('The maximum number of characters allowed in this input field is 16.')
      return
    }

    setAcubizOrganiztionId(event.target.value.trim())
  }
  return (
    <OrganizationAcubizInputWrapper>
      <DetailsHeader>Enroll organization to Acubiz</DetailsHeader>
      <DetailsUnderline />
      <RoleDescription>
        Enroll this organization to Acubiz by entering their Acubiz organization ID
      </RoleDescription>
      <AcubizInput
        errorMessage={errorMessage}
        placeholder="Organization Acubiz ID"
        value={AcubizOrganizationId}
        width="260px"
        onChange={handleInputChange}
      />
      <ButtonWrapper>
        <SaveButton variant={ComponentVariants.Primary} size={Sizes.Small} onClick={handleSave}>
          Enroll
        </SaveButton>
        <DeleteButton
          variant={ComponentVariants.Secondary}
          size={Sizes.Small}
          onClick={() => {
            toggleCancelDialog(true)
          }}
          disabled={!data?.organization.acubizOrgID}
        >
          Remove
        </DeleteButton>
      </ButtonWrapper>

      {/* Modal pop up to confirm delete behavior. */}
      {cancelDialog && (
        <DeleteConfirmModal
          handleDelete={handleDelete}
          message={'We will stop seding transaction data for this card to Acubiz.'}
          toggleCancelDialog={toggleCancelDialog}
        />
      )}
    </OrganizationAcubizInputWrapper>
  )
}

export default OrganizationAcubizInput
