import React from 'react'
import { useNewProspectState } from './store'
import { OnboardingFeeComponent } from '../../OnboardingFeeComponent'

export const OnboardingFee = () => {
  const {
    onboardingFee,
    setOnboardingFee,
    country,
    onboardingFeeBillingDate,
    setOnboardingFeeBillingDate,
  } = useNewProspectState()
  return (
    <OnboardingFeeComponent
      onboardingFee={onboardingFee}
      onboardingFeeBillingDate={onboardingFeeBillingDate}
      setOnboardingFee={setOnboardingFee}
      setOnboardingFeeBillingDate={setOnboardingFeeBillingDate}
      country={country}
    />
  )
}
