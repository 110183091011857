import tw from 'twin.macro'
import { ModalInputLabel } from '../../../lib/styles'
import { InputWrapper } from '../../CreditApplication/styles'
import { useOrganizationChannelsQuery } from '../../../GeneratedGraphQLTypes'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Option } from '@zevoy/common/src/types/Generic'

type Props = {
  channelId: number | null | undefined
  setChannelId: (channel: number) => void
}

const Wrapper = tw(InputWrapper)`col-span-2`
export const OrganizationChannelSelection = ({ channelId, setChannelId }: Props) => {
  const { data } = useOrganizationChannelsQuery({
    fetchPolicy: 'cache-first',
  })

  const options: Option[] =
    data?.organizationChannels
      .filter((channel) => !channel.archived || channel.id === channelId)
      .map((channel) => ({
        value: channel.id,
        label: channel.channel,
      })) ?? []

  return (
    <Wrapper>
      <ModalInputLabel>Channel:</ModalInputLabel>
      <SelectComponent
        lightFont
        options={options}
        isSmall
        value={options.find((option) => option.value === channelId) ?? null}
        onChange={(channel) => {
          const selectChannelId = (channel as Option)?.value as number
          setChannelId(selectChannelId)
        }}
      />
    </Wrapper>
  )
}
