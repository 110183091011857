import React from 'react'
import { ModalInputLabel } from '../../../../../lib/styles'
import { ModalInputWrapper } from '../../../styles'
import tw from 'twin.macro'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Countries, CountryKey, Option } from '@zevoy/common/src/types/Generic'
import { DefaultPlan } from './types'
import { InputWrapper } from '../../../../CreditApplication/styles'
import { useNewProspectState } from './store'
import { PlanType } from '../../../../../GeneratedGraphQLTypes'
import { DEFAULT_PLANS_DETAILS } from './constants'
import { usePlanConditionsState } from '../../../../Plans/store'

const Wrapper = tw(InputWrapper)`text-left font-slussenLight`

export const ProspectCountry = () => {
  const { country, setCountry } = useNewProspectState()
  const { setPlanConditions, planConditions } = usePlanConditionsState()
  const countryOptions: Option[] = (Object.keys(Countries) as Array<CountryKey>).map((key) => ({
    label: Countries[key],
    value: key,
  }))
  return (
    <Wrapper>
      <ModalInputLabel>Country:</ModalInputLabel>
      <ModalInputWrapper>
        <SelectComponent
          options={countryOptions}
          value={countryOptions.find((countryOption: Option) => countryOption.value === country)}
          onChange={(newValue) => {
            const newCountry = newValue?.value as CountryKey
            setCountry(newCountry)
            setPlanConditions(
              planConditions.map((plan) => {
                if (plan.type === PlanType.Custom) {
                  return plan
                }
                const defaultPlan = DEFAULT_PLANS_DETAILS.find(
                  (planDetail: DefaultPlan) => planDetail.type === plan.type,
                )
                return {
                  ...plan,
                  amountPerUser: defaultPlan?.amountPerUser[newCountry],
                  amountPerBusinessCardUser: defaultPlan?.amountPerBusinessCardUser[newCountry],
                  amountPerClaimUser: defaultPlan?.amountPerClaimUser[newCountry],
                  amountPerSubscription: defaultPlan?.amountPerSubscription[newCountry],
                  minimumBillingFee: defaultPlan?.minimumBillingFee[newCountry],
                  fixedFee: defaultPlan?.fixedFee[newCountry] ?? 0,
                  freeClaimUsers: defaultPlan?.freeClaimUsers[newCountry] ?? 0,
                  freeBusinessCardUsers: defaultPlan?.freeBusinessCardUsers[newCountry] ?? 0,
                  freeSubscriptionCards: defaultPlan?.freeSubscriptionCards[newCountry] ?? 0,
                  freeClaimForBusinessCardUser:
                    defaultPlan?.freeClaimForBusinessCardUser[newCountry] ?? false,
                }
              }),
            )
          }}
        />
      </ModalInputWrapper>
    </Wrapper>
  )
}
