import create from 'zustand'
import { OrganizationStatus } from '../../GeneratedGraphQLTypes'

const organizationsStore = create((set) => ({
  organizations: [],
  // organization: {},
  filter: OrganizationStatus.CardOrderRestricted,
  activeUsers: [],
  nextCursor: null,
  nextPage: true,

  setOrganizations: (organizations) =>
    set((state) => ({
      ...state,
      organizations: [...state.organizations, ...organizations],
    })),

  // selectOrganization: (id) =>
  //   set((state) => ({
  //     ...state,
  //     organization: state.organizations.find(
  //       (organization) => organization.id === id,
  //     ),
  //   })),

  resetSelectOrganization: () => set({ organization: {} }),

  setFilter: (filter) => set({ filter }),

  setNextCursor: (nextCursor) => set({ nextCursor }),

  setNextPage: (nextPage) => set({ nextPage }),

  // setOrganization: (organization) =>
  //   set({
  //     organization,
  //     activeUsers: organization.members?.edges.map((e) => e.edge),
  //   }),

  resetOrganizations: () => set({ organizations: [], nextCursor: null, nextPage: true }),
}))

export default organizationsStore
