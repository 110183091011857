import tw from 'twin.macro'

export const VirtualWalletHeaderWrapper = tw.div`flex-grow flex flex-row pt-4 text-xs font-slussenLight`
export const VirtualWalletMainWrapper = tw.div`flex-grow flex flex-col text-xs font-slussenLight`
export const VirtualWalletCardholderHeaderName = tw.div`w-3/4 font-slussenSemibold pl-12`
export const VirtualWalletMaskedCardNumber = tw(VirtualWalletCardholderHeaderName)`opacity-50 pl-3`
export const VirtualWalletTokenRefIDLabel = tw(VirtualWalletCardholderHeaderName)`opacity-50 pl-3`
export const VirtualWalletRequestorIDLabel = tw(VirtualWalletCardholderHeaderName)`opacity-50 pl-3`
export const VirtualWalletProviderLabel = tw(VirtualWalletCardholderHeaderName)`opacity-50 pl-3`

export const VirtualWalletTokenItem = tw.div`w-3/4 font-slussenLight pl-4`
export const VirtualWalletRowHeader = tw.div`flex flex-row`
export const VirtualWalletListRowHeader = tw.div`flex flex-row w-full pl-16 py-2`

export const VirtualWalletListWrapper = tw.div`
  flex flex-col space-y-2 pt-6
`
export const VirtualWalletListRow = tw(VirtualWalletMainWrapper)`
  hover:shadow-heavy rounded-xl py-4 text-sm border-b-2 w-full
`
export const CardSelectWrapper = tw.div`pl-6`

export const VirtualWalletExpanded = tw.div`
 w-full flex flex-col
`
export const VirtualWalletCardWrapper = tw.div`flex flex-col space-y-4`
export const VirtualWalletCard = tw.div`
  bg-white rounded shadow-lg p-10 overflow-hidden w-full
`
export const ChevronWrapper = tw.div`w-5 h-5 ml-4 rounded-full hover:bg-lightGrey flex items-center justify-center`

// export const UsersCardholdersFilterOptions = styled.section(({ selected }) => [
//   tw`flex flex-row text-menuGrey opacity-50 space-x-2 py-2 hover:bg-lightGrey cursor-default justify-center items-center font-slussenSemibold`,
//   selected && tw`font-slussenSemibold border-b-2 border-black opacity-100`,
// ])
