import React from 'react'
import { DetailsLabel } from '../styles'
import tw from 'twin.macro'
import { Checkbox, CheckboxWrapper } from '../../../lib/styles'

const Wrapper = tw.div`font-slussenLight mt-2`
const UboRow = tw.div`flex mt-2`
const UboName = tw.div``
const UboPepStatus = tw.div`ml-auto mr-5`
const UboHeaders = tw.div`flex`
const UboPepHeader = tw(DetailsLabel)`ml-auto mr-2`

export const UboTable = ({ ubos }) => {
  return (
    <Wrapper>
      <UboHeaders>
        <DetailsLabel>Name</DetailsLabel>
        <UboPepHeader>Is PEP?</UboPepHeader>
      </UboHeaders>
      {ubos.map((ubo) =>
        ubo ? (
          <UboRow key={ubo.token}>
            <UboName>{ubo.fullName ?? ''}</UboName>
            <UboPepStatus>
              <CheckboxWrapper>
                <Checkbox name="pep_result" type="checkbox" checked={ubo.isPEP ?? false} />
              </CheckboxWrapper>
            </UboPepStatus>
          </UboRow>
        ) : null,
      )}
    </Wrapper>
  )
}
