import React from 'react'
import { hubUrl } from '../../../../lib/environments'
import {
  DetailsChevronWrapper,
  DetailsInviteButton,
  DetailsInviteButtonWrapper,
  DetailsInviteCol,
  DetailsInviteHeading,
  DetailsInviteText,
  DetailsInviteWrapper,
  InfoWrapper,
} from '../../styles'
import { ReactComponent as ChevronRight } from '../../../../lib/assets/icons/chevronRight.svg'
import { useOpenKycSessionMutation } from '../../../../GeneratedGraphQLTypes'
import { useProspectCardState } from './store'

export const BusinessInvitation = () => {
  const [openKycSession] = useOpenKycSessionMutation()
  const { prospect } = useProspectCardState()

  const handleOpenKycSession = async (prospectId: string, urlPrefix: string) => {
    try {
      const response = await openKycSession({
        variables: {
          id: prospectId,
        },
      })
      const sessionToken = response?.data?.openKYCSession?.session?.token ?? ''
      window.open(`${urlPrefix}/business_invite/${sessionToken}`)
    } catch (e) {
      const error: Error = e as Error
      console.error(error?.message ?? e)
    }
  }
  if (!prospect) {
    return null
  }
  return (
    <InfoWrapper>
      <DetailsInviteWrapper
        onClick={async (e) => {
          e.stopPropagation()
          await handleOpenKycSession(prospect.id, hubUrl)
        }}
      >
        <DetailsInviteCol>
          <DetailsInviteHeading>Business Invitation</DetailsInviteHeading>
          <DetailsInviteText>
            {`Visit the ${prospect.name} Business Inivation in ghost mode`}
          </DetailsInviteText>
        </DetailsInviteCol>
        <DetailsInviteButtonWrapper>
          <DetailsInviteButton>Ready</DetailsInviteButton>
        </DetailsInviteButtonWrapper>
        <DetailsChevronWrapper>
          <ChevronRight />
        </DetailsChevronWrapper>
      </DetailsInviteWrapper>
    </InfoWrapper>
  )
}
