import React from 'react'
import { useParams } from 'react-router-dom'
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useGetOne,
  useList,
} from 'react-admin'
import { BenefitApplicationSignersWrapper, BenefitsOptionDescription, OptionTitle } from './styled'
import { KycMerchantApplication, MerchantApplicationInvite } from '../../../GeneratedGraphQLTypes'
import { ZevoyList } from '../styles'
import { LabelComponent } from './BenefitApplicationList'
import { FormattedDate } from '../IncidentCommunication/IncidentCommunicationList'
import tw from 'twin.macro'

const EmptyListWrapper = tw.div`p-5 font-slussenSemibold`

const EmptyList = () => {
  return <EmptyListWrapper>There is no invitation for this application yet</EmptyListWrapper>
}

export const BenefitApplicationInvites = () => {
  const { id } = useParams()
  const { data: benefitApplicationData } = useGetOne<KycMerchantApplication>('benefitApplication', {
    id: id as any,
  })

  const listContext = useList({ data: benefitApplicationData?.invites ?? undefined })

  return (
    <BenefitApplicationSignersWrapper>
      <BenefitsOptionDescription>
        <OptionTitle>Invites</OptionTitle>
      </BenefitsOptionDescription>
      <ListContextProvider value={listContext}>
        <ZevoyList>
          <Datagrid bulkActionButtons={false} empty={<EmptyList />}>
            <TextField source="fullName" label={<LabelComponent labelName={'Full name'} />} />
            <TextField source="email" label={<LabelComponent labelName={'Email'} />} />
            <BooleanField source="isSignatory" label={<LabelComponent labelName={'Signer'} />} />
            <TextField
              source="createdByUser.fullName"
              label={<LabelComponent labelName={'Created by'} />}
            />
            <FunctionField
              source="createTime"
              label={<LabelComponent labelName={'Create time'} />}
              render={(record: MerchantApplicationInvite) => (
                <FormattedDate value={record.createTime} />
              )}
            />
            <FunctionField
              source="expiresOn"
              label={<LabelComponent labelName={'Expired on'} />}
              render={(record: MerchantApplicationInvite) => (
                <FormattedDate value={record.expiresOn} />
              )}
            />
          </Datagrid>
        </ZevoyList>
      </ListContextProvider>
    </BenefitApplicationSignersWrapper>
  )
}
