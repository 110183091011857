import { SelectInput, TextInput } from 'react-admin'
import capitalize from 'lodash/capitalize'
import { PaymentBatchStatus, PaymentTransactionStatus } from '../../../GeneratedGraphQLTypes'

const batchStatuses = Object.values(PaymentBatchStatus).map((status) => ({
  id: status,
  name: capitalize(status),
}))

export const batchFilters = [
  <SelectInput
    variant="standard"
    choices={batchStatuses}
    label="Status"
    source="statuses"
    alwaysOn
  />,
  <TextInput variant="standard" label="Search" source="q" alwaysOn />,
]

const transactionStatuses = Object.values(PaymentTransactionStatus).map((status) => ({
  id: status,
  name: capitalize(status),
}))

export const transactionFilters = [
  <SelectInput
    variant="standard"
    multiline
    choices={transactionStatuses}
    label="Status"
    source="statuses"
    alwaysOn
  />,
  <TextInput variant="standard" label="Search" source="q" alwaysOn />,
]
