import {
  DetailsChevronWrapper,
  DetailsInviteButton,
  DetailsInviteButtonWrapper,
  DetailsInviteCol,
  DetailsInviteHeading,
  DetailsInviteSwitcherWrapper,
  DetailsInviteText,
} from '../../styles'
import Slider from '../../../../lib/components/Slider'
import { useNavigate } from 'react-router-dom'

export const BezalaActivation = ({ organization }) => {
  const navigate = useNavigate()
  const isBezalaActive = !!organization?.bezalaEnrollment?.enrollTime
  return (
    <DetailsInviteSwitcherWrapper>
      <DetailsInviteCol>
        <DetailsInviteHeading>Activate Bezala</DetailsInviteHeading>
        <DetailsInviteText>
          {isBezalaActive
            ? 'Bezala was activated and cannot be deactivated through UI'
            : 'Bezala activation toggler'}
        </DetailsInviteText>
      </DetailsInviteCol>
      <DetailsInviteButtonWrapper>
        <DetailsInviteButton>{isBezalaActive ? 'On' : 'Off'}</DetailsInviteButton>
      </DetailsInviteButtonWrapper>
      <DetailsChevronWrapper>
        <Slider
          selected={isBezalaActive}
          onClick={() => {
            if (!isBezalaActive) {
              navigate('activate-bezala')
            }
          }}
        />
      </DetailsChevronWrapper>
    </DetailsInviteSwitcherWrapper>
  )
}
