import create from 'zustand'
type ErrorState = {
  error: boolean
  errorObject: any
  requestId?: string
  setError: (error: boolean, errorObject: any, requestId: string | undefined) => void
}
const errorStore = create<ErrorState>((set) => ({
  error: false,
  errorObject: undefined,
  requestId: undefined,
  setError: (error, errorObject, requestId) => set({ error, errorObject, requestId }),
}))

export default errorStore
