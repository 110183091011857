import { ColorVariants } from '../../constants'
import tw, { css } from 'twin.macro'

const error = css`
  ${tw`bg-zevoyNegativeLight border-zevoyNegative`}
`

const info = css`
  ${tw`bg-zevoyGray4 border-zevoyGray3`}
`
const special = css`
  ${tw`bg-zevoyPurple-200 border-zevoyPurple`}
`

const success = css`
  ${tw`bg-zevoyPositiveLight border-zevoyPositive`}
`

const warning = css`
  ${tw`bg-zevoyOrangeLight border-zevoyOrange`}
`

export const InfoBoxVariantStyles = {
  [ColorVariants.Error]: error,
  [ColorVariants.Info]: info,
  [ColorVariants.Special]: special,
  [ColorVariants.Success]: success,
  [ColorVariants.Warning]: warning,
}
