import tw, { styled } from 'twin.macro'

export interface TabProps {
  selected?: boolean
}

export const Tabs = tw.ul`flex flex-row list-none gap-2 justify-between border-0 border-b border-lightGrey border-solid p-0 m-0`
export const Tab = styled.li<TabProps>`
  ${tw`cursor-pointer flex-grow text-center py-2 font-slussenLight uppercase text-xs font-slussenSemibold`}
  ${({ selected }) =>
    selected ? tw`border-darkGrey border-0 border-b-2 border-solid h-full` : tw``}
`

export const ZevoyList = tw.div`border border-r-4 border-lightGrey border-solid`
