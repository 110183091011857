import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import Home from '../Home/Home'
import { ScreenWrapper } from '../../lib/styles'

const HomeScreen = () => {
  return (
    <ScreenWrapper>
      <Sidebar />
      <Home />
    </ScreenWrapper>
  )
}

export default HomeScreen
