import React, { useEffect, useState } from 'react'
import VirtualWalletHeader from './VirtualWalletHeader'
import VirtualWalletList from './VirtualWalletList'
import CardsSelector from './VirtualWalletPicker'
import { ContentWrapper } from '../../lib/styles'
import Header from '../../lib/components/Header'
import Searchbar from '../../lib/components/Searchbar'

import { GetOrgWalletTokens } from './queries'
import cardsStore from './store'

const VirtualWallet = () => {
  const [searchString, setSearchString] = useState('')
  const { setCards, selectedOrganization } = cardsStore()

  useEffect(() => {
    if (selectedOrganization !== '') {
      GetOrgWalletTokens(selectedOrganization).then((res) => {
        if (res) {
          const result = res.cards.edges.map((card) => {
            return card.edge
          })
          setCards(result.filter((item) => item.walletTokens.length !== 0))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization])

  return (
    <ContentWrapper>
      <Header
        title="Virtual wallet"
        searchBar={
          <Searchbar onChange={setSearchString} placeholder="Search with cardholder name" />
        }
      />
      <CardsSelector />
      <VirtualWalletHeader />
      <VirtualWalletList searchString={searchString} />
    </ContentWrapper>
  )
}

export default VirtualWallet
