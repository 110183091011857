import { gql } from '@apollo/client'

export const getOrganizationChannels = gql`
  query organizationChannels {
    organizationChannels {
      id
      channel
      archived
    }
  }
`
export const ORG_NOTE = gql`
  fragment orgNote on OrganizationNote {
    cardPotential
    claimsUsers
    subscriptionPotential
    spendPotential
    channel {
      id
      channel
    }
  }
`

export const UPDATE_ORGANIZATION_NOTES = gql`
  ${ORG_NOTE}
  mutation updateOrganizationNote($id: OrganizationID!, $input: OrganizationNoteInput!) {
    updateOrganizationNote(id: $id, input: $input) {
      success
      organization {
        id
        note {
          ...orgNote
        }
      }
    }
  }
`
