import { useEffect, useMemo, useState } from 'react'
import { DetailsHeader, DetailsUnderline, ModalInputLabel } from '../styles'
import tw from 'twin.macro'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Option } from '@zevoy/common/src/types/Generic'
import {
  AccountStatus,
  AccountStatusInput,
  Organization,
  useDisableInterestPostingMutation,
  useEnableInterestPostingMutation,
  useUpdateOrganizationAccountStatusMutation,
  useUpdatePaymentTermsMutation,
} from '../../../GeneratedGraphQLTypes'
import { SaveButton } from '../../../lib/styles'
import { toast } from 'react-toastify'
import Slider from '../../../lib/components/Slider'
import { useParams } from 'react-router-dom'
import { sortBy } from 'lodash'

const Wrapper = tw.div`flex flex-col w-full items-start space-y-5 p-0 pt-8 `
const Grid = tw.div`grid grid-cols-3 gap-4`
const Cell = tw.div`flex flex-col gap-2`
const CellEnd = tw.div`flex flex-col items-start justify-end`
const CellRowSpan3 = tw(Cell)`row-span-3`

const PAYMENT_TERMS_14 = 14
const PAYMENT_TERMS_30 = 30
const DEFAULT_PAYMENT_TERMS = PAYMENT_TERMS_14

const statusOptions: Option[] = [
  {
    label: 'ACCOUNT_OK',
    value: AccountStatusInput.AccountOk,
  },
  {
    label: 'ACCOUNT_BLOCKED',
    value: AccountStatusInput.AccountBlocked,
  },
  {
    label: 'ACCOUNT_TO_CLOSE',
    value: AccountStatusInput.AccountToClose,
  },
]

const mapAccountStatusToInput = (status: AccountStatus): string => {
  switch (status) {
    case AccountStatus.Ok:
      return AccountStatusInput.AccountOk
    case AccountStatus.Blocked:
      return AccountStatusInput.AccountBlocked
    case AccountStatus.ToClose:
      return AccountStatusInput.AccountToClose
  }
  return ''
}

type Props = {
  organization: Organization
}

const AVAILABLE_PAYMENT_TERM_OPTIONS = sortBy(
  [
    { label: '14', value: PAYMENT_TERMS_14 },
    { label: '30', value: PAYMENT_TERMS_30 },
  ],
  'value',
)

export const EnfuceAccounts = ({ organization }: Props) => {
  const { id } = useParams()

  const [accountId, setAccountId] = useState<string>('')

  const [paymentTerms, setPaymentTerms] = useState<number>(DEFAULT_PAYMENT_TERMS)

  const [updateOrgAccountStatus] = useUpdateOrganizationAccountStatusMutation()
  const [enableInterestPosting] = useEnableInterestPostingMutation()
  const [disableInterestPosting] = useDisableInterestPostingMutation()
  const [updatePaymentTerms] = useUpdatePaymentTermsMutation({
    variables: {
      organizationID: id,
      accountID: accountId,
      paymentTerms,
    },
  })

  const options = useMemo(
    () =>
      organization.allAccounts.map((account) => {
        return {
          label: account.name,
          value: account.id,
        }
      }),
    [organization],
  )

  const [paymentTermsOptions, setPaymentTermsOptions] = useState<Option[]>(
    AVAILABLE_PAYMENT_TERM_OPTIONS,
  )

  const [status, setStatus] = useState<string>('')
  const [interestPosting, setInterestPosting] = useState<boolean>(false)

  const currentAccount = useMemo(() => {
    return organization.allAccounts.find((account) => account.id === accountId)
  }, [accountId, organization.allAccounts])

  const currentStatus = useMemo(() => {
    return currentAccount ? mapAccountStatusToInput(currentAccount.status) : ''
  }, [currentAccount])

  const currentInterestPosting = useMemo(() => {
    return currentAccount?.interestPostingEnabled ?? true
  }, [currentAccount?.interestPostingEnabled])

  const currentPaymentTerms = useMemo(() => {
    return currentAccount?.paymentTerms ?? DEFAULT_PAYMENT_TERMS
  }, [currentAccount?.paymentTerms])

  useEffect(() => {
    if (currentStatus) {
      setStatus(currentStatus)
    }
  }, [currentStatus])

  useEffect(() => {
    setInterestPosting(currentInterestPosting)
  }, [currentInterestPosting])

  useEffect(() => {
    if (!AVAILABLE_PAYMENT_TERM_OPTIONS.find((option) => option.value === currentPaymentTerms)) {
      setPaymentTermsOptions(
        sortBy(
          [
            ...AVAILABLE_PAYMENT_TERM_OPTIONS,
            { label: currentPaymentTerms.toString(), value: currentPaymentTerms },
          ],
          'value',
        ),
      )
    } else setPaymentTermsOptions(AVAILABLE_PAYMENT_TERM_OPTIONS)

    setPaymentTerms(currentPaymentTerms)
  }, [currentPaymentTerms])

  const handleSaveStatus = async () => {
    try {
      await updateOrgAccountStatus({
        variables: {
          input: {
            accountID: accountId,
            status: status as AccountStatusInput,
          },
        },
      })
      toast.success('Organization account status saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }

  const handleSaveInterestPosting = async () => {
    try {
      if (interestPosting) {
        await enableInterestPosting({
          variables: {
            accountID: accountId,
          },
        })
      } else {
        await disableInterestPosting({
          variables: {
            accountID: accountId,
          },
        })
      }
      toast.success('Account interest posting saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }

  const handleSavePaymentTerms = async () => {
    try {
      await updatePaymentTerms()
      toast.success('Payment terms saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }

  return (
    <Wrapper>
      <DetailsHeader>Enfuce Accounts</DetailsHeader>
      <DetailsUnderline />
      <Grid>
        <CellRowSpan3>
          <ModalInputLabel>Account:</ModalInputLabel>
          <SelectComponent
            width={'13rem'}
            options={options}
            value={options.find((option) => option.value === accountId)}
            onChange={(newValue) => setAccountId(newValue?.value)}
          />
        </CellRowSpan3>
        <Cell>
          <ModalInputLabel>Status:</ModalInputLabel>
          <SelectComponent
            width={'13rem'}
            isDisabled={accountId === '' || currentStatus === ''}
            options={statusOptions}
            value={statusOptions.find((option) => option.value === status)}
            onChange={(newValue) => setStatus(newValue?.value as string)}
          />
        </Cell>
        <CellEnd>
          <SaveButton
            onClick={handleSaveStatus}
            disabled={status === '' || status === currentStatus || accountId === ''}
          >
            Save Account Status
          </SaveButton>
        </CellEnd>
        <Cell>
          <ModalInputLabel>Payment Terms</ModalInputLabel>
          <SelectComponent
            options={paymentTermsOptions}
            isDisabled={accountId === ''}
            value={paymentTermsOptions.find((option) => option.value === paymentTerms)}
            onChange={(newValue) => setPaymentTerms(newValue?.value as number)}
          />
        </Cell>
        <CellEnd>
          <SaveButton
            onClick={handleSavePaymentTerms}
            disabled={
              accountId === '' ||
              currentPaymentTerms === paymentTerms ||
              !AVAILABLE_PAYMENT_TERM_OPTIONS.find((option) => option.value === paymentTerms)
            }
          >
            Save Payment Terms
          </SaveButton>
        </CellEnd>
        <Cell>
          <ModalInputLabel>Interest Posting:</ModalInputLabel>
          <Slider
            selected={interestPosting}
            onClick={() => setInterestPosting(!interestPosting)}
            disabled={accountId === ''}
          />
        </Cell>
        <CellEnd>
          <SaveButton
            onClick={handleSaveInterestPosting}
            disabled={accountId === '' || interestPosting === currentInterestPosting}
          >
            Save Interest Posting
          </SaveButton>
        </CellEnd>
      </Grid>
    </Wrapper>
  )
}
