import React from 'react'
import { InputWrapper, ModalInputLabel } from '../../../styles'
import { useProspectCardState } from '../store'
import { Input } from '../../../../../lib/styles'

export const NameOnCard = () => {
  const { prospect, updateProspect } = useProspectCardState()
  return (
    <InputWrapper>
      <ModalInputLabel>Company Name:</ModalInputLabel>
      <Input
        value={prospect?.name ?? ''}
        onChange={(e) => {
          updateProspect({
            name: e.target.value,
          })
        }}
      />
    </InputWrapper>
  )
}
