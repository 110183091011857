import React, { useState } from 'react'
import { getEidAuthUrl } from './queries'

import {
  SelectRow,
  SectionWrapper,
  LoginBodyWrapper,
  LoginButtonWrapper,
  OrangeButton,
  ButtonTextWrapper,
  PageTitle,
} from './styles'

import Select from '../../lib/components/Select'

import { ReactComponent as RightArrow } from '../../lib/assets/icons/rightArrowWhite.svg'

import { ErrorBoundary } from '@zevoy/common/src/components/Error/ErrorBoundary'

import { ReactComponent as FiFlag } from '@zevoy/common/assets/flags/fi_flag.svg'
import { ReactComponent as LvFlag } from '@zevoy/common/assets/flags/lv_flag.svg'
import { ReactComponent as LtFlag } from '@zevoy/common/assets/flags/lt_flag.svg'
import { ReactComponent as SeFlag } from '@zevoy/common/assets/flags/se_flag.svg'
import { ReactComponent as EeFlag } from '@zevoy/common/assets/flags/ee_flag.svg'

const zevopsCountryOptions = [
  {
    country: 'Finland',
    countryCode: 'FI',
    flagIcon: <FiFlag />,
  },
  {
    country: 'Sweden',
    countryCode: 'SE',
    flagIcon: <SeFlag />,
  },
  {
    country: 'Estonia',
    countryCode: 'EE',
    flagIcon: <EeFlag />,
  },
  {
    country: 'Latvia',
    countryCode: 'LV',
    flagIcon: <LvFlag />,
  },
  {
    country: 'Lithuania',
    countryCode: 'LT',
    flagIcon: <LtFlag />,
  },
  {
    country: 'Developer login',
    countryCode: 'FAKE',
    flagIcon: null,
  },
]

const Login = () => {
  const [countryCode, setCountryCode] = useState('FI')
  const [countryPicker, selectCountry] = useState(zevopsCountryOptions[0])
  const loginWithSignicat = async () => {
    const eidAuthUrl = await getEidAuthUrl(countryCode, 'redirect')
    window.open(eidAuthUrl.data.eidAuth.authURL, '_self')
  }
  return (
    <ErrorBoundary>
      <LoginBodyWrapper>
        <SectionWrapper>
          <PageTitle>Zevops Login</PageTitle>
          {/*  TODO substitute with SelectCompopnent from common */}
          <Select
            fullWidth
            selected={
              <SelectRow>
                {countryPicker.flagIcon}
                <div>{countryPicker.country}</div>
              </SelectRow>
            }
            options={zevopsCountryOptions.map((country) => (
              <div
                onClick={() => {
                  selectCountry(country)
                  setCountryCode(country.countryCode)
                }}
                key={`${country.countryCode}_select_opt`}
              >
                <SelectRow>
                  {country.flagIcon}
                  <div>{country.country}</div>
                </SelectRow>
              </div>
            ))}
          />
          <LoginButtonWrapper>
            <OrangeButton
              onClick={() => {
                loginWithSignicat()
              }}
            >
              <ButtonTextWrapper>
                <div>Login</div>
                <RightArrow />
              </ButtonTextWrapper>
            </OrangeButton>
          </LoginButtonWrapper>
        </SectionWrapper>
      </LoginBodyWrapper>
    </ErrorBoundary>
  )
}

export default Login
