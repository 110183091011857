import React from 'react'
import { ModalInputLabel } from '../../../lib/styles'
import { InputWrapper } from '../../CreditApplication/styles'
import { Toggle } from '@zevoy/common/src/components/Toggle/Toggle'
import { NilableBoolean } from './types'

type Props = {
  subscriptionPotential: NilableBoolean
  setSubscriptionPotential: (potential: boolean) => void
}

export const SubscriptionPotential = ({
  subscriptionPotential,
  setSubscriptionPotential,
}: Props) => {
  return (
    <InputWrapper>
      <ModalInputLabel>Subscription Potential:</ModalInputLabel>
      <Toggle
        initialValue={subscriptionPotential ?? false}
        onChange={(value) => {
          setSubscriptionPotential(value)
        }}
      />
    </InputWrapper>
  )
}
