import React, { useState } from 'react'
import tw from 'twin.macro'
import { InfoWrapper } from '../../Prospects/styles'
import { DetailsHeader, DetailsUnderline } from '../styles'
import { TextInput } from '@zevoy/common/src/components/TextInput'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { useParams } from 'react-router-dom'
import { Option } from '@zevoy/common/src/types/Generic'
import { Button } from '@zevoy/common/src/components'
import { Sizes } from '@zevoy/common/src/constants'
import {
  useChangeOrganizationBankAccountMutation,
  useGetBankAccountsListQuery,
  useGetOrganizationBankAccountSettingQuery,
} from '../../../GeneratedGraphQLTypes'
import { toast } from 'react-toastify'

const OrganizationAcubizInputWrapper = tw(InfoWrapper)`w-110 p-0 pt-8`
const ButtonWrapper = tw.div`flex flex-row justify-start items-center pt-4 gap-2`
const PaymentReferenceInput = tw(TextInput)`my-0`
const SaveButton = tw(Button)``

const BankAccount = () => {
  const { id: organizationId } = useParams()

  const [paymentReferenceInput, setPaymentRerenceInput] = useState<string>('')
  const [selectedBankAccount, setSelectedBankAccount] = useState<Option | null>(null)
  const [changeBankAccount] = useChangeOrganizationBankAccountMutation()
  const { data: organizationBankAccountsList } = useGetBankAccountsListQuery({
    variables: {
      organizationId,
    },
  })

  const formattedBankAccountsList: Option[] =
    organizationBankAccountsList?.bankAccountsResults?.map((account) => ({
      value: account?.id,
      label: account?.name ?? '',
    })) ?? []

  useGetOrganizationBankAccountSettingQuery({
    variables: {
      organizationId,
    },
    onCompleted: (data) => {
      setPaymentRerenceInput(data?.organization.paymentReference ?? '')
      if (data.organization.bankAccount) {
        setSelectedBankAccount({
          label: data.organization.bankAccount?.name ?? '',
          value: data.organization.bankAccount?.id,
        })
      }
    },
  })

  const handleSave = () => {
    changeBankAccount({
      variables: {
        organizationID: organizationId,
        bankAccountID: selectedBankAccount?.value,
      },
    })
      .then(() => {
        toast.success('Successfully update bank account setting.')
      })
      .catch((error) => {
        console.error('Failed to update bank account setting.')
      })
  }

  const isSaveButtonDisabled = !(
    (paymentReferenceInput && selectedBankAccount) ||
    (!paymentReferenceInput && !selectedBankAccount)
  )

  return (
    <OrganizationAcubizInputWrapper>
      <DetailsHeader>Bank account setting</DetailsHeader>
      <DetailsUnderline />
      <SelectComponent
        label={'Bank account'}
        width="300px"
        options={formattedBankAccountsList}
        value={selectedBankAccount}
        onChange={(data) => setSelectedBankAccount(data as Option)}
        isClearable={true}
      />
      <PaymentReferenceInput
        label={'Payment reference'}
        placeholder="Add..."
        isNumeric
        value={paymentReferenceInput}
        width="300px"
        disabled
        maxLength={32}
      />
      <ButtonWrapper>
        <SaveButton onClick={handleSave} size={Sizes.Small} disabled={isSaveButtonDisabled}>
          Save
        </SaveButton>
      </ButtonWrapper>
    </OrganizationAcubizInputWrapper>
  )
}

export default BankAccount
