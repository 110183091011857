import tw, { styled } from 'twin.macro'

export const ProspectHeaderWrapper = tw.div`flex-grow flex flex-row pt-10 text-xs font-slussenLight`
export const ProspectHeaderName = tw.div`w-3/4 font-slussenSemibold pl-20 pt-1`
export const ProspectHeaderLabel = tw(ProspectHeaderName)`opacity-50 pl-4 pt-1`
export const ProspectItemSalesRepLabel = tw(ProspectHeaderName)`w-3/4 opacity-50 pl-1 pt-1`

export const ProspectItemName = tw.div`w-3/4 font-slussenSemibold pt-1 pl-6`
export const ProspectItemSalesRep = tw.div`w-3/4 font-slussenSemibold pt-1 pl-24`
export const ProspectEmployees = tw.div`w-3/4 font-slussenSemibold pl-14 pt-1`
export const ProspectDate = tw.div`w-3/4 font-slussenSemibold pl-14 pt-1`

export const ProspectOptionsButton = tw.div`hover:bg-lightGrey h-4 w-4 rounded-full flex items-center justify-center px-1 py-1 mr-4`
// export const ProspectHeaderLabel = tw(ProspectHeaderName)`opacity-50 pl-10`
export const ProspectHeaderEnd = tw(
  ProspectHeaderName,
)`flex justify-end opacity-50 items-center pr-6 pl-0`

export const ProspectPickerWrapper = tw.div`flex flex-row justify-start pl-6 py-4 space-x-4 justify-start items-center text-sm`

interface ProspectFilterOptionsProps {
  selected?: boolean
}
export const ProspectFilterOptions = styled.section(({ selected }: ProspectFilterOptionsProps) => [
  tw`flex flex-row text-menuGrey opacity-50 space-x-2 py-2 hover:bg-lightGrey cursor-default justify-center items-center font-slussenSemibold`,
  selected && tw`font-slussenSemibold border-b-2 border-menuGrey opacity-100`,
])

export const ModalPadding = tw.div`px-6 py-4 flex flex-col space-y-1`

export const ModalInputWrapper = tw.div`flex flex-col py-1 space-y-3`

export const ModalInputLabel = tw.label`flex justify-start font-slussenLight text-sm text-labelsGrey opacity-50`

export const PromptInfo = tw.div`font-slussenLight text-sm flex space-y-3 pt-4 pb-4`

export const StyledHeader = tw.div`
  text-menuGrey text-3xl font-slussenSemibold
`

export const ModalStyledHeader = tw.div`
  flex flex-row items-start text-menuGrey text-lg font-slussenSemibold
`

export const SectionWrapper = tw.div`
  flex flex-col w-full p-2 
`
export const HeaderSectionWrapper = tw(SectionWrapper)`
  border-b border-gray-800
`
export const Message = tw.p`text-labelsGrey pb-5 flex justify-start items-start`

export const ProspectListWrapper = tw.div`
  flex flex-col space-y-2 pt-6 overflow-y-auto
`

export const ProspectListRow = tw(ProspectHeaderWrapper)`
  hover:shadow-heavy rounded-xl py-2 text-sm
`
export const CardHeaderWrapper = tw.div`flex-grow flex flex-row px-4 text-xs font-slussenLight`
export const CardHeaderName = tw.div`w-3/4 font-slussenSemibold pl-12`
export const CardHeaderLabel = tw(CardHeaderName)`opacity-50 pl-3`

export const CardPickerWrapper = tw.div`flex flex-row justify-start pl-6 py-4 space-x-4 justify-start items-center text-sm`

interface CardFilterOptionsProps {
  selected?: boolean
}
export const CardFilterOptions = styled.section(({ selected }: CardFilterOptionsProps) => [
  tw`flex flex-row text-menuGrey opacity-50 space-x-2 py-2 hover:bg-lightGrey cursor-default justify-center items-center font-slussenSemibold`,
  selected && tw`font-slussenSemibold border-b-2 border-menuGrey opacity-100`,
])

export const InfoWrapper = tw.div`flex flex-col w-full items-start px-5 space-y-5 pt-2 w-full xl:w-2/5`
export const InfoHeader = tw.div`font-slussenSemibold border-b-2 border-opacity-25 w-full flex flex-row justify-start items-center text-sm pb-3`
export const ButtonsSection = tw.div`font-slussenSemibold flex flex-row justify-start items-center`

export const InputWrapper = tw.div`flex flex-col py-1 w-full space-y-3`
export const InputRow = tw.div`flex flex-row w-full space-x-4`
export const InputLabel = tw.label`font-slussenSemibold text-sm text-labelsGrey`
export const InputNumberPicker = tw.div`flex flex-row w-42 space-y-2`

export const Button = tw.button`
  justify-self-end bg-white text-sm
  hover:opacity-75 shadow-heavy py-0 px-6 rounded-full border-0
`
export const SaveButton = tw(
  Button,
)`font-slussenSemibold text-sm text-white bg-greyBlue bg-opacity-25 py-2`
export const CancelButton = tw(
  Button,
)`font-slussenSemibold text-sm text-greyBlue bg-white bg-opacity-25 py-2 border-0 shadow-sm`

export const ProspectSubtitle = tw.div`
  flex flex-col w-full text-labelsGrey text-sm font-slussenLight
`
// details

export const DetailsScreenWrapper = tw.section`flex bg-white`
export const DetailsHeader = tw.div`font-slussenSemibold border-b-2 border-opacity-25 w-full flex flex-row justify-start items-center text-base`
export const DetailsWrapper = tw.div`w-full pt-4 h-full pb-4`
export const AdditionalInfoText = tw.div`font-slussenLight text-sm flex-grow w-110`
export const DetailsLabel = tw.div`flex justify-start font-slussenLight text-sm text-labelsGrey opacity-50`
export const DetailsInputWrapper = tw.div`flex flex-col py-1 w-110 space-y-3`
export const DetailsTextArea = tw.textarea`bg-white font-slussenLight p-2 text-sm shadow-heavy h-24 border-0 w-100`
export const DetailsInviteWrapper = tw.div`flex flex-row bg-white pt-2 pb-4 pl-2 text-sm shadow-heavy rounded-lg focus:outline-none border-0 w-100 items-center cursor-pointer`
export const DetailsInviteHeading = tw.div`flex justify-start font-slussenSemibold text-base text-black pt-2 pb-2 pl-2`
export const DetailsInviteText = tw.div`flex justify-start font-slussenLight text-sm text-labelsGrey pl-2`
export const DetailsInviteButton = tw.div`font-slussenSemibold text-sm bg-brightOrange bg-opacity-10 text-brightOrange text-opacity-80 rounded flex justify-center items-center ml-2 mr-2 mt-2 p-1`
export const DetailsInviteCol = tw.div`flex flex-col justify-start items-start`
export const DetailsInviteButtonRow = tw.div`flex-row w-full justify-end items-end`
export const DetailsInviteButtonWrapper = tw.div`px-2 h-full`
export const DetailsChevronWrapper = tw.div`flex justify-center items-center mr-2 mt-2 p-1`
export const DetailsUnderline = tw.div`w-110 shadow-heavy`
