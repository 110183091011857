import tw from 'twin.macro'
import {
  ArrayField,
  Datagrid,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin'
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import get from 'lodash/get'
import { ResetButton } from './components/ResetButton'
import { CloseButton } from './components/CloseButton'
import { SegmentUpdate } from './components/SegmentUpdate'

const ButtonsWrapper = tw.div`flex flex-row items-center gap-2 mt-8`

const ExpirationDateField = ({ source }: { source: string; label: string }) => {
  const record = useRecordContext()
  const expirationDate = get(record, source!)

  if (!expirationDate) {
    return null
  }
  return (
    <div>
      {expirationDate.year} / {expirationDate.month}
    </div>
  )
}

export const CardShow = () => (
  <>
    <Show>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography>Card ID</Typography>
            <TextField source="id" />
          </Grid>
          <Grid item xs={4}>
            <Typography>Account ID</Typography>
            <TextField source="account.id" />
          </Grid>
          <Grid item xs={4}>
            <Typography>Organization name</Typography>
            <TextField source="organization.name" />
          </Grid>
          <Grid item xs={4}>
            <Typography>Cardholder name</Typography>
            <TextField source="cardHolderName" />
          </Grid>
          <Grid item xs={4}>
            <Typography>Card name</Typography>
            <TextField source="cardName" />
          </Grid>
          <Grid item xs={4}>
            <Typography>Card type</Typography>
            <TextField source="cardType" />
          </Grid>
          <Grid item xs={4}>
            <Typography>First four</Typography>
            <TextField source="firstFour" />
          </Grid>
          <Grid item xs={4}>
            <Typography>Last four</Typography>
            <TextField source="lastFour" />
          </Grid>
          <Grid item xs={4}>
            <Typography>Status</Typography>
            <TextField source="status" />
          </Grid>
          <Grid item xs={6}>
            <Typography>Expiration date</Typography>
            <ExpirationDateField label="Expiration date" source="expirationDate" />
          </Grid>
          <Grid item xs={6}>
            <Typography>Total transaction count</Typography>
            <TextField source="totalTransactionCount" />
          </Grid>
        </Grid>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="regional-block-content"
            id="regional-block-header"
          >
            <Typography>Regional Block Statuses</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayField source="regionalBlockStatuses">
              <Datagrid bulkActionButtons={false}>
                <TextField source="region" />
                <TextField source="status" />
              </Datagrid>
            </ArrayField>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="card-usage-limit-content"
            id="card-usage-limit-header"
          >
            <Typography>Card Usage Limit</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayField source="usageLimits">
              <Datagrid bulkActionButtons={false}>
                <TextField source="code" />
                <ArrayField source="values">
                  <Datagrid bulkActionButtons={false}>
                    <TextField source="code" />
                    <NumberField source="singleAmount" />
                    <NumberField source="count" />
                    <NumberField source="sumAmount" />
                  </Datagrid>
                </ArrayField>
              </Datagrid>
            </ArrayField>
          </AccordionDetails>
        </Accordion>

        <ButtonsWrapper>
          <ResetButton source="id" sortable={false} label="Reset Pin Attempt" />
          <CloseButton source="id" label="Close Due To Fraud" />
        </ButtonsWrapper>
        <SegmentUpdate source="segment" label="" />
      </SimpleShowLayout>
    </Show>
  </>
)
