import create, { GetState, SetState } from 'zustand'
import { prospectInfoStateChunk } from '../../../utils'
import { ProspectInfoState } from '../../../types'
import { StoreApi } from 'zustand/vanilla'

interface NewProspectState extends ProspectInfoState {
  error: string
  setError: (validationError: string) => void
  onboardingFee: string | null
  setOnboardingFee: (onboardingFee: string | null) => void
  onboardingFeeBillingDate: Date | null
  setOnboardingFeeBillingDate: (date: Date | null) => void
}

type ProspectInfoStateChunk = Partial<NewProspectState> & Required<ProspectInfoState>

export const useNewProspectState = create<NewProspectState>((set, get, api) => ({
  ...prospectInfoStateChunk(
    set as SetState<ProspectInfoStateChunk>,
    get as GetState<ProspectInfoStateChunk>,
    api as StoreApi<ProspectInfoStateChunk>,
  ),
  error: '',
  setError: (validationError: string) => set({ error: validationError }),
  onboardingFee: null,
  setOnboardingFee: (onboardingFee: string | null) => set({ onboardingFee }),
  onboardingFeeBillingDate: null,
  setOnboardingFeeBillingDate: (date: Date | null) => set({ onboardingFeeBillingDate: date }),
}))
