import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Confirm,
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useDataProvider,
  useGetOne,
  useList,
  useNotify,
  useRefresh,
} from 'react-admin'
import { BenefitApplicationSignersWrapper, BenefitsOptionDescription, OptionTitle } from './styled'
import {
  KycApplicationStatus,
  KycMerchantApplication,
  KycMerchantApplicationSign,
} from '../../../GeneratedGraphQLTypes'
import { ZevoyList } from '../styles'
import { LabelComponent } from './BenefitApplicationList'
import { FormattedDate } from '../IncidentCommunication/IncidentCommunicationList'
import tw from 'twin.macro'
import { Button } from '@zevoy/common/src/components'
import { ComponentVariants, Sizes } from '@zevoy/common/src/constants'

const ActionButtonsWrapper = tw.div`flex mt-3 gap-5`
const EmptyListWrapper = tw.div`p-5 font-slussenSemibold`

const EmptyList = () => {
  return <EmptyListWrapper>There is no invitation for this application yet</EmptyListWrapper>
}

export const BenefitApplicationSigners = () => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const { id } = useParams()
  const { data: benefitApplicationData } = useGetOne<KycMerchantApplication>('benefitApplication', {
    id: id as any,
  })

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmApprove, setConfirmApprove] = useState(false)

  const signatoriesWithId = benefitApplicationData?.signatories?.map((signatory) => ({
    ...signatory,
    id: `${signatory.signedByUser.id}-${id}`,
  }))

  const listContext = useList({ data: signatoriesWithId ?? undefined })

  const canApproveAndRejectApplication =
    benefitApplicationData?.status === KycApplicationStatus.Signed

  const approveApplication = async () => {
    dataProvider
      .approve('benefitApplication', {
        data: { applicationID: id },
      })
      .then(() => {
        notify('Approve successfully', { type: 'success' })
        refresh()
        setConfirmApprove(false)
      })
      .catch((error: any) =>
        notify(`Approve failed: ${error?.message ?? 'Unknown error'}`, { type: 'error' }),
      )
  }

  const rejectApplication = () => {
    dataProvider
      .reject('benefitApplication', {
        data: { applicationID: id },
      })
      .then(() => {
        notify('Reject successfully', { type: 'success' })
        refresh()
        setConfirmDelete(false)
      })
      .catch((error: any) =>
        notify(`Reject failed: ${error?.message ?? 'Unknown error'}`, { type: 'error' }),
      )
  }

  return (
    <BenefitApplicationSignersWrapper>
      <BenefitsOptionDescription>
        <OptionTitle>Signers</OptionTitle>
      </BenefitsOptionDescription>
      <ListContextProvider value={listContext}>
        <ZevoyList>
          <Datagrid bulkActionButtons={false} empty={<EmptyList />}>
            <TextField
              source="signedByUser.fullName"
              label={<LabelComponent labelName={'Signed by'} />}
            />
            <FunctionField
              source="signTime"
              label={<LabelComponent labelName={'Sign time'} />}
              render={(record: KycMerchantApplicationSign) => (
                <FormattedDate value={record.signTime} />
              )}
            />
          </Datagrid>
        </ZevoyList>
      </ListContextProvider>

      <ActionButtonsWrapper>
        <Button
          size={Sizes.Medium}
          disabled={!canApproveAndRejectApplication}
          onClick={() => setConfirmApprove(true)}
        >
          Approve
        </Button>
        <Button
          size={Sizes.Medium}
          variant={ComponentVariants.BorderRed}
          onClick={() => setConfirmDelete(true)}
          disabled={!canApproveAndRejectApplication}
        >
          Reject
        </Button>

        <Confirm
          isOpen={confirmDelete}
          title={`Confirm`}
          content={'Are you sure you want to reject this application?'}
          onConfirm={rejectApplication}
          onClose={() => {
            setConfirmDelete(false)
          }}
        />
        <Confirm
          isOpen={confirmApprove}
          title={`Confirm`}
          content={`Are you sure you want to approve this application?`}
          onConfirm={approveApplication}
          onClose={() => {
            setConfirmApprove(false)
          }}
        />
      </ActionButtonsWrapper>
    </BenefitApplicationSignersWrapper>
  )
}
