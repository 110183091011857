import React from 'react'
import { ContentWrapper } from '../../lib/styles'
import Header from '../../lib/components/Header'
import { ErrorBoundary } from '@zevoy/common/src/components/Error/ErrorBoundary'

const Home = () => {
  return (
    <ErrorBoundary>
      <ContentWrapper>
        <Header title="Welcome to Zevops" />
      </ContentWrapper>
    </ErrorBoundary>
  )
}

export default Home
