import React from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { TagValueStatus, TagType } from '../../../GeneratedGraphQLTypes'

const types = [
  { id: TagType.Fixed, name: 'Fixed' },
  { id: TagType.Free, name: 'Free' },
  { id: TagType.Open, name: 'Open' },
]
const statuses = [
  { id: TagValueStatus.Enabled, name: 'Enabled' },
  { id: TagValueStatus.Archived, name: 'Archived' },
]
export const tagFilters = [
  <SelectInput
    variant="standard"
    multiline
    choices={types}
    label="Types"
    source="types"
    alwaysOn
  />,
  <SelectInput
    variant="standard"
    multiline
    choices={statuses}
    label="Status"
    source="statuses"
    alwaysOn
  />,
  <TextInput variant="standard" label="Search" source="q" alwaysOn />,
]
