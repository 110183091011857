import { useEffect, useMemo } from 'react'
import Sidebar from '../../../Sidebar/Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { ContentWrapper, InfoWrapper, ScreenWrapper } from '../../../../lib/styles'
import Header from '../../../../lib/components/Header'
import BackButtonArrow from '../../../../lib/components/BackButton'
import { PlanCondition, useGetProspectQuery } from '../../../../GeneratedGraphQLTypes'
import { useProspectCardState } from './store'
import { DetailsScreenWrapper, DetailsWrapper } from '../../styles'
import { BusinessInvitation } from './BusinessInvitation'
import { ProspectInfo } from './ProspectInfo/ProspectInfo'
import { usePlanConditionsState } from '../../../Plans/store'
import { formatPlanConditions } from '../../utils'
import { OnboardingFee } from './OnboardingFee'
import { ProspectPlans } from './ProspectPlans'
import { Comments } from './Comments'
import tw from 'twin.macro'
import { AdditionalTerms } from './AdditionalTerms'
import {
  LoadingWrapper,
  LoadingDeprecated,
} from '@zevoy/common/src/components/Loading/LoadingDeprecated'
import { ComponentVariants } from '@zevoy/common/src/constants'
import { OrganizationNoteWithSave } from '../../../Organizations/Note'
import { CountryKey } from '@zevoy/common/src/types/Generic'
import { DefaultNote } from '../../../Organizations/Note/OrganizationNote'
import { omit } from 'lodash'

const AdditionalDetailsWrapper = tw(InfoWrapper)`pt-8 w-110`

export const ProspectDetails = () => {
  const { prospect, setProspect } = useProspectCardState()

  const existingNote = useMemo(() => {
    return omit({ ...prospect?.note, channelId: prospect?.note?.channel?.id ?? 1 } ?? DefaultNote, [
      '__typename',
      'channel',
    ])
  }, [prospect])

  const { setPlanConditions, setError } = usePlanConditionsState()
  const navigate = useNavigate()
  const { id } = useParams()
  const query = useGetProspectQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (query.data?.organization) {
      setProspect(query.data.organization)

      const formattedPlanConditions: PlanCondition[] = formatPlanConditions(
        query.data.organization?.plan?.conditions || [],
      )
      setError('')
      setPlanConditions(formattedPlanConditions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data])

  if (query.loading) {
    return (
      <LoadingWrapper>
        <LoadingDeprecated variant={ComponentVariants.Secondary} title={'Loading'} />
      </LoadingWrapper>
    )
  }
  if (!prospect) {
    return null
  }
  return (
    <ScreenWrapper>
      <Sidebar />
      <ContentWrapper>
        <Header
          title={prospect.name ?? ''}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate(-1)
              }}
            />
          }
          customLabel={'PROSPECTS /'}
        />

        <DetailsScreenWrapper>
          <DetailsWrapper>
            <BusinessInvitation />
            <ProspectInfo />

            <AdditionalDetailsWrapper>
              <OnboardingFee />
              <ProspectPlans />
              <AdditionalTerms />
              <Comments />
              <OrganizationNoteWithSave
                organizationId={prospect.id}
                note={existingNote}
                country={prospect.country as CountryKey}
              />
            </AdditionalDetailsWrapper>
          </DetailsWrapper>
        </DetailsScreenWrapper>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
