import * as React from 'react'
import tw, { styled, css } from 'twin.macro'
import { ButtonSizeStyles, StretchedButtonSizeStyles } from './ButtonSize'
import { ButtonVariantStyles } from './ButtonVariant'
import { ComponentVariants, Sizes } from '../../constants'

export type ButtonVariants = Extract<
  ComponentVariants,
  | ComponentVariants.Primary
  | ComponentVariants.Secondary
  | ComponentVariants.DisabledPrimary
  | ComponentVariants.DisabledSecondary
  | ComponentVariants.Light
  | ComponentVariants.Dark
  | ComponentVariants.Grey
  | ComponentVariants.BorderRed
  | ComponentVariants.BorderGrey
  | ComponentVariants.Borderless
  | ComponentVariants.BorderGradient
  | ComponentVariants.Gradient
  | ComponentVariants.Positive
>
export interface StyledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isStretched?: boolean
  size?: Sizes
  variant?: ButtonVariants
  width?: string
}

export const Button = styled('button')<StyledButtonProps>`
  cursor: pointer;
  ${tw`
    flex
    flex-row
    rounded-md
    shadow-none
    border
    border-solid
    text-center
    items-center
    justify-center
    font-slussenLight
    gap-2
    disabled:hover:cursor-not-allowed
  `}
  ${({ size = Sizes.Large, isStretched }) =>
    isStretched ? StretchedButtonSizeStyles[size] : ButtonSizeStyles[size]};
  ${({ variant = ComponentVariants.Primary }) => ButtonVariantStyles[variant]};
  ${({ width }) =>
    !!width &&
    css`
      width: ${width};
    `}
`
