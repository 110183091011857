import {
  CheckboxGroupInput,
  Edit,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  useNotify,
  useUpdate,
} from 'react-admin'

import { useNavigate } from 'react-router-dom'
import get from 'lodash/get'
import BackButtonArrow from '../../../lib/components/BackButton'
import { ContentWrapper, ScreenWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import { ScopedRole } from '../../../GeneratedGraphQLTypes'
import { ReadableScopedRole } from './UserList'

export const UserEdit = () => {
  const navigate = useNavigate()
  const notify = useNotify()
  const [update] = useUpdate()

  const saveRoles = (data: any) => {
    update(
      'scopedRoles',
      {
        id: data.id,
        data: {
          userID: data.id,
          scopedRoles: get(data, 'scopedRoles'),
        },
        previousData: {},
      },
      {
        onSuccess: () => {
          notify('Update successfully', { type: 'success' })
          navigate('/admin/user')
        },
        onError: (error: any) => {
          notify(`Update failed: ${error?.message ?? 'Unknown error'}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <ScreenWrapper>
      <ContentWrapper>
        <Header
          title={''}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate('/admin/user')
              }}
            />
          }
        />

        <Edit>
          <SimpleShowLayout>
            <SimpleForm toolbar={false} onSubmit={saveRoles}>
              <TextInput type="standard" source="fullName" label="Full Name" disabled />
              <CheckboxGroupInput
                source="scopedRoles"
                choices={[
                  { id: ScopedRole.Admin, name: ReadableScopedRole[ScopedRole.Admin] },
                  { id: ScopedRole.SalesRep, name: ReadableScopedRole[ScopedRole.SalesRep] },
                  {
                    id: ScopedRole.CreditApprover,
                    name: ReadableScopedRole[ScopedRole.CreditApprover],
                  },
                  {
                    id: ScopedRole.FinanceBro,
                    name: ReadableScopedRole[ScopedRole.FinanceBro],
                  },
                  {
                    id: ScopedRole.SuperAdmin,
                    name: ReadableScopedRole[ScopedRole.SuperAdmin],
                  },
                  {
                    id: ScopedRole.MerchantManager,
                    name: ReadableScopedRole[ScopedRole.MerchantManager],
                  },
                ]}
              />
              <SaveButton />
            </SimpleForm>
          </SimpleShowLayout>
        </Edit>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
