import { useCallback, useMemo, useState } from 'react'
import {
  Create,
  AutocompleteInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useWatch } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AccountStatus,
  ZevoyPaymentTransactionType,
  useGetOrganizationAccountsLazyQuery,
} from '../../../GeneratedGraphQLTypes'
import { ContentWrapper, ScreenWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import BackButtonArrow from '../../../lib/components/BackButton'
import {
  EnfuceDateTypes,
  EnfuceTextMandatoryTypes,
  EnfuceTextTypes,
  ZevoyTypeOptions,
} from './constants'

type Option = {
  id: string
  name: string
}

type AccountOption = Option & {
  currency: string
}

export const EnfuceTextInput = ({ source, disabled }: { source: string; disabled?: boolean }) => {
  const zevoyType: ZevoyPaymentTransactionType | undefined = useWatch({ name: 'zevoyType' })

  const required = !!zevoyType && EnfuceTextMandatoryTypes.includes(zevoyType)
  const validator = useCallback(
    (value: string): string | null => {
      if (zevoyType === ZevoyPaymentTransactionType.CustomerPayment) {
        if (!value.startsWith('Payment 20')) return 'Must always start with  "Payment 20 "'
      }
      return null
    },
    [zevoyType],
  )

  const defaultEnfuceText = useMemo(() => {
    if (zevoyType === ZevoyPaymentTransactionType.CustomerPayment) {
      return 'Payment 20'
    }
    return undefined
  }, [zevoyType])

  if (
    zevoyType === ZevoyPaymentTransactionType.CustomerCashback ||
    zevoyType === ZevoyPaymentTransactionType.CustomerCashbackReversal
  ) {
    return (
      <TextInput
        disabled
        fullWidth
        required
        source={source}
        label="Enfuce Text"
        defaultValue={'Cashback'}
      />
    )
  } else if (
    zevoyType === ZevoyPaymentTransactionType.RedemptionAccountFunds ||
    zevoyType === ZevoyPaymentTransactionType.RedemptionAccountFundsReversal
  ) {
    return (
      <TextInput
        disabled
        fullWidth
        required
        source={source}
        label="Enfuce Text"
        defaultValue={'Redemption of account funds'}
      />
    )
  }
  return (
    <TextInput
      disabled={disabled}
      fullWidth
      required={required}
      source={source}
      label="Enfuce Text"
      defaultValue={defaultEnfuceText}
      validate={validator}
    />
  )
}

export const PaymentTransactionCreate = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const notify = useNotify()
  const refresh = useRefresh()

  const batchId = new URLSearchParams(location.search).get('batchId')
  const [accountOptions, setAccountOptions] = useState<AccountOption[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<Option[]>([])
  const [zevoyType, setZevoyType] = useState<ZevoyPaymentTransactionType>(
    ZevoyPaymentTransactionType.CustomerPayment,
  )

  const [getOrgAccounts] = useGetOrganizationAccountsLazyQuery()

  const onOrganizationChange = (organizationId: string) => {
    if (!organizationId) return
    getOrgAccounts({
      variables: { id: organizationId },
      onCompleted: (data) => {
        const options = data.organization.accounts
          .filter((acc) => acc.status === AccountStatus.Ok)
          .map((acc) => ({
            id: acc.id,
            name: acc.id,
            currency: acc.currency,
          }))
        setAccountOptions(options)
        const currencies = data.organization.accounts
          .filter((acc) => acc.status === AccountStatus.Ok)
          .map((acc) => acc.currency)

        setCurrencyOptions(
          Array.from(new Set(currencies)).map((currency) => ({
            id: currency,
            name: currency,
          })),
        )
      },
    })
  }

  const onSuccess = () => {
    notify('New transaction created successfully', { type: 'success' })
    navigate(`/admin/paymentTransaction?filter={"batchId": ${batchId}}`)
    refresh()
  }

  return (
    <ScreenWrapper>
      <ContentWrapper>
        <Header
          title={'New Payment Transaction'}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate(`/admin/paymentTransaction?filter={"batchId": ${batchId}}`)
              }}
            />
          }
        />
        <Create title="New transaction" mutationOptions={{ onSuccess }}>
          <SimpleForm minWidth={'300px'} maxWidth={'450px'}>
            <TextInput
              source="batchId"
              label="Batch ID"
              fullWidth
              disabled
              required
              defaultValue={batchId}
            />
            {/* there is a bug realted to AutocompleteInput in the version of react-admin we are using https://github.com/marmelab/react-admin/issues/8182 */}
            <ReferenceInput source="organizationId" reference="organization">
              <AutocompleteInput
                onChange={onOrganizationChange}
                fullWidth
                isRequired
                label="Organization"
                optionText="name"
              />
            </ReferenceInput>
            <SelectInput
              source="enfuceAccountId"
              label="Enfuce Account ID"
              fullWidth
              required
              choices={accountOptions}
              defaultValue={accountOptions[0]?.id}
            />
            <SelectInput
              label="Zevoy Type"
              source="zevoyType"
              fullWidth
              required
              choices={ZevoyTypeOptions}
              onChange={(e) => {
                setZevoyType(e.target.value)
              }}
            />
            {zevoyType !== ZevoyPaymentTransactionType.AccountFeeReversal && (
              <>
                <TextInput source="amount" fullWidth required />
                <SelectInput
                  source="currency"
                  fullWidth
                  required
                  choices={currencyOptions}
                  defaultValue={currencyOptions[0]?.id}
                />
              </>
            )}
            {EnfuceDateTypes.includes(zevoyType) && (
              <DateInput source="enfuceDate" label="Date" required fullWidth />
            )}
            {EnfuceTextTypes.includes(zevoyType) && <EnfuceTextInput source="enfuceText" />}
            {zevoyType === ZevoyPaymentTransactionType.CustomerPayment && (
              <TextInput source="externalId" label="External ID" fullWidth />
            )}
            {zevoyType === ZevoyPaymentTransactionType.AccountFeeReversal && (
              <TextInput
                source="enfuceTransactionId"
                label="Enfuce Transaction Id"
                required
                fullWidth
              />
            )}
            <TextInput source="comments" multiline={true} rows={4} fullWidth />
          </SimpleForm>
        </Create>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
