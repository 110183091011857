import React, { useState } from 'react'
import { DetailsHeader, DetailsInputWrapper, DetailsUnderline, InfoWrapper } from '../../../styles'
import { ProspectCountry } from './Country'
import { BusinessID } from './BusinessID'
import { SalesRepresentative } from './SalesRepresentative'
import { InputRow, SaveButton } from '../../../../../lib/styles'
import { useUpdateProspectMutation } from '../../../../../GeneratedGraphQLTypes'
import { useProspectCardState } from '../store'
import tw from 'twin.macro'
import { toast } from 'react-toastify'
import { NameOnCard } from './NameOnCard'

const Wrapper = tw(InfoWrapper)`pt-8`
export const ProspectInfo = () => {
  const { prospect } = useProspectCardState()
  const [updateProspectMutation] = useUpdateProspectMutation()
  const [loading, setLoading] = useState(false)

  const saveProspectInfo = async () => {
    try {
      if (!prospect) {
        return
      }
      setLoading(true)
      await updateProspectMutation({
        variables: {
          input: {
            id: prospect.id,
            name: prospect.name,
            Country: prospect.country,
            salesRepID: prospect.salesRep?.id,
            businessID: prospect.businessID,
          },
        },
      })
      toast.success('Successfully saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <DetailsInputWrapper>
        <DetailsHeader>Prospect details</DetailsHeader>
        <DetailsUnderline />
        <ProspectCountry />
        <NameOnCard />
        <BusinessID />
        <SalesRepresentative />
        <InputRow>
          <SaveButton onClick={saveProspectInfo} disabled={loading}>
            Save changes
          </SaveButton>
        </InputRow>
      </DetailsInputWrapper>
    </Wrapper>
  )
}
