import React from 'react'
import { ColorVariants } from '../../constants'
import tw, { css, styled } from 'twin.macro'
import { InfoBoxVariantStyles } from './InfoBoxVariant'
import { FlattenSimpleInterpolation } from 'styled-components'

type WrapperProps = {
  type: ColorVariants
  styled?: FlattenSimpleInterpolation
}
const Wrapper = styled.div<WrapperProps>`
  ${tw`rounded-lg border-1 border-solid rounded-md px-[15px] py-[10px] text-sm font-slussenLight`}
  ${({ type }) => type && InfoBoxVariantStyles[type]}
  ${({ styled }) =>
    styled &&
    css`
      ${styled}
    `}
`

type InfoBoxProps = {
  message: string | React.ReactNode
  type: ColorVariants
  styled?: FlattenSimpleInterpolation
}
export const InfoBox = ({ message, type, styled }: InfoBoxProps) => {
  return (
    <Wrapper styled={styled} type={type}>
      {message}
    </Wrapper>
  )
}
