import { Prospect, ProspectInfoState } from './types'
import { GetProspectsQuery, PlanCondition } from '../../GeneratedGraphQLTypes'
import { GetState, SetState, StateCreator } from 'zustand'
import { DateTime } from 'luxon'
import { CountryKey } from '@zevoy/common/src/types/Generic'

export const mapProspectsFromData = (data: GetProspectsQuery): Prospect[] => {
  const edges = data?.prospects?.edges ?? null
  if (!edges) {
    return []
  }

  return edges.map((item) => item.edge) as Prospect[]
}

export const prospectInfoStateChunk: StateCreator<
  ProspectInfoState,
  SetState<ProspectInfoState>,
  GetState<ProspectInfoState>
> = (set) => ({
  name: '',
  businessId: '',
  country: 'FI',
  salesRepId: '',
  setName: (name: string) => set({ name }),
  setBusinessId: (businessId: string) => set({ businessId }),
  setCountry: (country: CountryKey) => set({ country }),
  setSalesRepId: (salesRepId: string) => set({ salesRepId }),
  resetNewProspectInfo: () =>
    set({
      name: '',
      businessId: '',
      country: 'FI',
      salesRepId: '',
    }),
})

export const formatPlanConditions = (planConditions: PlanCondition[]) =>
  planConditions
    ?.map((planCondition) => ({
      ...planCondition,
      startDate: DateTime.fromFormat(planCondition.startDate, 'yyyy-MM-dd').toJSDate(),
    }))
    .sort((a, b) => {
      return a.startDate.getTime() - b.startDate.getTime()
    }) || []
