import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CardStatusModalWrapper from '../../CardStatusModalWrapper'
import {
  ModalPromptPadding,
  ApproveButton,
  DismissButton,
  StyledHeader,
} from '../../../../lib/styles'
import { PromptInfo } from '../../styles'
import { activateBezala } from '../../queries'

export const BezalaActivationModal = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState('')
  const onSubmit = async () => {
    try {
      setError('')
      await activateBezala(params.id)
      navigate(`/admin/organization`)
    } catch (e) {
      const err = e as Error
      setError(err?.message ?? e)
    }
  }

  return (
    <CardStatusModalWrapper>
      <ModalPromptPadding>
        <StyledHeader>Activate Bezala</StyledHeader>
        <PromptInfo>
          WARNING: Once activated, Bezala cannot be deactivated again through UI!
        </PromptInfo>
        {error ? <PromptInfo>ERROR: {error}</PromptInfo> : null}
        <ApproveButton onClick={onSubmit}>Yes</ApproveButton>
        <DismissButton
          onClick={() => {
            navigate(-1)
          }}
        >
          No
        </DismissButton>
      </ModalPromptPadding>
    </CardStatusModalWrapper>
  )
}
