import React from 'react'
import { ModalInputLabel } from '../../../../../lib/styles'
import { InputWrapper } from '../../../../CreditApplication/styles'
import { useProspectCardState } from '../store'
import { TextInput } from '@zevoy/common/src/components/TextInput'

export const BusinessID = () => {
  const { prospect } = useProspectCardState()
  return (
    <InputWrapper>
      <ModalInputLabel>Business ID:</ModalInputLabel>
      <TextInput value={prospect?.businessID ?? ''} disabled />
    </InputWrapper>
  )
}
