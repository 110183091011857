import { DefaultPlan } from './types'
import { PlanType } from '../../../../../GeneratedGraphQLTypes'
import { DefaultPlanTypeNames } from '@zevoy/common/src/types/Generic'

const DEFAULT_ZERO_COUNT = { EE: 0, FI: 0, SE: 0, LV: 0, LT: 0, DK: 0, NO: 0 }
const DEFAULT_ZERO_PRICE = { EE: '0', FI: '0', SE: '0', LV: '0', LT: '0', DK: '0', NO: '0' }

export const PLAN_ZEVOY_GO: DefaultPlan = {
  amountPerUser: {
    EE: '0',
    FI: '0',
    SE: '0',
    LV: '0',
    LT: '0',
    DK: '0',
    NO: '0',
  },
  amountPerBusinessCardUser: {
    EE: '10',
    FI: '10',
    SE: '100',
    LV: '10',
    LT: '10',
    DK: '75',
    NO: '100',
  },
  amountPerClaimUser: {
    EE: '5',
    FI: '5',
    SE: '50',
    LV: '5',
    LT: '5',
    DK: '37.5',
    NO: '50',
  },
  amountPerSubscription: {
    EE: '5',
    FI: '5',
    SE: '50',
    LV: '5',
    LT: '5',
    DK: '37.5',
    NO: '50',
  },
  minimumBillingFee: {
    EE: '50',
    FI: '50',
    SE: '500',
    LV: '50',
    LT: '50',
    DK: '370',
    NO: '580',
  },
  freeClaimForBusinessCardUser: {
    EE: false,
    FI: false,
    SE: false,
    LV: false,
    LT: false,
    DK: false,
    NO: false,
  },
  fixedFee: DEFAULT_ZERO_PRICE,
  freeClaimUsers: DEFAULT_ZERO_COUNT,
  freeBusinessCardUsers: DEFAULT_ZERO_COUNT,
  freeSubscriptionCards: DEFAULT_ZERO_COUNT,
  type: PlanType.ZevoyGo,
  typeName: DefaultPlanTypeNames.zevoyGo,
}

export const PLAN_FREE_TRIAL: DefaultPlan = {
  amountPerUser: DEFAULT_ZERO_PRICE,
  amountPerBusinessCardUser: DEFAULT_ZERO_PRICE,
  amountPerClaimUser: DEFAULT_ZERO_PRICE,
  amountPerSubscription: DEFAULT_ZERO_PRICE,
  minimumBillingFee: DEFAULT_ZERO_PRICE,
  freeClaimForBusinessCardUser: {
    EE: false,
    FI: false,
    SE: false,
    LV: false,
    LT: false,
    DK: false,
    NO: false,
  },
  fixedFee: DEFAULT_ZERO_PRICE,
  freeClaimUsers: DEFAULT_ZERO_COUNT,
  freeBusinessCardUsers: DEFAULT_ZERO_COUNT,
  freeSubscriptionCards: DEFAULT_ZERO_COUNT,
  type: PlanType.FreeTrial,
  typeName: DefaultPlanTypeNames.freeTrial,
}

export const DEFAULT_PLANS_DETAILS: DefaultPlan[] = [PLAN_ZEVOY_GO, PLAN_FREE_TRIAL]
