import { AgreementChannelOptions, WeekDay } from '../../../GeneratedGraphQLTypes'

export enum BenefitTypes {
  SPORT = 'sports',
  CULTURE = 'culture',
  LUNCH = 'lunch',
}

export enum BenefitTypeIDs {
  SPORT = 3,
  CULTURE = 2,
  LUNCH = 1,
}

// Mapping of day indices to the WeekDay enum
export const DayMapping: { [key: number]: WeekDay } = {
  0: WeekDay.Sunday,
  1: WeekDay.Monday,
  2: WeekDay.Tuesday,
  3: WeekDay.Wednesday,
  4: WeekDay.Thursday,
  5: WeekDay.Friday,
  6: WeekDay.Saturday,
}

export const DayOrder = {
  [WeekDay.Monday]: 0,
  [WeekDay.Tuesday]: 1,
  [WeekDay.Wednesday]: 2,
  [WeekDay.Thursday]: 3,
  [WeekDay.Friday]: 4,
  [WeekDay.Saturday]: 5,
  [WeekDay.Sunday]: 6,
}

export const agreementChannelOptions = [
  {
    name: 'Merchant Onboarding',
    id: AgreementChannelOptions.MerchantOnboarding,
  },
  { name: 'Email', id: AgreementChannelOptions.Email },
  { name: 'Phone Call', id: AgreementChannelOptions.PhoneCall },
  { name: 'Physical', id: AgreementChannelOptions.Physical },
  { name: 'Text Message', id: AgreementChannelOptions.TextMessage },
  { name: 'Third Party Contract Service', id: AgreementChannelOptions.ThirdPartyContractService },
  { name: 'Other', id: AgreementChannelOptions.Other },
]
