import React from 'react'
import { InfoWrapper } from '../../Prospects/styles'
import { DetailsHeader, DetailsUnderline, ModalInputLabel } from '../styles'
import tw from 'twin.macro'
import { CountryKey, CurrencySigns } from '@zevoy/common/src/types/Generic'
import { useOrganizationDetailsStore } from './store'

const OnboardingFeeWrapper = tw(InfoWrapper)`w-110 p-0 pt-8`
const FeeCaption = tw(ModalInputLabel)`text-darkGrey opacity-100`
export const OnboardingFee = () => {
  const { organization } = useOrganizationDetailsStore()
  if (!organization) {
    return (
      <>
        <DetailsHeader>Onboarding fee</DetailsHeader>
        <DetailsUnderline />
        <ModalInputLabel>Unavailable</ModalInputLabel>
      </>
    )
  }
  const { onboardingFeeBillingDate, onboardingFee: fee, country } = organization

  if (!fee) {
    return (
      <OnboardingFeeWrapper>
        <DetailsHeader>Onboarding fee</DetailsHeader>
        <DetailsUnderline />
        <ModalInputLabel>Onboarding fee wasn't requested.</ModalInputLabel>
      </OnboardingFeeWrapper>
    )
  }

  const currency = Object.keys(CurrencySigns).some((key) => key === country)
    ? CurrencySigns[country as CountryKey]
    : ''
  return (
    <OnboardingFeeWrapper>
      <DetailsHeader>Onboarding fee</DetailsHeader>
      <DetailsUnderline />
      <FeeCaption>
        Requested onboarding fee: {fee} {currency}
      </FeeCaption>
      <FeeCaption>Billing date: {onboardingFeeBillingDate}</FeeCaption>
    </OnboardingFeeWrapper>
  )
}
