import get from 'lodash/get'
import { Datagrid, DateField, Identifier, List, TextField, useRecordContext } from 'react-admin'
import { postFilters } from './Filters'
import { TabFilter } from './TabFilter'
import { ZevoyList } from '../styles'

export const MoneyField = ({ source }: { source: string; label: string }) => {
  const record = useRecordContext()
  const money = get(record, source)

  return (
    <>
      {money?.amount} {money?.currency}
    </>
  )
}

export const FundingTypeField = (_: { label: string }) => {
  const record = useRecordContext()
  const requested = get(record, 'requested')
  const approved = get(record, 'approved')
  const money = approved ?? requested
  return <>{money.amount > 0.01 ? 'credit' : 'prepaid'}</>
}

export const CreditApplicationList = () => {
  return (
    <List filters={postFilters}>
      <ZevoyList>
        <TabFilter />
        <Datagrid
          rowClick={(id: Identifier) => `/credit-applications/${id}/details`}
          bulkActionButtons={false}
        >
          <TextField source="organization.name" sortable={false} label="Company Name" />
          <TextField source="organization.salesRep.fullName" sortable={false} label="Sale rep." />
          <TextField source="user.fullName" sortable={false} label="Requestor" />
          <TextField source="type" label="Type" sortable={false} />
          <MoneyField source="requested" label="Credit Request" />
          <MoneyField source="approved" label="Credit Approved" />
          <FundingTypeField label="Funding type" />
          <DateField source="createTime" label="Application date" sortable={false} />
        </Datagrid>
      </ZevoyList>
    </List>
  )
}
