import React from 'react'
import { DetailsHeader, DetailsUnderline, ModalInputWrapper } from '../styles'
import tw from 'twin.macro'
import { useSalesReps } from '../../Prospects/hooks'
import { useOrganizationDetailsStore } from './store'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Option } from '@zevoy/common/src/types/Generic'
import { getSalesRepOptions } from '../../../lib/utils'
import { InfoWrapper } from '../../Prospects/styles'
import { useSetOrganizationSalesRepMutation } from '../../../GeneratedGraphQLTypes'
import { useParams } from 'react-router-dom'
import { SaveButton } from '../../../lib/styles'
import { toast } from 'react-toastify'

const Wrapper = tw(InfoWrapper)`p-0 pt-8`

export const SalesRepresentative = () => {
  const { salesReps } = useSalesReps()
  const { setSalesRep, salesRep } = useOrganizationDetailsStore()
  const { id } = useParams()

  const [saveSalesRep] = useSetOrganizationSalesRepMutation({
    variables: {
      organizationId: id,
      salesRepId: salesRep,
    },
  })

  const handleSave = async () => {
    try {
      await saveSalesRep()
      toast.success('Sales representative saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }
  const salesRepOptions: Option[] = getSalesRepOptions(salesReps)

  return (
    <Wrapper>
      <DetailsHeader>Sales representative</DetailsHeader>
      <DetailsUnderline />
      <ModalInputWrapper>
        <SelectComponent
          options={salesRepOptions}
          value={salesRepOptions.find(
            (salesRepOption: Option) => salesRepOption.value === salesRep,
          )}
          onChange={(newValue) => {
            setSalesRep(newValue?.value as string)
          }}
        />
      </ModalInputWrapper>
      <SaveButton onClick={handleSave}>Save Sales Representative</SaveButton>
    </Wrapper>
  )
}
