import React from 'react'
import {
  BenefitsOptionDetails,
  BenefitsOptionDescription,
  OptionTitle,
  BenefitsType,
  TagsWrapper,
} from './styled'
import { Stack } from '@zevoy/common/src/components/Spacing'
import { GetBenefitCategoriesQuery } from 'admin-client/src/GeneratedGraphQLTypes'
import { ChipField } from 'react-admin'
import { theme } from 'twin.macro'

export type BenefitCategoryType =
  GetBenefitCategoriesQuery['benefitCategories'][number]['tags'][number]

interface BenefitsSectionProps {
  benefitsList: BenefitCategoryType[] | undefined
  selectedOptions: BenefitCategoryType[]
  handleOptionClick: (option: BenefitCategoryType) => void
  benefitTitle: string
}

export const BenefitOptionSections: React.FC<BenefitsSectionProps> = ({
  benefitsList,
  selectedOptions,
  handleOptionClick,
  benefitTitle,
}) => {
  return (
    <Stack gap={5}>
      <BenefitsType>
        <BenefitsOptionDetails>
          <BenefitsOptionDescription>
            <OptionTitle>{benefitTitle}</OptionTitle>
          </BenefitsOptionDescription>
        </BenefitsOptionDetails>

        <TagsWrapper>
          {benefitsList?.map((option) => {
            const isSelected = selectedOptions.some(
              (selectedOption) => selectedOption.id === option.id,
            )
            return (
              <ChipField
                key={option.id}
                style={isSelected ? { background: theme('colors.zevoyPastelGreen') } : {}}
                source="title"
                record={option}
                onClick={() => handleOptionClick(option)}
              />
            )
          })}
        </TagsWrapper>
      </BenefitsType>
    </Stack>
  )
}
