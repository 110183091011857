import create from 'zustand'

const creditApplicationsStore = create((set) => ({
  creditApplications: [],
  creditApplication: {},
  creditApprovers: [],
  creditApprover: {},
  filter: 'pending',
  setCreditApplications: (creditApplications) => set({ creditApplications }),
  setCreditApprovers: (creditApprovers) => set({ creditApprovers }),
  selectCreditApplication: (id) =>
    set((state) => ({
      ...state,
      creditApplication: state.creditApplications.find(
        (creditApplication) => creditApplication.id === id,
      ),
    })),

  selectCreditApprover: (id) =>
    set((state) => ({
      ...state,
      creditApprover: state.creditApprovers.find((creditApprover) => creditApprover.id === id),
    })),

  resetSelectCreditApplication: () => set({ creditApplications: {} }),

  setFilter: (filter) => set({ filter }),

  setCreditApplication: (creditApplication) => set({ creditApplication }),

  setCreditApplicationDocuments: (documents) =>
    set((state) => ({
      ...state,
      creditApplication: {
        ...state.creditApplication,
        documents,
      },
    })),
}))

export default creditApplicationsStore
