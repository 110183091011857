import React from 'react'
import Select, {
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  Props,
  StylesConfig,
} from 'react-select'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import { theme } from 'twin.macro'
import { ErrorMessage } from '../ErrorMessage'
import { Label } from '../Label'
import { CSSProp } from 'styled-components'
import LegacyLoading from '../Loading/LegacyLoading'
import { SelectComponentNew } from './SelectComponentNew'
import { Option } from '../../types/Generic'
import { InfoBox } from '../InfoBox'
import { ColorVariants } from '../../constants'
import { Stack } from '../Spacing'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'

interface SelectComponentProps<
  OptionType extends Option,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends Props<OptionType, IsMulti, GroupType>,
    CreatableProps<OptionType, IsMulti, GroupType> {
  errorMessage?: string
  isSmall?: boolean
  lightFont?: boolean
  width?: string
  label?: string
  labelCss?: CSSProp<any>
  creatable?: boolean
  isNew?: boolean
  onDeleteOption?: (option: OptionType) => void
  infoMessage?: string | React.ReactNode
}

export const SelectComponent = <
  OptionType extends Option,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: SelectComponentProps<OptionType, IsMulti, GroupType>,
) => {
  const customStyles: StylesConfig<OptionType, IsMulti, GroupType> = {
    control: (styles: CSSObjectWithLabel) => ({
      ...styles,
      boxShadow: props.errorMessage
        ? theme('boxShadow.inputShadowError')
        : theme('boxShadow.inputShadow'),
      borderColor: theme('colors.lightGrey'),
      border: 'none',
      borderRadius: theme('borderRadius.inputRadius'),
      background: props.isDisabled ? theme('colors.legacyDisabledBackground') : undefined,
      height: props.isSmall ? '30px' : 'default',
      minHeight: props.isSmall ? '30px' : 'default',
      fontSize: props.isSmall ? '14px' : 'default',
      width: props.width ? `${props.width}` : 'default',
      cursor: 'pointer',
      fontFamily: props.lightFont
        ? theme('fontFamily.slussenLight')
        : theme('fontFamily.slussenLight'),
      '&:hover': {
        borderColor: theme('colors.lightGrey'),
      },
    }),
    multiValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      background: theme('colors.zevoyBlue.100'),
      borderRadius: theme('borderRadius.inputRadius'),
      border: `1px solid ${theme('colors.zevoyBlue.300')}`,
      color: theme('colors.zevoyBlue.500'),
    }),
    multiValueLabel: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: theme('colors.zevoyBlue.500'),
    }),
    multiValueRemove: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: theme('colors.zevoyBlue.500'),

      borderBottomRightRadius: theme('borderRadius.inputRadius'),
      borderTopRightRadius: theme('borderRadius.inputRadius'),
      '&:hover': {
        background: theme('colors.zevoyBlue.200'),
        color: theme('colors.zevoyBlue.900'),
      },
    }),
    singleValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: props.isDisabled ? theme('colors.legacyDisabledText') : undefined,
    }),
    placeholder: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: props.isDisabled ? theme('colors.legacyDisabledText') : undefined,
    }),
    option: (styles: CSSObjectWithLabel, { isSelected, isFocused }) => ({
      ...styles,
      cursor: 'pointer',
      fontSize: props.isSmall ? '14px' : '1rem',
      paddingTop: props.isSmall ? '5px' : 'default',
      paddingBottom: props.isSmall ? '5px' : 'default',
      fontFamily: props.lightFont
        ? theme('fontFamily.slussenLight')
        : theme('fontFamily.slussenLight'),
      background: isSelected
        ? theme('colors.brightOrange')
        : isFocused
        ? theme('colors.lightOrange')
        : '#FFFFFF',

      '&:active': {
        background: theme('colors.lightOrange'),
      },
    }),
    menuPortal: (styles: CSSObjectWithLabel) => ({
      ...styles,
      zIndex: 9999,
    }),
    menu: (styles: CSSObjectWithLabel) => ({
      ...styles,
      marginTop: props.isSmall ? '3px' : 'default',
      marginBottom: props.isSmall ? '3px' : 'default',
      fontFamily: props.lightFont
        ? theme('fontFamily.slussenLight')
        : theme('fontFamily.slussenLight'),
    }),
    container: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: props.width ? `${props.width}` : 'default',
    }),
  }
  const SmallDropdownIndicator = (
    props: DropdownIndicatorProps<OptionType, IsMulti, GroupType>,
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <components.DownChevron size={12} />
      </components.DropdownIndicator>
    )
  }

  const LoadingIndicator = () => {
    // Loading indicator is using the LegacyLoading component for consistencys sake.
    // This should be replaced with the current Primary variant from ./Loading/index when it is implemented
    return (
      <LegacyLoading
        hideText
        size="16"
        wrapperStyles={{ paddingTop: 0, paddingRight: '1.25rem' }}
      />
    )
  }

  const searchFilter = (option: FilterOptionOption<OptionType>, inputValue: string) => {
    if (inputValue.length === 0) {
      return true
    }

    return (
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      !!option.data.group?.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  if (props.isNew) {
    return <SelectComponentNew {...props} filterOption={searchFilter} />
  }

  return (
    <div style={{ width: props.width }}>
      {props.label && (
        <Label css={props.labelCss} width={props.width}>
          {props.label}
        </Label>
      )}
      {props.creatable ? (
        <CreatableSelect
          {...props}
          styles={{ ...customStyles, ...props.styles }}
          filterOption={searchFilter}
          components={{
            ...props.components,
            DropdownIndicator: props.isSmall
              ? SmallDropdownIndicator
              : components.DropdownIndicator,
            LoadingIndicator: LoadingIndicator,
          }}
        />
      ) : (
        <Select
          {...props}
          styles={{ ...customStyles, ...props.styles }}
          filterOption={searchFilter}
          components={{
            DropdownIndicator: props.isSmall
              ? SmallDropdownIndicator
              : components.DropdownIndicator,
            LoadingIndicator: LoadingIndicator,
            ...props.components,
          }}
        />
      )}
      <Stack>
        {props.infoMessage && <InfoBox message={props.infoMessage} type={ColorVariants.Info} />}
        {props.errorMessage && <ErrorMessage error={props.errorMessage} isFieldError />}
      </Stack>
    </div>
  )
}
