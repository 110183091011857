const refreshToken = 'refreshToken'
const authToken = 'authToken'
const accessToken = 'accessToken'
export { refreshToken, authToken, accessToken }

export const mockedTeams = [
  {
    name: 'Marketing',
    approver: 'Aage Reerslev',
  },
  {
    name: 'Development',
    approver: 'Joakim Lundborg',
  },
  {
    name: 'Sales',
    approver: 'Aage Reerslev',
  },
]
