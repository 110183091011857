import React from 'react'
import { InputWrapper, ModalInputLabel, ModalInputWrapper } from '../../../styles'
import { useProspectCardState } from '../store'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import tw from 'twin.macro'
import { Countries } from '@zevoy/common/src/types/Generic'

const Wrapper = tw(InputWrapper)`font-slussenLight`
export const ProspectCountry = () => {
  const { prospect } = useProspectCardState()
  const country = prospect?.country
  const selectedCountryOption = {
    label: country ? Countries[country as keyof typeof Countries] : '',
    value: country ?? '',
  }
  return (
    <Wrapper>
      <ModalInputLabel>Country:</ModalInputLabel>
      <ModalInputWrapper>
        <SelectComponent
          isDisabled
          options={[selectedCountryOption]}
          value={selectedCountryOption}
        />
      </ModalInputWrapper>
    </Wrapper>
  )
}
