import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { InfoWrapper } from '../../Prospects/styles'
import { DetailsChevronWrapper, DetailsHeader, DetailsUnderline, ModalInputLabel } from '../styles'
import { useOrganizationDetailsStore } from './store'
import { SaveButton } from '../../../lib/styles'
import { Plan } from '../../../GeneratedGraphQLTypes'
import { useRequestFxFeeUpdate } from '../../../lib/hooks'
import Slider from '../../../lib/components/Slider'

const AdditionalTermsWrapper = tw(InfoWrapper)`w-110 p-0 pt-8`

export const FXFee = () => {
  const { organization } = useOrganizationDetailsStore()
  const [fxFee, setFXFee] = useState(true)

  const { updateFXFee, loading } = useRequestFxFeeUpdate()

  useEffect(() => {
    setFXFee(organization?.plan?.fxFee ?? true)
  }, [organization])

  if (!organization) {
    return (
      <>
        <DetailsHeader>FX fee</DetailsHeader>
        <DetailsUnderline />
        <ModalInputLabel>Unavailable</ModalInputLabel>
      </>
    )
  }

  const handleSave = async () => {
    await updateFXFee(fxFee, organization.id, organization?.plan as Plan)
  }

  return (
    <AdditionalTermsWrapper>
      <DetailsHeader>FX fee</DetailsHeader>
      <DetailsUnderline />
      <DetailsChevronWrapper>
        <Slider selected={fxFee} onClick={() => setFXFee(!fxFee)} />
      </DetailsChevronWrapper>
      <SaveButton onClick={handleSave} disabled={loading}>
        Save FX fee
      </SaveButton>
    </AdditionalTermsWrapper>
  )
}
