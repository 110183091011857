import {
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  ExportButton,
  Identifier,
  List,
  Pagination,
  TextField,
  TextFieldProps,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import get from 'lodash/get'
import Typography from '@mui/material/Typography'
import ContentAdd from '@mui/icons-material/Add'
import Upload from '@mui/icons-material/Upload'
import { Toolbar } from '@mui/material'
import tw from 'twin.macro'
import { transactionFilters } from './Filters'
import { useLocation, useNavigate } from 'react-router-dom'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import {
  ZevoyPaymentTransactionType,
  useClosePaymentBatchMutation,
  useGetCurrentUserDataQuery,
  useImportPaymentTransactionsMutation,
  useOpenPaymentBatchMutation,
  usePostPaymentBatchMutation,
} from '../../../GeneratedGraphQLTypes'
import { csvToJson } from '../utils'
import { getReadableZevoyType, validatePaymentTransactions } from './utils'

export type Batch = {
  id: number
  status: string
  closer: { id: string }
  summary: { transactionCount: number; currencies: { amount: number; currency: string }[] }
}

type ListActionsProps = {
  batch: Batch
  refetchBatch: () => void
}

const ListActions = ({ batch, refetchBatch }: ListActionsProps) => {
  const navigate = useNavigate()
  const { filterValues } = useListContext()
  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null)
  const [sending, setSending] = useState<boolean>(false)

  const { data } = useGetCurrentUserDataQuery({
    fetchPolicy: 'cache-and-network',
  })
  const notify = useNotify()
  const refresh = useRefresh()
  const [closePaymentBatch] = useClosePaymentBatchMutation()
  const [openPaymentBatch] = useOpenPaymentBatchMutation()
  const [postPaymentBatch] = usePostPaymentBatchMutation()
  const [importPaymentTransactions] = useImportPaymentTransactionsMutation()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const content = e.target?.result
        if (typeof content === 'string') {
          const values = csvToJson(content)
          const transactions = values.map((item) => {
            return {
              batchId: batch.id,
              amount: item.amount || undefined,
              currency: item.currency || undefined,
              comments: item.comments || undefined,
              enfuceAccountId: item.enfuceAccountId,
              enfuceDate: item.enfuceDate || undefined,
              enfuceText: item.enfuceText || undefined,
              enfuceTransactionId: item.enfuceTransactionId || undefined,
              externalId: item.externalId || undefined,
              organizationId: item.organizationId,
              zevoyType: item.zevoyType as ZevoyPaymentTransactionType,
            }
          })
          try {
            validatePaymentTransactions(transactions)
          } catch (e: any) {
            notify(`CSV validation failed: ${e?.message ?? 'Unknown error'}`, { type: 'error' })
            return
          }
          importPaymentTransactions({
            variables: {
              input: transactions,
            },
            onCompleted: () => {
              notify('Import transactions successflly', { type: 'success' })
              refresh()
            },
            onError: () => {
              notify('Import transactions failed', { type: 'error' })
            },
          })
        }
      }
      reader.readAsText(file)
    }
  }

  const openFileInput = () => {
    if (fileInput) {
      fileInput.click()
    }
  }

  return (
    <TopToolbar>
      <Button
        startIcon={<ContentAdd />}
        disabled={batch?.status !== 'open'}
        label="New Transaction"
        onClick={() => navigate(`/admin/paymentTransaction/create?batchId=${filterValues.batchId}`)}
      />
      {batch?.status && ['closed', 'error'].includes(batch?.status) && (
        <Button
          label="Reopen"
          onClick={() => {
            openPaymentBatch({ variables: { id: batch.id } }).then(() => {
              refetchBatch()
              notify('Payment batch reopened')
            })
          }}
        />
      )}
      {batch?.status === 'open' && (
        <Button
          label="Close"
          onClick={() => {
            closePaymentBatch({ variables: { id: batch.id } }).then(() => {
              refetchBatch()
              notify('Payment batch closed')
            })
          }}
        />
      )}
      {batch && (
        <Button
          label="Send"
          disabled={
            sending ||
            batch?.status === 'open' ||
            batch?.status === 'posted' ||
            data?.me.id === batch?.closer?.id
          }
          onClick={() => {
            setSending(true)
            postPaymentBatch({
              variables: { id: batch?.id },
              onCompleted: (data) => {
                if (data) {
                  notify('Payment batch sent', { type: 'success' })
                } else {
                  notify('Error sending payment batch', { type: 'error' })
                }
              },
              onError: () => {
                notify('Error sending payment batch', { type: 'error' })
              },
            }).then(() => {
              refresh()
              refetchBatch()
              setSending(false)
            })
          }}
        />
      )}

      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={(input) => setFileInput(input)}
      />
      <Button
        disabled={batch?.status !== 'open'}
        startIcon={<Upload />}
        label="Import"
        onClick={openFileInput}
      />
      <ExportButton />
    </TopToolbar>
  )
}

export const DeleteButtonField = ({
  source,
  disabled,
}: {
  source: string
  disabled: boolean
  label: string
  sortable: boolean
}) => {
  const record = useRecordContext()
  const status = get(record, source!)

  return (
    <DeleteWithConfirmButton
      label="Delete"
      disabled={disabled || status !== 'open'}
      confirmTitle={'Cancel selected transaction'}
      confirmContent={'Are you sure you want to delete this item?'}
    />
  )
}

export const ZevoyTypeField: FC<TextFieldProps> = memo((props) => {
  const { className, source, emptyText, ...rest } = props
  const record = useRecordContext(props)
  const value = getReadableZevoyType(get(record, source!))

  return (
    <Typography component="span" variant="body2" className={className} {...rest}>
      {value != null && typeof value !== 'string' ? JSON.stringify(value) : value || emptyText}
    </Typography>
  )
})

const ListDataGrid = ({ isOpen }: { isOpen: boolean }) => {
  const { filterValues } = useListContext()

  return (
    <Datagrid
      rowClick={(id: Identifier) =>
        `/admin/paymentTransaction/${id}/show?batchId=${filterValues.batchId}`
      }
      bulkActionButtons={false}
    >
      <TextField label="Organization" source="organization.name" />
      <ZevoyTypeField label="Type" source="zevoyType" />
      <TextField source="status" />
      <TextField label="Date" source="enfuceDate" />
      <TextField source="amount" textAlign="right" />
      <TextField source="currency" />
      <TextField label="Res Code" source="resCode" />
      <TextField source="comments" />
      <DeleteButtonField disabled={!isOpen} label="Delete" source={'status'} sortable={false} />
    </Datagrid>
  )
}

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />

export const PaymentTransactionList = () => {
  const location = useLocation()
  const filter = new URLSearchParams(location.search).get('filter')
  const batchId = JSON.parse(filter!).batchId

  const dataProvider = useDataProvider()
  const [batch, setBatch] = useState<Batch | undefined>()

  const refetchBatch = useCallback(() => {
    dataProvider.getOne('paymentBatch', { id: batchId }).then((res) => {
      setBatch(res.data)
    })
  }, [dataProvider, batchId])

  useEffect(() => {
    refetchBatch()
  }, [refetchBatch])

  return (
    <>
      <List
        filters={transactionFilters}
        actions={batch && <ListActions batch={batch} refetchBatch={refetchBatch} />}
        pagination={<PostPagination />}
        perPage={100}
      >
        <ListDataGrid isOpen={batch?.status === 'open'} />
      </List>
      <StyledToolbar>
        <div>Total Transactions: {batch?.summary.transactionCount}</div>
        {batch?.summary.currencies.map((c) => (
          <div>
            {c.currency}: {c.amount}
          </div>
        ))}
      </StyledToolbar>
    </>
  )
}

const StyledToolbar = tw(Toolbar)`flex gap-4 border border-lightGrey border-solid bg-zevoyGray4`
