import tw, { styled, css } from 'twin.macro'

export const SidebarWrapper = tw.section`
  flex flex-col items-center min-h-screen justify-start
  px-4 w-72
  bg-darkGrey shadow-base
`
export const SvgWrapper = tw.div`
  px-2 min-w-full justify-between items-center flex flex-row
  pt-8
`
export const RowWrapper = styled.section`
  ${tw`
  flex rounded-lg hover:opacity-75 justify-evenly items-center min-w-full
  py-1 cursor-pointer rounded-lg`}
  ${(props) =>
    props.selected &&
    css`
      ${tw`text-opacity-100 shadow-heavy`}
    `}
`
export const ButtonsWrapper = tw.section`flex flex-col items-center justify-start min-w-full px-4 pt-3 font-slussenLight`
export const SectionTitle = tw.h3`font-slussenLight text-lightGrey w-full text-sm`

export const IconWrapper = tw.div`w-6 h-6 flex items-center justify-center`

export const SidebarButton = styled.div`
  ${tw`flex flex-grow py-0 px-4 opacity-70 text-sm text-lightGrey font-slussenLight`}
  ${(props) =>
    props.selected &&
    css`
      ${tw`opacity-100 font-slussenSemibold`}
    `}
`

export const Notifications = styled.div`
  ${tw`bg-greyBlue bg-opacity-50 flex rounded-xl text-white items-center text-xs font-slussenSemibold justify-center px-2 h-5  `}
  ${(props) =>
    props.selected &&
    css`
      ${tw`bg-brightOrange opacity-100 font-slussenSemibold`}
    `}
`
export const NotificationWrapper = tw.div`flex items-center justify-center`

export const InfoWrapper = tw.div`flex flex-col items-start w-3/5 space-y-2`
export const UserWrapper = tw.div`flex flex-row justify-between items-end flex-grow w-full px-4 pb-5`
export const UserName = tw.div`text-sm font-slussenSemibold text-lightGrey`
export const Company = tw.div`text-xs font-slussenLight text-lightGrey text-opacity-50`

export const OptionsButton = tw.div`
  rounded-full h-8 w-8 flex items-center justify-center shadow-heavy
  bg-white hover:bg-lightGrey
`
