import { SelectInput, TextInput } from 'react-admin'
import { countries } from '../../../lib/utils'

const types = [
  { id: 'new', name: 'New' },
  { id: 'increaseCredit', name: 'Increase Credit' },
]
export const postFilters = [
  <SelectInput
    variant="standard"
    choices={countries}
    label="Countries"
    source="country"
    alwaysOn
  />,
  <SelectInput variant="standard" choices={types} label="Types" source="types" alwaysOn />,
  <TextInput variant="standard" label="Search" source="q" alwaysOn />,
]
