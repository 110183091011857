import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import {
  AdditionalInfoText,
  DetailsWrapper,
  ModalInputWrapper,
  ModalInputWrapperSmall,
  ModalInputWrapperMedium,
  DetailsScreenWrapper,
  DetailsHeader,
  DetailsLabel,
  DetailsInputWrapper,
  DetailsUnderline,
  DetailsTextArea,
  DetailsDropzoneWrapper,
  DetailsCurrencyWrapper,
  DetailsCurrencyHeader,
  DetailsLabelCurrency,
  DetailsFileItem,
  DetailsFileItemTrash,
  DetailsFileItemName,
  DetailsFileItemLink,
} from './styles'
import { InfoWrapper, SaveButton, Input, InputRow, DetailsInput, SelectRow } from '../../lib/styles'
import { ReactComponent as TrashIcon } from '../../lib/assets/icons/trash.svg'
import { ReactComponent as LinkIcon } from '../../lib/assets/icons/link.svg'
import CurrencySelect from './CurrencySelect'
import Select from '../../lib/components/Select'
import {
  saveCreditApplicationMutationExternal,
  saveCreditApplicationMutationInternal,
  addCreditApplicationApprover,
  uploadCreditApplicationDocument,
  removeCreditApplicationDocument,
  getCreditApplication,
  getCreditApplicationDocuments,
} from './queries-legacy'
import creditApplicationsStore from './store'
import { getCurrencyForCountry } from '../../lib/utils'
import { UboList } from './Details/UboList'
import { CreditApplicationUser } from './Details/SignedBy'
import { useGetCreditApproversQuery } from '../../GeneratedGraphQLTypes'

const Details = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    creditApprovers,
    setCreditApprovers,
    setCreditApplication,
    setCreditApplicationDocuments,
    creditApplication,
  } = creditApplicationsStore()

  const [businessId, setBusinessId] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [resolveButtonDisabled, setResolveButtonDisabled] = useState(true)
  const [externalbuttonDisabled, setExternalbuttonDisabled] = useState(true)
  const [comments, setComments] = useState('')
  const [decision, setDecision] = useState('')
  const [creditApprover, setCreditApprover] = useState('')
  const [, setCreditApproverId] = useState('')
  const [creditApproverSecondary, setCreditApproverSecondary] = useState('')
  const [, setCreditApproverSecondaryId] = useState('')
  const [approvedCreditLimit, setApprovedCreditLimit] = useState(null)
  const [currency, setCurrency] = useState('EUR')
  const [status, setStatus] = useState('')
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true)

  useGetCreditApproversQuery({
    onCompleted: (data) => {
      setCreditApprovers(data.creditApprovers)
    },
  })

  useEffect(() => {
    if (id) {
      getCreditApplication(id).then((response) => {
        setCreditApplication(response.data.creditApplication)
        const creditApp = response.data.creditApplication
        setBusinessId(creditApp.organization?.businessID)
        setComments(creditApp.comments?.length > 0 ? creditApp.comments : '')
        setDecision(creditApp.decision && creditApp.decision.length > 0 ? creditApp.decision : '')
        setCreditApprover(
          creditApp.approvers && creditApp.approvers.length > 0
            ? creditApp.approvers[0]?.fullName
            : '',
        )
        setCreditApproverSecondary(
          creditApp.approvers && creditApp.approvers.length > 0
            ? creditApp.approvers[1]?.fullName
            : '',
        )
        setApprovedCreditLimit(
          creditApp.approved && creditApp.approved.amount !== null
            ? creditApp.approved.amount
            : null,
        )
        setCurrency(
          creditApp.approved?.currency && creditApp.approved.currency.length > 0
            ? creditApp.approved.currency
            : creditApp.organization?.country
            ? getCurrencyForCountry(creditApp.organization.country) || ''
            : '',
        )
        setStatus(creditApp.status)
        setLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const addCreditApprover = (id, userId) => {
    addCreditApplicationApprover(id, userId)
  }

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept:
      'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        }),
      )
      setButtonDisabled(false)
    },
  })

  const uploadedFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const fileLink = (url) => {
    window.open(url, '_blank').focus()
  }

  const removeFile = (id, docID) => {
    removeCreditApplicationDocument(id, docID).then(() =>
      getCreditApplicationDocuments(id).then((res) =>
        setCreditApplicationDocuments(res.data.creditApplication.documents),
      ),
    )
  }

  const saveInternal = (creditApplicationId) => {
    saveCreditApplicationMutationInternal(creditApplicationId, comments, decision)
    files.length > 0 &&
      uploadCreditApplicationDocument(id, files[0]).then(() => {
        getCreditApplicationDocuments(id).then((res) =>
          setCreditApplicationDocuments(res.data.creditApplication.documents),
        )
      })
  }

  const saveExternal = (creditApplicationId) => {
    saveCreditApplicationMutationExternal(
      creditApplicationId,
      comments,
      decision,
      approvedCreditLimit,
      currency,
    )
    setExternalbuttonDisabled(true)
  }

  const resolveButtonDependency =
    decision !== '' &&
    creditApprover !== '' &&
    approvedCreditLimit !== 0 &&
    creditApproverSecondary !== '' &&
    currency !== ''

  useEffect(() => {
    if (resolveButtonDependency) {
      setResolveButtonDisabled(false)
    }
  }, [resolveButtonDependency])

  useEffect(() => {
    if (decision.length > 0) {
      setExternalbuttonDisabled(false)
    }
  }, [decision])

  const disableField = status && status !== 'pending'

  const creditApproversListPrimary = creditApprovers.map((item, index) => {
    return (
      <SelectRow
        key={`salesRep_${index}`}
        onClick={() => {
          setCreditApproverId(item.id)
          setCreditApprover(item?.fullName)
          addCreditApprover(id, item.id)
          setExternalbuttonDisabled(false)
        }}
      >
        {item?.fullName}
      </SelectRow>
    )
  })

  const creditApproversListSecondary = creditApprovers.map((item, index) => {
    return (
      <SelectRow
        key={`salesRep_${index}`}
        onClick={() => {
          setCreditApproverSecondaryId(item.id)
          setCreditApproverSecondary(item?.fullName)
          addCreditApprover(id, item.id)
          setExternalbuttonDisabled(false)
        }}
      >
        {item?.fullName}
      </SelectRow>
    )
  })

  const currencyList = ['EUR', 'SEK']

  const currencyOptions = currencyList.map((item, index) => {
    return (
      <SelectRow
        key={`currency_${index}`}
        onClick={() => {
          setCurrency(item)
          setExternalbuttonDisabled(false)
        }}
      >
        {item}
      </SelectRow>
    )
  })
  if (loading) return null

  return (
    <DetailsScreenWrapper>
      <DetailsWrapper>
        <InfoWrapper>
          {creditApplication && creditApplication.user && (
            <CreditApplicationUser user={creditApplication.user} />
          )}
          <DetailsInputWrapper>
            <DetailsHeader>Credit request</DetailsHeader>
            <DetailsUnderline />
            <DetailsLabel>Business ID:</DetailsLabel>
            <DetailsInput value={businessId} disabled={true} />
            <DetailsLabel>Application type:</DetailsLabel>
            <DetailsLabel>{creditApplication.type || '/'}</DetailsLabel>
            <DetailsLabel>Credit amount:</DetailsLabel>
            <DetailsInput
              value={`${creditApplication.requested?.amount} ${creditApplication.requested?.currency}`}
              disabled={true}
            />
          </DetailsInputWrapper>
          <DetailsInputWrapper>
            <DetailsHeader>Internal communication</DetailsHeader>
            <DetailsUnderline />
            <DetailsLabel>Credit department comment:</DetailsLabel>
            <ModalInputWrapper>
              <DetailsTextArea
                placeholder="Add an internal comment..."
                onChange={(e) => {
                  setComments(e.target.value)
                }}
                onClick={() => {
                  setButtonDisabled(false)
                }}
                value={comments}
              />
            </ModalInputWrapper>
            <DetailsDropzoneWrapper>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag and drop and .DOC or .PDF file here or browse to choose a file</p>
                )}
                <p>{uploadedFiles}</p>
              </div>
            </DetailsDropzoneWrapper>
            {creditApplication.documents &&
              creditApplication.documents.map((item) => {
                return (
                  <DetailsFileItem
                  // href={`${item.downloadLink?.URL}`}
                  // target="_blank"
                  // rel="noreferrer"
                  >
                    <DetailsFileItemLink>
                      <LinkIcon />
                    </DetailsFileItemLink>
                    <DetailsFileItemName onClick={() => fileLink(`${item.URL}`)}>
                      {item.filename}
                    </DetailsFileItemName>
                    <DetailsFileItemTrash>
                      <TrashIcon onClick={() => removeFile(item.creditApplicationID, item.docID)} />
                    </DetailsFileItemTrash>
                  </DetailsFileItem>
                )
              })}
            <InputRow>
              <SaveButton disabled={buttonDisabled} onClick={() => saveInternal(id)}>
                Save changes
              </SaveButton>
            </InputRow>
          </DetailsInputWrapper>
          <UboList KYCSessions={creditApplication?.organization?.KYCSessions ?? null} />
          <DetailsInputWrapper>
            <DetailsHeader>Decision and external communication</DetailsHeader>
            <DetailsUnderline />
            <DetailsLabel>Decision</DetailsLabel>
            <ModalInputWrapper>
              <DetailsTextArea
                placeholder="Share a decision message with the Prospect..."
                value={decision}
                onChange={(e) => setDecision(e.target.value)}
                disabled={disableField}
              />
            </ModalInputWrapper>
            <AdditionalInfoText>
              This will be communicated with the prospect ({creditApplication.organization?.name})
            </AdditionalInfoText>
            <DetailsCurrencyHeader>
              <DetailsLabel>Approved credit limit:</DetailsLabel>
              <DetailsLabelCurrency>Currency:</DetailsLabelCurrency>
            </DetailsCurrencyHeader>
            <DetailsCurrencyWrapper>
              <ModalInputWrapperMedium onClick={() => {}}>
                <Input
                  type="number"
                  onChange={(e) => setApprovedCreditLimit(e.target.value)}
                  value={approvedCreditLimit}
                  onClick={() => {
                    setExternalbuttonDisabled(false)
                  }}
                  disabled={disableField}
                />
              </ModalInputWrapperMedium>
              <ModalInputWrapperSmall onClick={() => {}}>
                <CurrencySelect
                  options={currencyOptions}
                  value={currency}
                  selected={currency}
                  onClick={() => {
                    setExternalbuttonDisabled(false)
                  }}
                  disabled={disableField}
                />
              </ModalInputWrapperSmall>
            </DetailsCurrencyWrapper>
            <DetailsUnderline />
            <DetailsLabel>2 x Zevoy Credit Approvers</DetailsLabel>
            <Select
              options={creditApproversListPrimary}
              value={creditApprover}
              selected={creditApprover}
              disabled={disableField}
            />
            <Select
              options={creditApproversListSecondary}
              value={creditApproverSecondary}
              selected={creditApproverSecondary}
              disabled={disableField}
            />
            <InputRow>
              <SaveButton
                disabled={externalbuttonDisabled || disableField}
                onClick={() => saveExternal(id)}
              >
                Save changes
              </SaveButton>
            </InputRow>
          </DetailsInputWrapper>
          <InputRow>
            <SaveButton
              onClick={() => {
                navigate('prompt')
              }}
              disabled={resolveButtonDisabled || disableField}
            >
              Resolve credit application
            </SaveButton>
          </InputRow>
        </InfoWrapper>
      </DetailsWrapper>
    </DetailsScreenWrapper>
  )
}

export default Details
