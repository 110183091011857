import { authMutation, authQuery } from '../../lib/apolloClient'

export const saveCreditApplicationMutationInternal = async (id, comments, decision) => {
  await authMutation(`
      mutation saveCreditApplicationMutationInternal {
        reviewCreditApplication(input:
          {
            id: "${id}",
            comments: "${comments}"
            decision: "${decision}"
        }) {
          success
          }
        }
    `)
}

export const saveCreditApplicationMutationExternal = async (
  id,
  comments,
  decision,
  amount,
  currency,
) => {
  await authMutation(`
      mutation saveCreditApplicationMutationExternal {
        reviewCreditApplication(input:
          {
            id: "${id}",
            comments: "${comments}"
            decision: "${decision}"
            approved: {
              amount: "${amount}"
              currency: "${currency}"
            }
        }) {
          success
          }
        }
    `)
}

export const approveCreditApplication = async (id) =>
  await authMutation(`
    mutation approveCreditApplication {
      approveCreditApplication(
        id: "${id}"
      ) { 
      success 
      }
    }
  `)

export const rejectCreditApplication = async (id) =>
  await authMutation(`
    mutation rejectCreditApplication {
      rejectCreditApplication(
        id: "${id}"
      ) { 
      success 
      }
    }
  `)

export const addCreditApplicationApprover = async (id, userId) =>
  await authMutation(`
    mutation addCreditApplicationApprover {
      addCreditApplicationApprover (
        id: "${id}",
        userID: "${userId}"
      ) {
        success
      }
    }
  `)

export const uploadCreditApplicationDocument = async (creditApplicationID, file) =>
  await authMutation(
    `
    mutation uploadCreditApplicationDocument(
      $creditApplicationID: CreditApplicationID!
      $file: Upload!
    ) {
      uploadCreditApplicationDocument (
        input: {
          creditApplicationID: $creditApplicationID,
          file: $file
        }
      ) {
        success
      }
    }
  `,
    { creditApplicationID, file },
  )

export const removeCreditApplicationDocument = async (creditApplicationID, docID) =>
  await authMutation(
    ` mutation removeCreditApplicationDocument(
    $creditApplicationID: CreditApplicationID!
    $docID: CreditApplicationDocID!
    ) {
      removeCreditApplicationDocument(
        input: {
          creditApplicationID: $creditApplicationID,
          docID: $docID
        }
        ) {
          success
        }
      }
      `,
    { creditApplicationID, docID },
  )

export const getCreditApplication = async (id) => {
  const res = await authQuery(`
  query getCreditApplication {
    creditApplication(id: "${id}") {
      id
      type
      user {
        id
        fullName
      }
      organization {
        id
        status
        createTime
        name
        country
        businessID
        country
        KYCSessions {
          token
          signedBy {
            fullName
          }
          signedAt
          hasUBOs
          ultimateBenefialOwners {
            token
            shareholderPosition
            nationalID
            citizenship
            fullName
            country
            isPEP
            passportLink {
              expiresAt
              URL
            }
          }
        }
      }
      createTime
      status
      requested {
        amount
        currency
      }
      documents {
        creditApplicationID
        docID
        filename
        URL
      }
      approved {
        amount
        currency
      }
      max {
        amount
        currency
      }
      comments
      decision
      decisionTime
      approvers {
        id
        name
        fullName
        givenName
      }
    }
  }`)
  return res
}

export const getCreditApplicationDocuments = async (id) => {
  const res = await authQuery(`
  query getCreditApplicationDocuments {
    creditApplication(id: "${id}") {
      documents {
        creditApplicationID
        docID
        filename
        URL
      }
    }
  }`)
  return res
}

export const getRoles = async () =>
  await authQuery(`
    query getRoles {
      me {
        roles {
          role
          organization {
            id
          }
        }
      }
    }
  `)
