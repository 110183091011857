import { authMutation, authQuery } from '../../lib/apolloClient'
import { gql } from '@apollo/client'

export const GET_ORGANIZATIONS = gql`
  query getOrganizations($nextCursor: ID) {
    organizations(input: { from: $nextCursor, limit: 2000 }) {
      totalCount
      edges {
        cursor
        edge {
          id
          name
          employeeRange {
            range
          }
          fundingHistory {
            type
          }
          status
          invites {
            id
          }
          plan {
            type
          }
          salesRep {
            id
            fullName
          }
          plan {
            startTime
          }
          invoiceSetting {
            paymentTerms
          }
          creditApplications {
            id
            status
          }
          KYCSessions {
            token
            signedAt
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        nextCursor
        size
        hasNextPage
      }
    }
  }
`

export const getOrganizationAcubiz = gql`
  query getOrganizationAcubiz($id: OrganizationID!) {
    organization(id: $id) {
      id
      acubizOrgID
    }
  }
`

export const GET_ORGANIZATION_MAX_ATM_LIMIT = gql`
  query getOrganizationMaxAtmLimit($organizationID: OrganizationID!) {
    organization(id: $organizationID) {
      id
      maxCardATMLimit {
        amount
        currency
      }
    }
  }
`

export const UPDATE_ORGANIZATION_MAX_ATM_LIMIT = gql`
  mutation updateOrganizationMaxAtmLimit($input: UpdateOrganizationMaxAtmLimitInput!) {
    updateOrganizationMaxAtmLimit(input: $input) {
      status
      organization {
        id
        maxCardATMLimit {
          amount
          currency
        }
      }
    }
  }
`

export const UPDATE_ORGANIZATION_PAYMENT_REFERENCE = gql`
  mutation updateOrganizationPaymentReference(
    $organizationID: OrganizationID!
    $paymentReferenceInput: String!
  ) {
    updatePaymentReference(
      organizationID: $organizationID
      paymentReferenceInput: $paymentReferenceInput
    ) {
      id
      paymentReference
    }
  }
`

export const DELETE_ORGANIZATION_PAYMENT_REFERENCE = gql`
  mutation deleteOrganizationPaymentReference($organizationID: OrganizationID!) {
    deletePaymentReference(organizationID: $organizationID) {
      id
      paymentReference
    }
  }
`

export const CHANGE_ORGANIZATION_BANK_ACCOUNT = gql`
  mutation changeOrganizationBankAccount(
    $organizationID: OrganizationID!
    $bankAccountID: BankAccountID!
  ) {
    changeOrganizationBankAccount(organizationID: $organizationID, bankAccountID: $bankAccountID) {
      id
      bankAccount {
        id
      }
    }
  }
`

export const DELETE_ORGANIZATION_BANK_ACCOUNT = gql`
  mutation deleteOrganizationBankAccount($organizationID: OrganizationID!) {
    deleteOrganizationBankAccount(organizationID: $organizationID) {
      id
      bankAccount {
        id
      }
    }
  }
`

export const ENROLL_ORGANIZATION_ACUBIZ = gql`
  mutation acubizSetOrganizationID($orgID: OrganizationID!, $acubizOrganizationID: String!) {
    acubizSetOrganizationID(orgID: $orgID, acubizOrganizationID: $acubizOrganizationID) {
      success
      organization {
        id
      }
    }
  }
`

export const GET_BANK_ACCOUNTS_LIST = gql`
  query getBankAccountsList($organizationId: OrganizationID!) {
    bankAccountsResults(organizationID: $organizationId) {
      id
      name
    }
  }
`

export const GET_ORGANIZATION_BANK_ACCOUNT_SETTING = gql`
  query getOrganizationBankAccountSetting($organizationId: OrganizationID!) {
    organization(id: $organizationId) {
      id
      paymentReference
      bankAccount {
        name
        id
      }
    }
  }
`

export const CLEAR_ORGANIZATION_ACUBIZ = gql`
  mutation acubizClearOrganizationID($orgID: OrganizationID!) {
    acubizClearOrganizationID(orgID: $orgID) {
      success
      organization {
        id
      }
    }
  }
`

export const getOrganizationM2 = gql`
  query getOrganizationM2($id: OrganizationID!) {
    organization(id: $id) {
      id
      m2OrgID
    }
  }
`

export const ENROLL_ORGANIZATION_M2 = gql`
  mutation m2SetOrganizationID($orgID: OrganizationID!, $m2OrganizationID: String!) {
    m2SetOrganizationID(orgID: $orgID, m2OrganizationID: $m2OrganizationID) {
      success
      organization {
        id
      }
    }
  }
`

export const CLEAR_ORGANIZATION_M2 = gql`
  mutation m2ClearOrganizationID($orgID: OrganizationID!) {
    m2ClearOrganizationID(orgID: $orgID) {
      success
      organization {
        id
      }
    }
  }
`

export const GET_ORGANIZATION = gql`
  query getOrganization($id: OrganizationID!) {
    organization(id: $id) {
      id
      name
      businessID
      bezalaEnrollment {
        enrollTime
      }
      featureSettings {
        personalLiabilityCardsEnabled
      }
      country
      salesRep {
        id
        fullName
      }
      onboardingFee
      onboardingFeeBillingDate
      hasActiveCard
      plan {
        organization {
          id
        }
        conditions {
          startDate
          type
          amountPerUser
          amountPerBusinessCardUser
          amountPerClaimUser
          amountPerSubscription
          minimumBillingFee
          freeClaimForBusinessCardUser
          fixedFee
          freeClaimUsers
          freeBusinessCardUsers
          freeSubscriptionCards
        }
        additionalTerms
        comments
        type
        startTime
        fxFee
        cashback {
          percentage
        }
      }
      invoiceSetting {
        paymentTerms
      }
      employeeRange {
        range
      }
      status
      creditApplications {
        id
        status
      }
      accounts {
        id
        creditLimit
        balance
      }
      allAccounts {
        id
        name
        status
        interestPostingEnabled
        paymentTerms
        parent {
          id
        }
      }
      note {
        cardPotential
        claimsUsers
        subscriptionPotential
        spendPotential
        channel {
          id
          channel
        }
      }
      members(input: { from: null, limit: null }) {
        totalCount
        edges {
          edge {
            user {
              id
              fullName
              cardRequests {
                id
                status
              }
            }
            organization {
              id
            }
          }
        }
      }
    }
  }
`

export const ORG_FEATURES = gql`
  fragment orgFeatures on Organization {
    featureSettings {
      personalLiabilityCardsEnabled
    }
  }
`

export const UPDATE_ORGANIZATION_FEATURE = gql`
  ${ORG_FEATURES}
  mutation updateOrganizationFeatures($input: UpdateOrganizationFeatureSettingsInput!) {
    updateOrganizationFeatureSetting(input: $input) {
      organization {
        id
        ...orgFeatures
      }
      status
    }
  }
`

export const UPDATE_ORGANIZATION_STATUS = gql`
  mutation setOrganizationStatus($orgID: OrganizationID!, $status: OrganizationStatus!) {
    setOrganizationStatus(id: $orgID, status: $status) {
      success
      organization {
        id
        status
      }
    }
  }
`

export const updateCreditLimit = gql`
  mutation updateCreditLimit(
    $accountId: AccountID!
    $amount: MonetaryAmount!
    $currency: Currency!
  ) {
    updateOrganizationAccount(
      input: { accountID: $accountId, amount: { amount: $amount, currency: $currency } }
    ) {
      success
    }
  }
`

export const updatePaymentTerms = gql`
  mutation updatePaymentTerms(
    $organizationID: OrganizationID!
    $accountID: AccountID!
    $paymentTerms: Int!
  ) {
    updatePaymentTerms(
      input: { organizationID: $organizationID, accountID: $accountID, paymentTerms: $paymentTerms }
    ) {
      id
      invoiceSetting {
        paymentTerms
      }
    }
  }
`

export const getOrganizationUser = async (userId, organizationId) =>
  await authQuery(`
  query getOrganizationUser {
    user(id: "${userId}") {
      userOrganization(id: "${organizationId}") {
        user {
          id,
          fullName,
          cardRequests {
            id,
            status
          }
        }
      }
    }
  }
  `)

export const activateBezala = async (orgId) => {
  try {
    const mutation = `
      mutation activateBezala {
        enrollOrganizationToBezala (
          org: "${orgId}"
        ) {
          success
        }
      }
  `
    await authMutation(mutation)
  } catch (e) {
    throw new Error(e?.message ?? e)
  }
}

export const UPDATE_ORGANIZATION_PLANS = gql`
  mutation updatePlanConditions($id: OrganizationID!, $conditions: [InputCondition!]!) {
    updatePlanConditions(id: $id, planConditions: $conditions) {
      success
    }
  }
`

export const SET_ORGANIZATION_SALES_REP = gql`
  mutation setOrganizationSalesRep($organizationId: OrganizationID!, $salesRepId: UserID!) {
    setOrganizationSalesRep(organizationID: $organizationId, salesRepID: $salesRepId) {
      success
    }
  }
`

export const SET_ORGANIZATION_PLAN = gql`
  mutation setOrganizationPlan($input: PlanInput!, $organizationId: OrganizationID!) {
    setAdvancedOrganizationPlan(input: $input, organizationID: $organizationId) {
      success
    }
  }
`

export const UPDATE_ORGANIZATION_ACCOUNT_STATUS = gql`
  mutation updateOrganizationAccountStatus($input: UpdateOrganizationAccountStatusInput!) {
    updateOrganizationAccountStatus(input: $input) {
      success
      organization {
        id
        allAccounts {
          id
          status
        }
      }
    }
  }
`

export const ENABLE_ORGANIZATION_ACCOUNT_INTEREST_POSTING = gql`
  mutation enableInterestPosting($accountID: AccountID!) {
    enableInterestPosting(accountID: $accountID) {
      success
      organization {
        id
        allAccounts {
          id
          interestPostingEnabled
        }
      }
    }
  }
`

export const DISABLE_ORGANIZATION_ACCOUNT_INTEREST_POSTING = gql`
  mutation disableInterestPosting($accountID: AccountID!) {
    disableInterestPosting(accountID: $accountID) {
      success
      organization {
        id
        allAccounts {
          id
          interestPostingEnabled
        }
      }
    }
  }
`
