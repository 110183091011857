import { useNavigate } from 'react-router-dom'
import { Create, SaveButton, SimpleForm, TextInput } from 'react-admin'
import { ContentWrapper, ScreenWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import BackButtonArrow from '../../../lib/components/BackButton'

export const PaymentBatchCreate = () => {
  const navigate = useNavigate()
  return (
    <ScreenWrapper>
      <ContentWrapper>
        <Header
          title={'Edit Payment Batch'}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate('/admin/paymentBatch')
              }}
            />
          }
        />
        <Create redirect="list">
          <SimpleForm toolbar={false}>
            <TextInput source="description" multiline={true} rows={4} fullWidth />
            <SaveButton />
          </SimpleForm>
        </Create>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
