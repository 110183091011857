import { useEffect, useState } from 'react'
import { useGetSalesRepsQuery } from '../../GeneratedGraphQLTypes'
import { SalesRepresentatives } from './types'

interface UseSalesRepsHookResult {
  salesReps: SalesRepresentatives
  error: string
}
export const useSalesReps = (): UseSalesRepsHookResult => {
  const getSalesRepsQuery = useGetSalesRepsQuery()
  const [salesReps, setSalesReps] = useState<SalesRepresentatives>([])
  const [error, setError] = useState<string>('')

  useEffect(() => {
    const salesRep = getSalesRepsQuery?.data?.salesRep
    if (salesRep) {
      setSalesReps(salesRep)
    }
  }, [getSalesRepsQuery.data])

  if (getSalesRepsQuery.error) {
    setError(getSalesRepsQuery.error.message)
  }
  return { salesReps, error }
}
