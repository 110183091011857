import React from 'react'
import { BackButtonWrapper } from '../styles'
import { ReactComponent as BackArrow } from '../assets/icons/backArrow.svg'

const BackButtonArrow = (props) => {
  return (
    <BackButtonWrapper onClick={props.onClick}>
      <BackArrow />
    </BackButtonWrapper>
  )
}

export default BackButtonArrow
