import React from 'react'
import errorStore from './store'
import { SvgButtonWrapper } from '../../lib/styles'

import { ReactComponent as Close } from '@zevoy/common/assets/icons/close.svg'
import { ReactComponent as ErrorImage } from '@zevoy/common/assets/icons/error_page_image.svg'

import { ErrorFrameComponent } from '@zevoy/common/src/components/Error/ErrorFrame'
import {
  ErrorHeader,
  ErrorWrapper,
  RequestId,
  CloseButton,
  ErrorContentWrapper,
  ErrorMessage,
  ErrorPageWrapper,
  VerticallyAndHorizontallyCentered,
} from '@zevoy/common/src/components/Error/styled'

const Error = () => {
  const { error, errorObject, requestId, setError } = errorStore()
  return error ? (
    <ErrorPageWrapper>
      <CloseButton>
        <SvgButtonWrapper
          onClick={() => {
            setError(false, '', undefined)
          }}
        >
          <Close />
        </SvgButtonWrapper>
      </CloseButton>
      <ErrorContentWrapper>
        <VerticallyAndHorizontallyCentered inModal={true}>
          <ErrorHeader>Error</ErrorHeader>
          <ErrorMessage>{errorObject?.message ?? 'Something went wrong'}</ErrorMessage>
          <ErrorImage />
          <ErrorWrapper>
            {requestId && <RequestId>Request ID: {requestId}</RequestId>}
            <ErrorFrameComponent errorObject={errorObject} />
          </ErrorWrapper>
        </VerticallyAndHorizontallyCentered>
      </ErrorContentWrapper>
    </ErrorPageWrapper>
  ) : (
    <></>
  )
}

export default Error
