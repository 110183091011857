import React from 'react'
import { ModalInputLabel } from '../../lib/styles'
import tw from 'twin.macro'
import { InputWrapper } from '../CreditApplication/styles'
import { Condition } from './Condition'
import { PlanCondition } from '../../GeneratedGraphQLTypes'
import { Button } from '../Prospects/styles'
import { addOneMonthToDate, isFutureDate, roundDateToMonth } from '../../lib/utils'
import { getDefaultPlan } from './utils'
import { usePlanConditionsState } from './store'
import { ErrorMessage } from '@zevoy/common/src/components/ErrorMessage'
import { CountryKey } from '@zevoy/common/src/types/Generic'
import { DateTime } from 'luxon'
const Wrapper = tw(InputWrapper)`text-left`

const AddPlanButton = tw(Button)`py-2 w-max cursor-pointer`

interface PlansProps {
  country: CountryKey
  noTitleLabel?: boolean
  plansActive?: boolean
}
export const Plans = ({ country, noTitleLabel, plansActive }: PlansProps) => {
  const { planConditions, setPlanConditions, setError, error } = usePlanConditionsState()

  const addPlan = (planConditions: PlanCondition[]) => {
    if (!!planConditions.length) {
      setPlanConditions([getDefaultPlan(country)])
    }

    const lastPlanDate = !!planConditions?.length
      ? planConditions[planConditions.length - 1].startDate
      : new Date()
    const lastDate = lastPlanDate > new Date() ? lastPlanDate : new Date()
    const nextDate = roundDateToMonth(addOneMonthToDate(lastDate))
    setPlanConditions([
      ...planConditions,
      {
        ...getDefaultPlan(country, nextDate),
      },
    ])

    setError('')
  }

  const ongoingPlanIndex = planConditions.reduce((acc, plan, index) => {
    return plan.startDate <= new Date() ? index : acc
  }, -1)

  return (
    <Wrapper>
      {!noTitleLabel && <ModalInputLabel>Plans:</ModalInputLabel>}
      {planConditions?.map((plan: PlanCondition, index) => (
        <Condition
          currentCondition={plan}
          key={plan.startDate}
          country={country}
          index={index}
          isReadOnly={plansActive && !isFutureDate(new Date(plan.startDate))}
          isOngoing={plansActive && ongoingPlanIndex === index}
          endDate={
            planConditions[index + 1]?.startDate
              ? DateTime.fromJSDate(planConditions[index + 1].startDate)
                  .minus({ days: 1 })
                  .toJSDate()
              : undefined
          }
          isLastOne={planConditions.length === 1}
        />
      ))}
      {error && <ErrorMessage error={error} />}
      <AddPlanButton
        type="button"
        onClick={() => {
          addPlan(planConditions)
        }}
      >
        + Add plan
      </AddPlanButton>
    </Wrapper>
  )
}
