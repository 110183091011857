import { KycOpeningHour, OpeningHour } from '../../../GeneratedGraphQLTypes'
import { DayOrder } from './constants'
import { required } from 'react-admin'

export const validateAgreementChannel = required('A agreement channel must be selected')

export const sortOpeningHours = (openingHours: KycOpeningHour[] | OpeningHour[]) => {
  return openingHours.sort((a, b) => {
    // First sort by day of the week
    const dayComparison = DayOrder[a.dayOfWeek] - DayOrder[b.dayOfWeek]
    if (dayComparison !== 0) {
      return dayComparison
    }

    // If same day, and not opening 24h then sort by opening time
    if (a.openingTime && b.openingTime) {
      a.openingTime.localeCompare(b.openingTime)
    }

    return 0
  })
}

// Format the time as "HH:MM:SS"
export const formatTimeOpeningHour = (hours: number, minutes: number) => {
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`
}
