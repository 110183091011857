export const csvToJson = (csv: string) => {
  const lines = csv.split('\n')
  const result = []

  // Function to detect delimiter
  const detectDelimiter = (line: string) => {
    const commas = line.split(',').length - 1
    const semicolons = line.split(';').length - 1
    return commas > semicolons ? ',' : ';'
  }

  // Function to split a line by detected delimiter, considering text qualifiers
  const splitLine = (line: string, delimiter: string) => {
    const values = []
    let valueBuffer = ''
    let insideQuotes = false

    for (const char of line) {
      if (char === '"') {
        insideQuotes = !insideQuotes
      } else if (char === delimiter && !insideQuotes) {
        values.push(valueBuffer.trim())
        valueBuffer = ''
      } else {
        valueBuffer += char
      }
    }

    values.push(valueBuffer.trim()) // Push the last value
    return values
  }

  // Detect the delimiter (either ',' or ';')
  const delimiter = detectDelimiter(lines[0])

  const headers = splitLine(lines[0], delimiter)

  for (let i = 1; i < lines.length; i++) {
    // Skip empty lines
    if (lines[i].trim() === '') continue

    let obj: { [key: string]: string } = {}
    let currentline = splitLine(lines[i], delimiter)

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j]
    }

    result.push(obj)
  }

  return result
}
