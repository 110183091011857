import { Datagrid, List, TextField, FunctionField } from 'react-admin'

import { ZevoyList } from '../styles'
import { defaultRoles, postFilters } from './Filters'
import { ScopedRole } from '../../../GeneratedGraphQLTypes'
import { ScopedRolesList } from './ScopedRolesList'

export enum ReadableScopedRole {
  creditApprover = 'Credit approver',
  salesRep = 'Sales rep',
  admin = 'Admin',
  superAdmin = 'SuperAdmin',
  financeBro = 'Finance Bro',
  merchantManager = 'Merchant Manager',
}

export type UserRecord = {
  scopedRoles: ScopedRole[]
}

export const UsersList = () => {
  return (
    <>
      <List filters={postFilters} filterDefaultValues={{ scopedRoles: defaultRoles }}>
        <ZevoyList>
          <Datagrid rowClick={'edit'} bulkActionButtons={false}>
            <TextField source="fullName" label="User name" />
            <FunctionField
              label="Scoped role"
              render={(record: UserRecord) => {
                return <ScopedRolesList record={record} />
              }}
            />
          </Datagrid>
        </ZevoyList>
      </List>
    </>
  )
}
