import { useState } from 'react'
import { Confirm, useNotify, useRecordContext } from 'react-admin'
import { Button } from '@mui/material'
import get from 'lodash/get'
import { useCloseCardMutation, CardCloseReason } from '../../../../GeneratedGraphQLTypes'

export const CloseButton = ({ source, label }: { source: string; label: string }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [closeCard, { loading }] = useCloseCardMutation()
  const [open, setOpen] = useState(false)

  const value = get(record, source!)

  const onClose = async () => {
    closeCard({
      variables: {
        cardID: value,
        reason: CardCloseReason.Fraud,
      },
      onCompleted: () => {
        notify('card status successfully updated.', { type: 'success' })
        handleDialogClose()
      },
      onError: () => {
        notify('Card status update failed.', { type: 'error' })
        handleDialogClose()
      },
    })
  }
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  return (
    <>
      <Button variant="contained" id={value} onClick={handleClick}>
        {label}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={`Close card #${record && value}`}
        content="Are you sure you want to close this card due to fraud?"
        onConfirm={onClose}
        onClose={handleDialogClose}
      />
    </>
  )
}
