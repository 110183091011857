import React from 'react'
import tw from 'twin.macro'
import Modal from '../../../lib/components/ModalWrapper'
import { Button } from '@zevoy/common/src/components'
import { ComponentVariants, Sizes } from '@zevoy/common/src/constants'

const ConfirmDialogWrapper = tw.div``
const Message = tw.div`py-2 font-slussenLight`
const Title = tw.div`font-slussenSemibold text-2xl flex flex-1 justify-center items-center`
const TitleWrapper = tw.div`w-full flex flex-row pt-10 pb-2`
const ButtonWrapper = tw.div`w-full items-center py-4 space-x-2 flex justify-center`

type DeleteConfirmModalProps = {
  toggleCancelDialog: (input: boolean) => void
  handleDelete: () => void
  message: string
}

const DeleteConfirmModal = ({
  toggleCancelDialog,
  handleDelete,
  message,
}: DeleteConfirmModalProps) => {
  return (
    <Modal
      exit={() => {
        toggleCancelDialog(false)
      }}
    >
      <ConfirmDialogWrapper>
        <TitleWrapper>
          <Title>Confirm Removal</Title>
        </TitleWrapper>
        <Message>{message}</Message>
        <ButtonWrapper>
          <Button
            onClick={() => {
              toggleCancelDialog(false)
            }}
            variant={ComponentVariants.Secondary}
            size={Sizes.Medium}
          >
            Cancel
          </Button>

          <Button onClick={handleDelete} variant={ComponentVariants.Primary} size={Sizes.Medium}>
            Remove
          </Button>
        </ButtonWrapper>
      </ConfirmDialogWrapper>
    </Modal>
  )
}

export default DeleteConfirmModal
