import { redirectUrl, url as uri } from './environments'
import { ApolloClient, gql, InMemoryCache, from, DefaultOptions } from '@apollo/client'
import { getAuthToken } from './queries'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { unstable_batchedUpdates } from 'react-dom'
import errorStore from '../components/Error/store'
import { DateTime } from 'luxon'
import { LOCAL_STORAGE_VARIABLES } from '@zevoy/common/src/constants'
import { accessTokenGet, refreshTokenGet } from '@zevoy/common/src/utils/auth-utils'
import { InMemoryCacheConfig } from '@apollo/client/cache/inmemory/types'

const httpLink = createUploadLink({
  uri,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const requestId = error.extensions['request-id'] as string | undefined
      console.log(graphQLErrors, error)
      unstable_batchedUpdates(() => {
        errorStore.getState().setError(true, error, requestId)
      })
    })

  if (networkError) {
    const errorMsg = `[Network error]: ${networkError}`
    console.log(errorMsg)
    unstable_batchedUpdates(() => {
      errorStore.getState().setError(true, networkError, undefined)
    })
  }
})

const regularHeaders = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  }
})

export const client = new ApolloClient({
  // @ts-ignore
  link: regularHeaders.concat(httpLink),
  cache: new InMemoryCache(),
})

/** @deprecated */
export const authQuery = async (query: string, redirect?: string) => {
  try {
    const refToken = refreshTokenGet()

    !refToken && window.open(redirect || `${redirectUrl}/login`, '_self')
    const authTokenFetch =
      refToken &&
      (await getAuthToken(refToken.token).catch((e) => {
        console.log('AuthToken Fetch failed', e)
        localStorage.removeItem(LOCAL_STORAGE_VARIABLES.REFRESH_TOKEN)
        localStorage.removeItem(LOCAL_STORAGE_VARIABLES.AUTH_TOKEN)
        window.open(redirect || `${redirectUrl}/login`, '_self')
      }))
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: authTokenFetch ? `Bearer ${authTokenFetch.data.accessToken}` : '',
        },
      }
    })

    const authClient = new ApolloClient({
      // @ts-ignore
      link: authLink.concat(from([errorLink, httpLink])),
      cache: new InMemoryCache(),
    })

    return authClient.query({
      query: gql`
        ${query}
      `,
    })
  } catch (e) {
    console.log('QUERY ERROR', e)
    localStorage.removeItem(LOCAL_STORAGE_VARIABLES.REFRESH_TOKEN)
    localStorage.removeItem(LOCAL_STORAGE_VARIABLES.AUTH_TOKEN)
  }
}

/** @deprecated */
export const authMutation = async (
  mutation: string,
  variables?: { creditApplicationID: any; file?: any; docID?: any } | undefined,
  redirect?: undefined,
) => {
  try {
    const refToken = refreshTokenGet()

    !refToken && window.open(redirect || `${redirectUrl}/login`, '_self')

    const authTokenFetch =
      refToken &&
      (await getAuthToken(refToken.token).catch((e) => {
        console.log('AuthToken Fetch failed', e)
        localStorage.removeItem(LOCAL_STORAGE_VARIABLES.REFRESH_TOKEN)
        localStorage.removeItem(LOCAL_STORAGE_VARIABLES.AUTH_TOKEN)
        window.open(redirect || `${redirectUrl}/login`, '_self')
      }))

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: authTokenFetch ? `Bearer ${authTokenFetch.data.accessToken}` : '',
        },
      }
    })

    // const authClient = new ApolloClient({
    //   link: authLink.concat(httpLink),
    //   cache: new InMemoryCache()
    // });
    const authClient = new ApolloClient({
      // @ts-ignore
      link: authLink.concat(from([errorLink, httpLink])),
      cache: new InMemoryCache(),
    })

    return authClient.mutate({
      mutation: gql`
        ${mutation}
      `,
      variables,
    })
  } catch (e) {
    console.log('MUTATION ERROR', e)
  }
}

export const nonAuthClient = new ApolloClient({
  // @ts-ignore
  link: regularHeaders.concat(httpLink),
  cache: new InMemoryCache(),
})

const authLink = setContext(async (_, { headers }) => {
  const currentRefreshToken = refreshTokenGet()
  const loginPage = () => {
    // TODO add redirect to current url
    window.open(`${redirectUrl}/login`, '_self')
  }

  const newAccessTokenMutation = {
    mutation: gql`
      mutation newAccessTokenMutation {
        accessToken(
          RefreshToken: "${currentRefreshToken.token}"
        )
      }`,
  }
  const fetchAccessToken = async () => {
    const newAccessTokenResult: any = await nonAuthClient
      .mutate(newAccessTokenMutation)
      .then((e) => e.data)
    if (!newAccessTokenResult) {
      localStorage.removeItem(LOCAL_STORAGE_VARIABLES.REFRESH_TOKEN)
      localStorage.removeItem(LOCAL_STORAGE_VARIABLES.ACCESS_TOKEN)
      loginPage()
      return
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_VARIABLES.ACCESS_TOKEN,
        `{ "token": "${newAccessTokenResult.accessToken}", "time": "${DateTime.now()}"}`,
      )
      return newAccessTokenResult
    }
  }

  const currentAccessToken = accessTokenGet()
  if (currentAccessToken) {
    var end = DateTime.now()
    var start = DateTime.fromISO(currentAccessToken.time)
    const duration = end.diff(start).as('minutes')
    if (duration < 5) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${currentAccessToken.token}`,
        },
      }
    }
  }

  try {
    const data: { accessToken: string } = await fetchAccessToken()
    return {
      headers: {
        ...headers,
        authorization: data ? `Bearer ${data.accessToken}` : '',
      },
    }
  } catch (e) {
    const err: Error = e as Error
    console.error(err?.message ?? e)

    // It's expected that the error is due to an expired refresh token,
    // therefore re-direct to the login page
    loginPage()
  }
})
const defaultOptions: DefaultOptions = {}

export const authClient = (cacheConfig?: InMemoryCacheConfig) =>
  new ApolloClient({
    // @ts-ignore
    link: from([errorLink, authLink, httpLink]),
    cache: cacheConfig ? new InMemoryCache(cacheConfig) : new InMemoryCache(),
    defaultOptions: defaultOptions,
    connectToDevTools: true,
  })
