import {
  Button,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  useRecordContext,
} from 'react-admin'
import ContentCreate from '@mui/icons-material/Create'

import { ContentWrapper, ScreenWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import BackButtonArrow from '../../../lib/components/BackButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Batch, ZevoyTypeField } from './PaymentTransactionList'

const ShowActions = ({ batch }: { batch?: Batch }) => {
  const navigate = useNavigate()
  const record = useRecordContext()
  return (
    <TopToolbar>
      <Button
        disabled={batch?.status !== 'open'}
        onClick={() => {
          navigate(`/admin/paymentTransaction/${record.id}?batchId=${batch?.id}`)
        }}
        label="ra.action.edit"
      >
        <ContentCreate />
      </Button>
    </TopToolbar>
  )
}

export const PaymentTransactionShow = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const batchId = new URLSearchParams(location.search).get('batchId')

  const dataProvider = useDataProvider()
  const [batch, setBatch] = useState<Batch | undefined>()

  const refetchBatch = useCallback(() => {
    dataProvider.getOne('paymentBatch', { id: batchId }).then((res) => {
      setBatch(res.data)
    })
  }, [dataProvider, batchId])

  useEffect(() => {
    refetchBatch()
  }, [refetchBatch])

  return (
    <ScreenWrapper>
      <ContentWrapper>
        <Header
          title={'Payment Transaction'}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate(`/admin/paymentTransaction?filter={"batchId": ${batchId}}`)
              }}
            />
          }
        />
        <Show actions={<ShowActions batch={batch} />}>
          <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="status" />
            <ZevoyTypeField source="zevoyType" />
            <TextField source="amount" />
            <TextField source="currency" />
            <ReferenceField source="enfuceTransactionId" reference="enfuceTransactions" />
            <DateField source="enfuceDate" />
            <TextField source="enfuceText" />
            <TextField source="organization.name" />
            <TextField source="enfuceAccountId" />
            <TextField source="externalId" label="External Id" />
            <TextField source="comments" />
            <TextField source="resCode" />
            <TextField source="resBody" />
          </SimpleShowLayout>
        </Show>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
