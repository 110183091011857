import React from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { Alignments } from '../../constants'
import { FlattenSimpleInterpolation } from 'styled-components'

type WrapperProps = {
  align?: Alignments
  verticalAlign?: Alignments
  fullWidth?: boolean
  styled?: FlattenSimpleInterpolation
  isWrapped?: boolean
  gap?: number
}

type InlineProps = React.PropsWithChildren<WrapperProps>

const Wrapper = styled.div<WrapperProps>`
  ${tw`flex flex-row items-center gap-4`}
  ${({ align }) =>
    align === Alignments.Left
      ? tw`justify-start`
      : align === Alignments.Center
      ? tw`justify-center`
      : align === Alignments.Right
      ? tw`justify-end`
      : align === Alignments.Between
      ? tw`justify-between`
      : tw`justify-start`}
  ${({ verticalAlign }) =>
    verticalAlign &&
    (verticalAlign === Alignments.Start
      ? tw`items-start`
      : verticalAlign === Alignments.Center
      ? tw`items-center`
      : verticalAlign === Alignments.End
      ? tw`items-end`
      : tw`items-start`)}
  ${({ fullWidth }) => fullWidth && tw`w-full`}
  ${({ isWrapped }) => isWrapped && tw`flex-wrap`}
  ${({ gap }) =>
    (gap || gap === 0) &&
    css`
    gap: calc(${theme`spacing.1`} * ${gap});
  }
  `}
  ${({ styled }) =>
    styled &&
    css`
      ${styled}
    `}
`
export const Inline = ({
  children,
  align = Alignments.Left,
  verticalAlign,
  isWrapped,
  fullWidth,
  gap,
  styled,
}: InlineProps) => {
  return (
    <Wrapper
      align={align}
      fullWidth={fullWidth}
      styled={styled}
      verticalAlign={verticalAlign}
      isWrapped={isWrapped}
      gap={gap}
    >
      {children}
    </Wrapper>
  )
}
