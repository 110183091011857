import React, { useState } from 'react'
import {
  VirtualWalletListRow,
  VirtualWalletCardholderHeaderName,
  VirtualWalletMaskedCardNumber,
  VirtualWalletExpanded,
  VirtualWalletMainWrapper,
  VirtualWalletRowHeader,
  VirtualWalletTokenRefIDLabel,
  VirtualWalletRequestorIDLabel,
  VirtualWalletProviderLabel,
  VirtualWalletListRowHeader,
  VirtualWalletTokenItem,
  ChevronWrapper,
} from './styles'

import { ReactComponent as ChevronDown } from '../../lib/assets/icons/chevronDown.svg'
import { ReactComponent as ChevronRight } from '../../lib/assets/icons/chevronRightDark.svg'

const VirtualWalletListItem = ({ card }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <VirtualWalletMainWrapper>
      <VirtualWalletListRow onClick={() => setIsOpen(!isOpen)}>
        <VirtualWalletRowHeader>
          <ChevronWrapper>{isOpen ? <ChevronDown /> : <ChevronRight />}</ChevronWrapper>
          <VirtualWalletCardholderHeaderName>
            {card.cardHolderName}
          </VirtualWalletCardholderHeaderName>
          <VirtualWalletMaskedCardNumber>{card.maskedCardNumber}</VirtualWalletMaskedCardNumber>
        </VirtualWalletRowHeader>
        {isOpen && (
          <VirtualWalletExpanded>
            <VirtualWalletListRowHeader>
              <VirtualWalletTokenRefIDLabel>Token Unique Reference ID</VirtualWalletTokenRefIDLabel>
              <VirtualWalletRequestorIDLabel>Requestor ID</VirtualWalletRequestorIDLabel>
              <VirtualWalletProviderLabel>Wallet Provider</VirtualWalletProviderLabel>
              <VirtualWalletProviderLabel>Status</VirtualWalletProviderLabel>
            </VirtualWalletListRowHeader>
            {card.walletTokens?.map((walletToken) => {
              return (
                <VirtualWalletListRowHeader>
                  <VirtualWalletTokenItem>
                    {walletToken.tokenUniqueReferenceID}
                  </VirtualWalletTokenItem>
                  <VirtualWalletTokenItem>{walletToken.requestorID}</VirtualWalletTokenItem>
                  <VirtualWalletTokenItem>{walletToken.provider}</VirtualWalletTokenItem>
                  <VirtualWalletTokenItem>{walletToken.status}</VirtualWalletTokenItem>
                </VirtualWalletListRowHeader>
              )
            })}
          </VirtualWalletExpanded>
        )}
      </VirtualWalletListRow>
    </VirtualWalletMainWrapper>
  )
}

export default VirtualWalletListItem
