import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'

import { ReactComponent as Search } from '../assets/icons/search.svg'
import { getSessionStorageItem, setSessionStorageItem } from '../utils'

const RowWrapper = tw.section`flex flex-grow px-3`
const Content = tw.section`
  flex flex-grow py-1
  bg-lightGrey shadow-outline rounded-md min-w-full rounded-full
`
const SearchInput = tw.input`
  bg-lightGrey px-1 text-sm font-slussenLight
  h-auto w-full
  focus:border-lightGrey placeholder-menuGrey placeholder-opacity-25 
  text-menuGrey border-none focus:outline-none rounded-full
`
const IconWrapper = tw.div`pl-1 h-8 w-8 flex justify-center items-center`

const SESSION_STORAGE_KEY = 'organizations_searchword'

const Searchbar = (props) => {
  const [value, setValue] = useState('')

  const handleChange = (newValue) => {
    setValue(newValue)

    if (props.onChange) props.onChange(newValue)
    setSessionStorageItem(SESSION_STORAGE_KEY, newValue)
  }

  useEffect(() => {
    const storedValue = getSessionStorageItem(SESSION_STORAGE_KEY)
    if (storedValue) {
      setValue(storedValue)
      if (props.onChange) props.onChange(storedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RowWrapper>
      <Content>
        <IconWrapper>
          <Search fill="#0A162E" fillOpacity="0.4" />
        </IconWrapper>
        <SearchInput
          placeholder={props.placeholder || 'Search'}
          value={value}
          onChange={(e) => {
            handleChange(e.target.value)
          }}
        />
      </Content>
    </RowWrapper>
  )
}

export default Searchbar
