import { DetailsHeader, DetailsUnderline } from '../styles'
import { SaveButton } from '../../../lib/styles'
import tw from 'twin.macro'
import { InfoWrapper } from '../../Prospects/styles'
import { TextInput } from '@zevoy/common/src/components/TextInput'
import { useParams } from 'react-router-dom'
import { ChangeEvent, useState } from 'react'
import {
  CardType,
  useGetOrganizationMaxAtmLimitQuery,
  useUpdateOrganizationMaxAtmLimitMutation,
} from '../../../GeneratedGraphQLTypes'
import { toast } from 'react-toastify'

const Wrapper = tw(InfoWrapper)`p-0 pt-8`

export const ATMWithdrawalLimits = () => {
  const { id: organizationID } = useParams()
  const [maxLimit, setMaxLimit] = useState<string>('')
  const [validationError, setValidationError] = useState<string>()
  const { loading: queryLoading } = useGetOrganizationMaxAtmLimitQuery({
    variables: {
      organizationID,
    },
    onCompleted: (data) => {
      setMaxLimit(data.organization.maxCardATMLimit.amount)
      setValidationError(undefined)
    },
  })

  const [updateLimits, { loading: mutationLoading }] = useUpdateOrganizationMaxAtmLimitMutation()

  const validate = (value: string) => {
    const regexp = new RegExp(/^(?:\d+|\d*\.\d+)$/)
    const valid = regexp.test(value)
    if (!valid) {
      setValidationError('ATM Withdrawal limit must be a number')
      return false
    }
    return true
  }

  const onClickHandler = () => {
    if (!validate(maxLimit)) return
    updateLimits({
      variables: {
        input: {
          organizationID,
          sumAmount: parseFloat(maxLimit),
          cardTypes: [CardType.Physical, CardType.Virtual],
        },
      },
      onCompleted: (data) => {
        setMaxLimit(data.updateOrganizationMaxAtmLimit.organization.maxCardATMLimit.amount)
        toast.success('ATM Withdrawal limit saved and new limit applied to organization cards')
      },
    })
  }

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (validationError) {
      setValidationError(undefined)
    }
    setMaxLimit(e.target.value)
  }

  const loading = queryLoading || mutationLoading

  return (
    <Wrapper>
      <DetailsHeader>ATM Withdrawal limits</DetailsHeader>
      <DetailsUnderline />
      <TextInput
        errorMessage={validationError}
        label={'Maximum limit'}
        value={maxLimit}
        onChange={onChangeHandler}
      />
      <SaveButton disabled={loading} onClick={onClickHandler}>
        Save
      </SaveButton>
    </Wrapper>
  )
}
