import { useState } from 'react'
import tw from 'twin.macro'
import { InfoWrapper } from '../../Prospects/styles'

import { SaveButton } from '../../../lib/styles'
import { Note, OrganizationNote } from './OrganizationNote'
import { CountryKey } from '@zevoy/common/src/types/Generic'
import { DetailsHeader, DetailsUnderline } from '../styles'
import { useUpdateOrganizationNoteMutation } from '../../../GeneratedGraphQLTypes'
import { toast } from 'react-toastify'

const Wrapper = tw(InfoWrapper)`w-110 p-0 pt-8`

type Props = {
  organizationId: string
  note: Note
  country: CountryKey
}

export const OrganizationNoteWithSave = ({
  organizationId,
  note: existingNote,
  country,
}: Props) => {
  const [note, setNote] = useState<Note>(existingNote)
  const [updateNote, { loading }] = useUpdateOrganizationNoteMutation()

  const handleSave = async () => {
    updateNote({
      variables: {
        id: organizationId,
        input: note,
      },
      onCompleted: () => {
        toast.success('Notes are successfully saved!')
      },
      onError: (error) => {
        console.error(error)
        toast.error('Failed to save!')
      },
    })
  }

  return (
    <Wrapper>
      <DetailsHeader>Notes</DetailsHeader>
      <DetailsUnderline />
      <OrganizationNote note={existingNote} country={country} onChange={(note) => setNote(note)} />
      <SaveButton onClick={handleSave} disabled={loading}>
        Save Notes
      </SaveButton>
    </Wrapper>
  )
}
