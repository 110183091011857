import { FlattenSimpleInterpolation } from 'styled-components'
import tw, { css } from 'twin.macro'
import { Sizes } from '../../constants'

const extraLarge = css`
  ${tw`text-base py-3 px-6`}
  width: 240px;
  line-height: 24px;
`

const large = css`
  ${tw`text-base py-2 px-4`}
  width: 240px;
  line-height: 24px;
`

const medium = css`
  ${tw`w-auto text-sm py-2 px-4`}
  line-height: 20px;
`

const small = css`
  ${tw`w-auto text-xs py-1 px-4`}
  line-height: 20px;
`

const extraSmall = css`
  ${tw`w-auto text-xs py-1 px-2.5`}
  line-height: 16px;
`

// TODO
const extraLargeStretched = css`
  ${extraLarge}
`

// TODO
const largeStretched = css`
  ${large}
`

const mediumStretched = css`
  ${medium}
  ${tw`px-8`}
`

// TODO
const smallStretched = css`
  ${small}
`

// TODO
const extraSmallStretched = css`
  ${extraSmall}
`

export const StretchedButtonSizeStyles = {
  [Sizes.ExtraLarge]: extraLargeStretched,
  [Sizes.Large]: largeStretched,
  [Sizes.Medium]: mediumStretched,
  [Sizes.Small]: smallStretched,
  [Sizes.ExtraSmall]: extraSmallStretched,
}

export const ButtonSizeStyles: Record<Sizes, FlattenSimpleInterpolation> = {
  [Sizes.ExtraLarge]: extraLarge,
  [Sizes.Large]: large,
  [Sizes.Medium]: medium,
  [Sizes.Small]: small,
  [Sizes.ExtraSmall]: extraSmall,
}
