import {
  Datagrid,
  DeleteWithConfirmButton,
  List,
  Button,
  TextField,
  useRecordContext,
  useNotify,
  useDataProvider,
  RefreshButton,
  useRefresh,
  FunctionField,
} from 'react-admin'
import get from 'lodash/get'
import intersection from 'lodash/intersection'

import { ZevoyList } from '../styles'
import { TabFilter } from './TabFilter'
import { postFilters } from './Filters'
import { useLocation } from 'react-router-dom'
import { CardRequest, CardRequestStatus } from '../../../GeneratedGraphQLTypes'
import { FormattedDate } from '../IncidentCommunication/IncidentCommunicationList'
import React from 'react'

export const SubmitButtonField = ({
  source,
}: {
  source: string
  label: string
  sortable: boolean
}) => {
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const record = useRecordContext()
  const notify = useNotify()

  const value = get(record, source!)

  const submit = async () => {
    dataProvider.submit('cardRequest', value).then(() => {
      notify('Card request submitted')
      refresh()
    })
  }
  return <Button label="Submit" id={value} onClick={submit} />
}

export const CardRequestsList = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const pending =
    intersection(JSON.parse(params.get('filter')!)?.statuses, [
      CardRequestStatus.Ordered,
      CardRequestStatus.Cancelled,
    ]).length === 0

  const ordered =
    intersection(JSON.parse(params.get('filter')!)?.statuses, [
      CardRequestStatus.Pending,
      CardRequestStatus.Cancelled,
    ]).length === 0

  return (
    <List filters={postFilters}>
      <ZevoyList>
        <TabFilter />
        <RefreshButton />
        <Datagrid bulkActionButtons={false}>
          <TextField source="organization.name" label="Company name" sortable={false} />
          <TextField source="user.fullName" label="Name" sortable={false} />
          <TextField source="status" label="Status" sortable={false} />
          {ordered && (
            <FunctionField
              source="createTime"
              label="Create time"
              render={(record: CardRequest) => <FormattedDate value={record.createTime} />}
            />
          )}
          {pending && <SubmitButtonField source="id" label="Submit" sortable={false} />}
          {pending && (
            <DeleteWithConfirmButton
              label="Cancel"
              confirmTitle={'Cancel card request'}
              confirmContent={'Are you sure you want to cancel this item?'}
            />
          )}
        </Datagrid>
      </ZevoyList>
    </List>
  )
}
