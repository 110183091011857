import React from 'react'
import { ModalInputLabel } from '../../../../../lib/styles'
import { InputWrapper } from '../../../../CreditApplication/styles'
import { useNewProspectState } from './store'
import { TextInput } from '@zevoy/common/src/components/TextInput'

export const CompanyName = () => {
  const { name, setName } = useNewProspectState()
  return (
    <InputWrapper>
      <ModalInputLabel>Official Company Name:</ModalInputLabel>
      <TextInput
        value={name}
        onChange={(e) => {
          setName(e.target.value)
        }}
      />
    </InputWrapper>
  )
}
