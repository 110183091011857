import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import VirtualWallet from '../VirtualWallet/VirtualWallet'
import { ScreenWrapper } from '../../lib/styles'
import { ErrorBoundary } from '@zevoy/common/src/components/Error/ErrorBoundary'

const VirtualWalletScreen = () => {
  return (
    <ErrorBoundary>
      <ScreenWrapper>
        <Sidebar />
        <VirtualWallet />
      </ScreenWrapper>
    </ErrorBoundary>
  )
}

export default VirtualWalletScreen
