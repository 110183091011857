import React from 'react'
import {
  DetailsChevronWrapper,
  DetailsInviteButton,
  DetailsInviteButtonWrapper,
  DetailsInviteCol,
  DetailsInviteHeading,
  DetailsInviteText,
  DetailsInviteWrapper,
} from '../styles'
import { hubUrl } from '../../../lib/environments'
import { ReactComponent as ChevronRight } from '../../../lib/assets/icons/chevronRight.svg'
import { useOrganizationDetailsStore } from './store'

export const HubGhostModeButton = () => {
  const { organization } = useOrganizationDetailsStore()
  if (!organization) {
    return null
  }
  return (
    <DetailsInviteWrapper href={`${hubUrl}/organization/${organization.id}`} target="_blank">
      <DetailsInviteCol>
        <DetailsInviteHeading>{organization.name} Zevoy Hub</DetailsInviteHeading>
        <DetailsInviteText>Visit the Zevoy Hub In Ghost Mode</DetailsInviteText>
      </DetailsInviteCol>
      <DetailsInviteButtonWrapper>
        <DetailsInviteButton />
      </DetailsInviteButtonWrapper>
      <DetailsChevronWrapper>
        <ChevronRight />
      </DetailsChevronWrapper>
    </DetailsInviteWrapper>
  )
}
