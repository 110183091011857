import React from 'react'
import tw from 'twin.macro'
import { PlanLabel } from './styles'
import { PlanCondition, PlanType } from '../../GeneratedGraphQLTypes'
import { usePlanConditionsState } from './store'
import Slider from '../../lib/components/Slider'

const Wrapper = tw.div``

type ToggleKey = 'freeClaimForBusinessCardUser'

interface PricePerUserProps {
  toggleKey: ToggleKey
  label: string
  currentCondition: PlanCondition
  isReadOnly?: boolean
}
export const PlanToggle = ({
  toggleKey,
  label,
  currentCondition,
  isReadOnly,
}: PricePerUserProps) => {
  const { updatePlanCondition } = usePlanConditionsState()
  const disabled = currentCondition.type !== PlanType.Custom || isReadOnly
  return (
    <Wrapper>
      <PlanLabel>{label}</PlanLabel>
      <Slider
        disabled={disabled}
        isSmall
        selected={currentCondition[toggleKey]}
        onClick={() => {
          if (!disabled) {
            updatePlanCondition(currentCondition.startDate, {
              [toggleKey]: !currentCondition[toggleKey],
            })
          }
        }}
      />
    </Wrapper>
  )
}
