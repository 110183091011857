import React from 'react'
import { ModalInputLabel } from '../../../lib/styles'
import { InputWrapper } from '../../CreditApplication/styles'
import { isEmpty } from 'lodash'
import { TextInput } from '@zevoy/common/src/components/TextInput'
import { NilableNumber } from './types'

type Props = {
  cardPotential: NilableNumber
  setCardPotential: (potential: number | undefined) => void
}

export const CardPotential = ({ cardPotential, setCardPotential }: Props) => {
  return (
    <InputWrapper>
      <ModalInputLabel>Card Potential:</ModalInputLabel>
      <TextInput
        isNumeric
        value={cardPotential ?? ''}
        onChange={({ target: { value } }) => {
          setCardPotential(!isEmpty(value) ? Number.parseInt(value) : undefined)
        }}
      />
    </InputWrapper>
  )
}
