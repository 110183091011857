import React from 'react'
import tw, { styled } from 'twin.macro'

interface ErrorProps {
  error: string
  isLight?: boolean
  isFieldError?: boolean
}

type WrapperProps = {
  isLight?: boolean
  isFieldError?: boolean
}
const Wrapper = styled.div`
  ${tw`text-red-600 py-2 text-sm font-slussenLight`}
  ${({ isLight, isFieldError }: WrapperProps) =>
    (isLight || isFieldError) && tw`py-1 text-xs font-slussenLight`}
  ${({ isFieldError }: WrapperProps) => isFieldError && tw`px-1`}
`
export const ErrorMessage = ({ error, isLight, isFieldError }: ErrorProps) => {
  if (!error) return null

  return (
    <Wrapper isFieldError={isFieldError} isLight={isLight}>
      {error}
    </Wrapper>
  )
}
