import React from 'react'
import {
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  FunctionField,
  List,
  TextField,
  TopToolbar,
} from 'react-admin'
import tw from 'twin.macro'
import { Route, Routes, useNavigate } from 'react-router-dom'
import ContentAdd from '@mui/icons-material/Add'
import { IncidentCommunicationCreate } from './IncidentCommunicationCreate'
import { DateTime } from 'luxon'

const Label = tw.div`font-slussenSemibold capitalize`
const Header = tw.div`text-[32px] font-slussenSemibold mt-10`

interface LabelComponentProps {
  labelName: string
}

interface FormattedDateProps {
  value: string
}
const LabelComponent = ({ labelName }: LabelComponentProps) => {
  return <Label>{labelName}</Label>
}

const ListActions = () => {
  const navigate = useNavigate()
  return (
    <TopToolbar>
      <Button
        startIcon={<ContentAdd />}
        label="New Message"
        onClick={() => navigate('/admin/incidentMessage/create')}
      />
    </TopToolbar>
  )
}
export const FormattedDate = ({ value }: FormattedDateProps) => {
  // Format the date as something more readable. For example: "April 9th, 2024, 10:02 AM"
  return <span>{DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</span>
}

export const IncidentCommunicationList = () => {
  return (
    <>
      <Header>Activated incident communication for Hub, App and Login</Header>
      <List pagination={false} actions={<ListActions />}>
        <Datagrid rowClick={'edit'} bulkActionButtons={false}>
          <TextField
            source="messageType"
            style={{
              fontFamily: 'Slussen-semibold',
              textTransform: 'capitalize',
            }}
            label={<LabelComponent labelName={'Type'} />}
          />
          <TextField
            source="id"
            label={<LabelComponent labelName={'Message id'} />}
            sortable={false}
          />
          <TextField source="message" label={<LabelComponent labelName={'Content'} />} />
          <FunctionField
            source="lastEditedTime"
            label={<LabelComponent labelName={'Last edited time'} />}
            render={(record: any) => <FormattedDate value={record.lastEditedTime} />}
          />
          <TextField
            source="lastEditedBy"
            label={<LabelComponent labelName={'Last edited by'} />}
          />
          <DeleteWithConfirmButton
            label={undefined}
            confirmTitle={'Hide incident message'}
            confirmContent={'Are you sure you want to hide this message?'}
          />
        </Datagrid>
      </List>
      <Routes>
        <Route path="/create" element={<IncidentCommunicationCreate />} />
      </Routes>
    </>
  )
}
