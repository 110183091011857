import React from 'react'
import {
  DetailsChevronWrapper,
  DetailsInviteButton,
  DetailsInviteButtonWrapper,
  DetailsInviteCol,
  DetailsInviteHeading,
  DetailsInviteSwitcherWrapper,
  DetailsInviteText,
} from '../styles'
import Slider from '../../../lib/components/Slider'
import { useNavigate } from 'react-router-dom'
import { OrganizationStatus } from '../../../GeneratedGraphQLTypes'

interface CardOrderStatusSwitcherProps {
  status: string
}
export const CardOrderStatusSwitcher = ({ status }: CardOrderStatusSwitcherProps) => {
  const navigate = useNavigate()

  const isCardOrderAutomatic = status === OrganizationStatus.CardOrderAutomatic
  return (
    <DetailsInviteSwitcherWrapper>
      <DetailsInviteCol>
        <DetailsInviteHeading>Card Order Status</DetailsInviteHeading>
        <DetailsInviteText>Restricted or automatic</DetailsInviteText>
      </DetailsInviteCol>
      <DetailsInviteButtonWrapper>
        <DetailsInviteButton>
          {isCardOrderAutomatic ? 'Automatic' : 'Restricted'}
        </DetailsInviteButton>
      </DetailsInviteButtonWrapper>
      <DetailsChevronWrapper>
        <Slider selected={isCardOrderAutomatic} onClick={() => navigate('prompt')} />
      </DetailsChevronWrapper>
    </DetailsInviteSwitcherWrapper>
  )
}
