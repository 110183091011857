import React, { useEffect, useState } from 'react'
import {
  DetailsCreditApplicationStatus,
  DetailsHeader,
  DetailsLabel,
  DetailsUnderline,
} from '../styles'
import { SaveButton } from '../../../lib/styles'
import tw from 'twin.macro'
import { useOrganizationDetailsStore } from './store'
import { CountryKey, CurrencyISO, CurrencySigns } from '@zevoy/common/src/types/Generic'
import {
  useGetCreditApproversQuery,
  useGetCurrentUserDataQuery,
  useUpdateCreditLimitMutation,
} from '../../../GeneratedGraphQLTypes'
import { InfoWrapper } from '../../Prospects/styles'
import { toast } from 'react-toastify'
import { formatMoneyValue } from '../../../lib/utils'
import { TextInput } from '@zevoy/common/src/components/TextInput'

const Wrapper = tw(InfoWrapper)`p-0 pt-8`
const CreditApplicationStatus = tw(DetailsLabel)``
const CurrencyLabel = tw.div`mx-2 font-slussenLight`
const CreditLimitWrapper = tw.div``
const CreditLimitLabel = tw(DetailsLabel)`mb-2 inline-block`
const CreditLimitInput = tw(TextInput)`my-0 w-[140px]`
const CreditLimitValue = tw.div`inline-block ml-2 font-slussenSemibold opacity-70 text-sm`

export const CreditApplicationInfo = () => {
  const { organization } = useOrganizationDetailsStore()
  const { data: currentUser } = useGetCurrentUserDataQuery({
    fetchPolicy: 'cache-and-network',
  })

  const { data: creditApprovers } = useGetCreditApproversQuery({
    errorPolicy: 'ignore',
  })

  const [creditLimit, setCreditLimit] = useState(
    String(organization?.accounts?.[0]?.creditLimit ?? 0),
  )

  const [updateCreditLimit] = useUpdateCreditLimitMutation({
    variables: {
      accountId: organization?.accounts?.[0]?.id,
      amount: creditLimit,
      currency: CurrencyISO[organization?.country as CountryKey] ?? '',
    },
  })
  useEffect(() => {
    const newCreditLimit = organization?.accounts?.[0]?.creditLimit ?? 0
    if (newCreditLimit) {
      setCreditLimit(String(newCreditLimit))
    }
  }, [organization])

  const isCurrentUserApprover = creditApprovers?.creditApprovers?.some(
    (approver) => approver?.id === currentUser?.me?.id,
  )

  const handleSave = async () => {
    try {
      await updateCreditLimit()
      toast.success('Credit limit saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }

  const country = organization?.country as CountryKey

  return (
    <Wrapper>
      <DetailsHeader>Credit Application</DetailsHeader>
      <DetailsUnderline />
      <CreditApplicationStatus>
        Credit application status:
        <DetailsCreditApplicationStatus>
          {organization?.creditApplications[organization?.creditApplications.length - 1]?.status ??
            null}
        </DetailsCreditApplicationStatus>
      </CreditApplicationStatus>
      <CreditLimitWrapper>
        <CreditLimitLabel>Credit limit:</CreditLimitLabel>
        {isCurrentUserApprover ? (
          <CreditLimitInput
            value={creditLimit}
            onChange={(e) => {
              setCreditLimit(e.target.value)
            }}
            rightInnerElement={() => <CurrencyLabel>{CurrencySigns[country]}</CurrencyLabel>}
          />
        ) : (
          <CreditLimitValue>
            {`${formatMoneyValue(parseInt(creditLimit))} ${CurrencySigns[country]}`}
          </CreditLimitValue>
        )}
      </CreditLimitWrapper>
      {isCurrentUserApprover && <SaveButton onClick={handleSave}>Save</SaveButton>}
    </Wrapper>
  )
}
