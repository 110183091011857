import React from 'react'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { firstLogin, isAuthenticated } from './auth'
import useStore from './coreStore'
import {
  LoadingWrapper,
  LoadingDeprecated,
} from '@zevoy/common/src/components/Loading/LoadingDeprecated'
import { ComponentVariants } from '@zevoy/common/src/constants'
import tw from 'twin.macro'

const ModLoadingWrapper = tw(LoadingWrapper)`h-[100vh]`

const AuthRedirect = () => {
  const { loginProgress, setLoginProgress } = useStore()
  useEffect(() => {
    firstLogin().then(() => setLoginProgress(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const authSuccess = loginProgress && isAuthenticated()
  return authSuccess ? (
    <Navigate to="/home" replace />
  ) : (
    <ModLoadingWrapper>
      <LoadingDeprecated variant={ComponentVariants.Secondary} title="Redirecting" />
    </ModLoadingWrapper>
  )
}

export default AuthRedirect
