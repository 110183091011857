import React from 'react'
import { DetailsInputWrapper, InputLabel } from '../styles'
import { User } from '../../../GeneratedGraphQLTypes'

interface CreditApplicationUserProps {
  user: User
}
export const CreditApplicationUser = ({ user }: CreditApplicationUserProps) => {
  return (
    <DetailsInputWrapper>
      <InputLabel>Credit application created by {user?.fullName}</InputLabel>
    </DetailsInputWrapper>
  )
}
