import React from 'react'
import { ReactComponent as Logo } from '../../lib/assets/Sidebar/Logo.svg'
import { SvgWrapper } from './styles'

const SidebarLogo = () => {
  return (
    <SvgWrapper>
      <Logo />
    </SvgWrapper>
  )
}

export default SidebarLogo
