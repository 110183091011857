import React from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { TagValueStatus } from '../../../../GeneratedGraphQLTypes'

const statuses = [
  { id: TagValueStatus.Enabled, name: 'Enabled' },
  { id: TagValueStatus.Archived, name: 'Archived' },
]
export const tagValueFilters = [
  <SelectInput
    variant="standard"
    multiline
    choices={statuses}
    label="Status"
    source="statuses"
    alwaysOn
  />,
  <TextInput variant="standard" label="Search" source="q" alwaysOn />,
]
