import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Close } from '@zevoy/common/assets/icons/close.svg'
import Modal from '../../../../../lib/components/ModalWrapper'
import { ModalPadding } from '../../../styles'
import Header from '../Header'
import {
  CloseButton,
  DismissButton,
  Divider,
  ModalTwoButtonWrapper,
  SaveProspectButton,
  SvgButtonWrapper,
} from '../../../../../lib/styles'
import { ProspectCountry } from './ProspectCountry'
import { PlanType, useCreateProspectMutation } from '../../../../../GeneratedGraphQLTypes'
import { CompanyName } from './CompanyName'
import { BusinessID } from './BusinessID'
import { SalesRepresentative } from './SalesRepresentative'
import { Plans } from '../../../../Plans/Plans'
import { useNewProspectState } from './store'
import { useProspectsState } from '../../../store'
import { usePlanConditionsState } from '../../../../Plans/store'
import { convertUTCWithoutChangingTime } from '../../../../../lib/utils'
import { toast } from 'react-toastify'
import { ErrorMessage } from '@zevoy/common/src/components/ErrorMessage'
import { getDefaultPlan } from '../../../../Plans/utils'
import { OnboardingFee } from './OnboardingFee'
import { BusinessIdValidationRules } from '@zevoy/common/src/types/Generic'
import tw from 'twin.macro'
import { OrganizationNote } from '../../../../Organizations/Note'
import { Note } from '../../../../Organizations/Note/OrganizationNote'

export const ContentWrapper = tw.div`flex flex-col py-1 w-full space-y-3 overflow-y-scroll max-h-[calc(80vh - 80px)]`

export const CreateProspect = () => {
  const { refreshProspects } = useProspectsState()
  const {
    country,
    salesRepId,
    businessId,
    name,
    setError,
    resetNewProspectInfo,
    error,
    onboardingFee,
    onboardingFeeBillingDate,
  } = useNewProspectState()

  const { planConditions, resetPlanConditions, setPlanConditions } = usePlanConditionsState()
  const [note, setNote] = useState<Note | undefined>(undefined)

  const [createProspect] = useCreateProspectMutation()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setError('')
    setPlanConditions([getDefaultPlan('FI')])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate()
  const parentPath = '/admin/prospect'

  const saveProspect = async () => {
    try {
      const businessIdValidationRule = BusinessIdValidationRules.find(
        (item) => item.countryCode === country,
      )
      if (!planConditions.length) {
        setError('Please add at least one plan.')
        return
      }
      if (businessIdValidationRule?.regex && !businessId.match(businessIdValidationRule.regex)) {
        setError(
          `Business ID format is incorrect. Correct format is for example ${businessIdValidationRule.example}`,
        )
        return
      }
      if (!note || !note.channelId) {
        setError(`Please select a channel for the prospect`)
        return
      }
      let onboardingFeeInput = undefined
      if (onboardingFee != null && onboardingFeeBillingDate != null) {
        onboardingFeeInput = {
          fee: onboardingFee,
          billingDate: onboardingFeeBillingDate,
        }
      }
      setIsLoading(true)
      await createProspect({
        variables: {
          input: {
            businessID: businessId,
            country,
            name,
            onboardingFee: onboardingFeeInput,
            plan: {
              additionalTerms: '',
              comments: '',
              type: PlanType.ZevoyGo,
              conditions: planConditions.map((plan) => ({
                ...plan,
                startDate: convertUTCWithoutChangingTime(plan.startDate),
              })),
              fxFee: true,
            },
            salesRepID: salesRepId,
            note,
          },
        },
      })

      await refreshProspects()
      resetNewProspectInfo()
      resetPlanConditions()
      toast.success(`Prospect ${name} has been successfully created!`)
      navigate(parentPath)
    } catch (e) {
      const err: Error = e as Error
      setError(err?.message ?? e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal>
      <ModalPadding>
        <CloseButton>
          <SvgButtonWrapper
            onClick={() => {
              navigate(parentPath)
            }}
          >
            <Close />
          </SvgButtonWrapper>
        </CloseButton>
        <Header title="New prospect" />
        <Divider />
        <ContentWrapper>
          <ProspectCountry />
          {!['FI', 'SE'].includes(country) && <CompanyName />}
          <BusinessID />
          <SalesRepresentative />
          <OnboardingFee />
          <Plans country={country} />
          <OrganizationNote note={note} country={country} onChange={(note) => setNote(note)} />
          {error && <ErrorMessage error={error} />}
        </ContentWrapper>
        <Divider />
        <ModalTwoButtonWrapper>
          <DismissButton
            onClick={() => {
              resetPlanConditions()
              navigate(parentPath)
            }}
          >
            Dismiss
          </DismissButton>
          {isLoading ? (
            <SaveProspectButton disabled>
              <span>Saving...</span>
            </SaveProspectButton>
          ) : (
            <SaveProspectButton onClick={saveProspect}>Save</SaveProspectButton>
          )}
        </ModalTwoButtonWrapper>
      </ModalPadding>
    </Modal>
  )
}
