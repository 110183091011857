import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { PlanLabel } from './styles'
import { PlanCondition, PlanType } from '../../GeneratedGraphQLTypes'
import { usePlanConditionsState } from './store'
import { TextInput } from '@zevoy/common/src/components/TextInput'

const Wrapper = tw.div``
const PriceInput = styled(TextInput)`
  ${tw`font-slussenLight`}
  ${css`
    flex-basis: 80px;
    width: 110px;
  `}
`

type NumberKey = 'freeClaimUsers' | 'freeBusinessCardUsers' | 'freeSubscriptionCards'

interface PricePerUserProps {
  numberKey: NumberKey
  label?: string
  currentCondition: PlanCondition
  isReadOnly?: boolean
}
export const PlanNumber = ({
  numberKey,
  label,
  currentCondition,
  isReadOnly,
}: PricePerUserProps) => {
  const { updatePlanCondition } = usePlanConditionsState()
  return (
    <Wrapper>
      {label && <PlanLabel>{label}</PlanLabel>}
      <PriceInput
        disabled={currentCondition.type !== PlanType.Custom || isReadOnly}
        isSmall
        isNumeric
        value={currentCondition[numberKey]}
        onChange={(e) => {
          updatePlanCondition(currentCondition.startDate, {
            [numberKey]: e.target.value,
          })
        }}
      />
    </Wrapper>
  )
}
