import React from 'react'
import tw from 'twin.macro'

const Title = tw.div`text-4xl font-slussenSemibold`
const HeaderWrapper = tw.div`flex flex-col justify-between items-center w-full pt-5 space-y-5`
const TopSection = tw.div`flex flex-row w-full justify-between items-center pt-4`
const SubSectionLeft = tw.div`flex flex-row w-2/5 justify-between items-center`
const SubSectionRight = tw.div`flex flex-row justify-between items-center pr-12 gap-2`
const BottomSection = tw.div`flex flex-col w-full pl-10 pt-5`
const TitleLabel = tw.div`font-slussenLight text-menuGrey opacity-50 text-xs`

interface HeaderProps {
  title: string
  backButton?: React.ReactNode
  searchBar?: React.ReactNode
  filter?: React.ReactNode
  customButton?: React.ReactNode
  customLabel?: string
}
const Header = ({
  title,
  backButton,
  searchBar,
  filter,
  customButton,
  customLabel,
}: HeaderProps) => {
  return (
    <HeaderWrapper>
      <TopSection>
        <SubSectionLeft css={[backButton && tw` pl-5`]}>
          {backButton}
          {searchBar}
          {filter}
        </SubSectionLeft>
        <SubSectionRight>{customButton}</SubSectionRight>
      </TopSection>

      <BottomSection>
        {customLabel && <TitleLabel>{customLabel}</TitleLabel>}
        <Title>{title}</Title>
      </BottomSection>
    </HeaderWrapper>
  )
}

export default Header
