import { useEffect } from 'react'
import {
  Create,
  maxLength,
  minLength,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { BusinessIdValidationRules, Countries, CountryKey } from '@zevoy/common/src/types/Generic'
import { useSalesReps } from '../../Prospects/hooks'
import { PlanType } from '../../../GeneratedGraphQLTypes'
import { convertUTCWithoutChangingTime } from '../../../lib/utils'
import { Plans } from '../../Plans/Plans'
import { usePlanConditionsState } from '../../Plans/store'
import { getDefaultPlan } from '../../Plans/utils'

export const countryOptions = (Object.keys(Countries) as Array<CountryKey>).map((key) => ({
  name: Countries[key],
  id: key,
}))

export const SalesRepresentative = ({ source = 'salesRepID' }) => {
  const { salesReps } = useSalesReps()

  const salesRepOptions = salesReps.map((salesRep) => ({
    name: salesRep.fullName,
    id: salesRep.id,
  }))

  return <SelectInput source={source} choices={salesRepOptions} label="Sale sep" />
}

export const PlanInput = () => {
  const country = useWatch({ name: 'country' }) ?? 'FI'
  const { setValue } = useFormContext()
  const { planConditions, setPlanConditions } = usePlanConditionsState()

  useEffect(() => {
    setPlanConditions([getDefaultPlan('FI')])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue('plan', {
      additionalTerms: '',
      comments: '',
      type: PlanType.ZevoyGo,
      conditions: planConditions.map((plan) => ({
        ...plan,
        startDate: convertUTCWithoutChangingTime(plan.startDate),
      })),
    })
  }, [planConditions, setValue])
  return <Plans country={country} />
}

export const validateName = [required(), minLength(2), maxLength(26)]

export const BusinessIDInput = ({ disabled }: { disabled?: boolean }) => {
  const country = useWatch({ name: 'country' }) ?? 'FI'

  const businessIdValidationRule = BusinessIdValidationRules.find(
    (item) => item.countryCode === country,
  )

  const businessIDValidator = (value: string): string | null => {
    if (businessIdValidationRule?.regex && !value.match(businessIdValidationRule.regex)) {
      return `Business ID format is incorrect. Correct format is for example ${businessIdValidationRule.example}`
    }
    return null
  }

  return (
    <TextInput
      disabled={disabled}
      source="businessID"
      label="Business ID"
      validate={businessIDValidator}
    />
  )
}

export const ProspectCreate = () => (
  <Create title="New prospect">
    <SimpleForm>
      <SelectInput source="country" choices={countryOptions} defaultValue="FI" />
      <TextInput type="standard" source="name" label="Trading Name" validate={validateName} />
      <BusinessIDInput />
      <TextInput type="number" source="onboardingFee" label="Onboarding fee" defaultValue={'0'} />
      <SalesRepresentative />
      <PlanInput />
    </SimpleForm>
  </Create>
)
