import React from 'react'
import { Stack } from '@zevoy/common/src/components/Spacing'
import { Autocomplete, LoadScript } from '@react-google-maps/api'
import { googleMapsApiKey } from '../../../environments'
import { TextField } from '@mui/material'
import tw from 'twin.macro'
import { BenefitMerchantLocation } from '../../../GeneratedGraphQLTypes'
import { KycMerchantLocationWithID } from '../BenefitApplication/BenefitApplicationEdit'

const AutocompleteProvider = tw(Autocomplete)`w-full`
const Title = tw.div`font-slussenSemibold text-zevoyBlueBlack`
const LocationWrapper = tw.div`py-2.5`
const InlineInputWrapper = tw.div`flex justify-between gap-2.5 items-center`
const MultipleLocationFooter = tw.div`border-b-1 pb-5 font-slussenLight text-zevoyBlueBlack`
const AddAnotherLocation = tw.div`text-sm underline cursor-pointer flex justify-end`
const RemoveLink = tw.div`text-xs flex justify-end underline cursor-pointer`
const OpeningHourInput = tw(TextField)`w-[160px]`

const mapLibraries: 'places'[] = ['places']

interface MerchantLocationsProps {
  benefitLocations: BenefitMerchantLocation[] | KycMerchantLocationWithID[]
  handleRemoveLocation: (id: number) => void
  handleAddAnotherLocation: () => void
  onLoad: (id: number, autocomplete: google.maps.places.Autocomplete | null) => void
  onPlaceChanged: (locationId: number) => void
  handleLocationChange: (id: number, fields: Partial<any>) => void
}

const MerchantLocations = ({
  benefitLocations,
  handleRemoveLocation,
  handleAddAnotherLocation,
  onPlaceChanged,
  onLoad,
  handleLocationChange,
}: MerchantLocationsProps) => {
  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={mapLibraries}>
      <Title>Locations</Title>
      {benefitLocations.map((location, index) => (
        <LocationWrapper key={location.id}>
          <Stack gap={2.5}>
            <AutocompleteProvider
              onPlaceChanged={() => onPlaceChanged(location.id)}
              onLoad={(autocomplete) => onLoad(location.id, autocomplete)}
            >
              <TextField
                placeholder={'Add...'}
                fullWidth
                label={'Location Name'}
                value={location.name}
                onChange={(e) => handleLocationChange(location.id, { name: e.target.value })}
              />
            </AutocompleteProvider>
            <TextField
              placeholder={'Add...'}
              label={'Address'}
              value={location?.address ?? ''}
              onChange={(e) => handleLocationChange(location.id, { address: e.target.value })}
            />
            <TextField
              placeholder={'Add...'}
              label={'Location Google Business URL'}
              value={location.location}
              onChange={(e) => handleLocationChange(location.id, { location: e.target.value })}
            />
            <TextField
              placeholder={'Add...'}
              label={'Location Google ID'}
              value={location.googleID ?? ''}
              onChange={(e) => handleLocationChange(location.id, { googleID: e.target.value })}
            />
            <InlineInputWrapper>
              <TextField
                placeholder={'Add...'}
                label={'Longitude'}
                fullWidth
                value={location.geometry?.longitude ?? ''}
                onChange={(e) =>
                  handleLocationChange(location.id, {
                    geometry: {
                      longitude: Number(e.target.value),
                      latitude: Number(location.geometry?.latitude),
                    },
                  })
                }
              />

              <TextField
                placeholder={'Add...'}
                label={'Latitude'}
                fullWidth
                value={location.geometry?.latitude ?? ''}
                onChange={(e) =>
                  handleLocationChange(location.id, {
                    geometry: {
                      longitude: Number(location.geometry?.longitude),
                      latitude: Number(e.target.value),
                    },
                  })
                }
              />
            </InlineInputWrapper>
            <TextField
              placeholder={'Add...'}
              label={'Lounaat.info ID'}
              fullWidth
              value={location.lounaatInfoID ?? ''}
              onChange={(e) =>
                handleLocationChange(location.id, {
                  lounaatInfoID: e.target.value ? Number(e.target.value) : null,
                })
              }
            />
            <Title>Opening Hours</Title>
            {location.openingHours ? (
              <>
                {location.openingHours?.map((openingHour, index) => (
                  <InlineInputWrapper key={index}>
                    <OpeningHourInput
                      label={'Day of week'}
                      value={openingHour.dayOfWeek}
                      disabled
                    />
                    {openingHour.isOpen24h ? (
                      <Title>Opening 24h</Title>
                    ) : (
                      <>
                        <OpeningHourInput
                          label={'Opening Time'}
                          value={openingHour.openingTime}
                          disabled
                        />
                        <OpeningHourInput
                          label={'Closing Time'}
                          value={openingHour.closingTime}
                          disabled
                        />
                      </>
                    )}
                  </InlineInputWrapper>
                ))}
              </>
            ) : (
              <>No opening hours available</>
            )}
          </Stack>

          {benefitLocations.length > 1 && (
            <MultipleLocationFooter>
              <RemoveLink
                onClick={() => {
                  handleRemoveLocation(location.id)
                }}
              >
                Remove location
              </RemoveLink>
            </MultipleLocationFooter>
          )}
          {index === benefitLocations.length - 1 && (
            <AddAnotherLocation onClick={handleAddAnotherLocation}>
              Add another location
            </AddAnotherLocation>
          )}
        </LocationWrapper>
      ))}
    </LoadScript>
  )
}

export default MerchantLocations
