let url = 'https://localhost:3000'
let redirectUrl = 'https://localhost:3000'
let hubUrl = 'https://localhost:8080'

if (process.env.REACT_APP_ENV === 'local') {
  url = 'https://api.test.zevoy.com/query'
  redirectUrl = 'http://localhost:3000'
  hubUrl = 'https://localhost:8080'
}
if (process.env.REACT_APP_ENV === 'dev') {
  url = 'https://api.dev.zevoy.com/query'
  redirectUrl = 'https://zevops.dev.zevoy.com'
  hubUrl = 'https://hub.dev.zevoy.com'
  if (process.env.REACT_APP_AWS_PULL_REQUEST_ID && process.env.REACT_APP_AWS_APP_ID) {
    redirectUrl = `https://pr-${process.env.REACT_APP_AWS_PULL_REQUEST_ID}.${process.env.REACT_APP_AWS_APP_ID}.amplifyapp.com`
  }
}
if (process.env.REACT_APP_ENV === 'localDev') {
  url = 'https://api.dev.zevoy.com/query'
  redirectUrl = 'http://localhost:3000'
  hubUrl = 'https://hub.dev.zevoy.com'
}
if (process.env.REACT_APP_ENV === 'development') {
  url = 'https://api.test.zevoy.com/query'
  redirectUrl = 'https://zevops.test.zevoy.com'
  hubUrl = 'https://hub.test.zevoy.com'
  if (process.env.REACT_APP_AWS_PULL_REQUEST_ID && process.env.REACT_APP_AWS_APP_ID) {
    redirectUrl = `https://pr-${process.env.REACT_APP_AWS_PULL_REQUEST_ID}.${process.env.REACT_APP_AWS_APP_ID}.amplifyapp.com`
  }
}
if (process.env.REACT_APP_ENV === 'staging') {
  url = 'https://api.production.zevoy.com/query'
  redirectUrl = 'https://staging.d5rqc8c37zq2e.amplifyapp.com'
  hubUrl = 'https://hub.production.zevoy.com'
  if (process.env.REACT_APP_AWS_PULL_REQUEST_ID && process.env.REACT_APP_AWS_APP_ID) {
    redirectUrl = `https://pr-${process.env.REACT_APP_AWS_PULL_REQUEST_ID}.${process.env.REACT_APP_AWS_APP_ID}.amplifyapp.com`
  }
}
if (process.env.REACT_APP_ENV === 'production') {
  url = 'https://api.production.zevoy.com/query'
  redirectUrl = 'https://zevops.production.zevoy.com'
  hubUrl = 'https://hub.production.zevoy.com'
}
if (process.env.REACT_APP_ENV === 'myenv') {
  url = 'http://localhost:8081/query'
  redirectUrl = 'http://localhost:3000'
  hubUrl = 'https://localhost:8080'
}

export { url, redirectUrl, hubUrl }
