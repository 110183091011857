import { Datagrid, List, TextField } from 'react-admin'

export const SalesrepList = () => (
  <List pagination={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="fullName" />
      <TextField source="country" />
    </Datagrid>
  </List>
)
