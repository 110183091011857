import { useNotify, useRecordContext } from 'react-admin'
import { Button } from '@mui/material'
import get from 'lodash/get'
import {
  useResetCardPinAttemptMutation,
  CardType,
  CardStatus,
} from '../../../../GeneratedGraphQLTypes'

export const ResetButton = ({
  source,
  label,
}: {
  source: string
  label: string
  sortable: boolean
}) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [resetPinAttempt] = useResetCardPinAttemptMutation()

  const value = get(record, source!)
  const cardType = get(record, 'cardType')
  const status = get(record, 'status')
  const reset = async () => {
    resetPinAttempt({
      variables: {
        cardID: value,
      },
      onCompleted: () => {
        notify('Card pin attempt reset successfully', { type: 'success' })
      },
      onError: () => {
        notify('Card pin attempt reset failed', { type: 'error' })
      },
    })
  }
  return (
    <Button
      disabled={cardType !== CardType.Physical || status !== CardStatus.CardOk}
      variant="contained"
      id={value}
      onClick={reset}
    >
      {label}
    </Button>
  )
}
