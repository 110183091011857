import { gql } from '@apollo/client'
import { client } from '../../lib/apolloClient'
import { redirectUrl } from '../../lib/environments'

export const getEidAuthUrl = async (country, redirectSuffix) =>
  await client.query({
    query: gql`
      query getEidAuthUrl {
        eidAuth(country: "${country}", redirectURL: "${redirectUrl}/${
      redirectSuffix || 'redirect'
    }") {
          authURL
        }
      }
    `,
  })
