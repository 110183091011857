import { Tab, Tabs } from '../styles'
import { KycApplicationStatus } from '../../../GeneratedGraphQLTypes'
import { useListContext } from 'react-admin'
import { useEffect } from 'react'

const statuses = [
  { id: KycApplicationStatus.Open, name: 'Open' },
  { id: KycApplicationStatus.Signed, name: 'Signed' },
]

export const BenefitApplicationTabFilter = () => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext

  const handleClick = (value: string) => {
    setFilters &&
      setFilters(
        { ...filterValues, statuses: [value] }, // API expects statuses to be an array of statuses so we pass value to an array here
        displayedFilters,
        false, // no debounce, we want the filter to fire immediately
      )
  }

  useEffect(() => {
    setFilters &&
      setFilters(
        { ...filterValues, statuses: [KycApplicationStatus.Open] }, // API expects statuses to be an array of statuses so we pass value to an array here
        displayedFilters,
        false, // no debounce, we want the filter to fire immediately
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Tabs>
      {statuses.map((status) => {
        return (
          <Tab
            key={status.id}
            onClick={() => handleClick(status.id)}
            selected={filterValues?.statuses?.find(
              (item: KycApplicationStatus) => item === status.id,
            )}
          >
            {status.name}
          </Tab>
        )
      })}
    </Tabs>
  )
}
