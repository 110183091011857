import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CreditModalWrapper from './CreditModalWrapper'
import {
  ModalPromptPadding,
  RejectButton,
  ApproveButton,
  DismissButton,
  StyledHeader,
} from '../../lib/styles'
import { PromptInfo } from './styles'
import { approveCreditApplication, rejectCreditApplication } from './queries-legacy'

const CreditPrompt = () => {
  const params = useParams()
  const navigate = useNavigate()
  return (
    <CreditModalWrapper>
      <ModalPromptPadding>
        <StyledHeader>Resolve</StyledHeader>
        <PromptInfo>Send a decision to the Prospect regarding their Credit Application</PromptInfo>
        <ApproveButton
          onClick={() => {
            approveCreditApplication(params.id)
            navigate('/admin/creditApplication')
          }}
        >
          Approve
        </ApproveButton>
        <RejectButton
          onClick={() => {
            rejectCreditApplication(params.id)
            navigate('/admin/creditApplication')
          }}
        >
          Reject
        </RejectButton>
        <DismissButton
          onClick={() => {
            navigate(-1)
          }}
        >
          Cancel
        </DismissButton>
      </ModalPromptPadding>
    </CreditModalWrapper>
  )
}

export default CreditPrompt
