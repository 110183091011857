import React from 'react'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { CountryKey, DefaultPlanTypeNames, Option } from '@zevoy/common/src/types/Generic'
import { PlanCondition, PlanType } from '../../GeneratedGraphQLTypes'
import { DEFAULT_PLANS_DETAILS } from '../Prospects/components/ProspectsList/CreatePropspect/constants'
import tw from 'twin.macro'
import { PlanLabel } from './styles'
import { DefaultPlan } from '../Prospects/components/ProspectsList/CreatePropspect/types'
import { usePlanConditionsState } from './store'

const Wrapper = tw.div``

interface PlanSelectionProps {
  currentCondition: PlanCondition
  country: CountryKey
  isReadOnly?: boolean
}
export const PlanSelection = ({ currentCondition, country, isReadOnly }: PlanSelectionProps) => {
  const { updatePlanCondition } = usePlanConditionsState()

  const planOptions: Option[] = DEFAULT_PLANS_DETAILS.map((plan: DefaultPlan) => ({
    value: plan.type,
    label: DefaultPlanTypeNames[plan.type],
  })).concat({
    value: PlanType.Custom,
    label: DefaultPlanTypeNames[PlanType.Custom],
  })
  return (
    <Wrapper>
      <PlanLabel>Type:</PlanLabel>
      <SelectComponent
        isDisabled={isReadOnly}
        lightFont
        options={planOptions}
        isSmall
        value={planOptions.find((option) => option.value === currentCondition.type)}
        onChange={(planType) => {
          const selectedType = (planType as Option)?.value as PlanType
          if (selectedType === PlanType.Custom) {
            updatePlanCondition(currentCondition.startDate, {
              type: selectedType,
            })
            return
          }
          const selectdPlan = DEFAULT_PLANS_DETAILS.find((plan) => plan.type === selectedType)
          updatePlanCondition(currentCondition.startDate, {
            type: selectedType as PlanType,
            amountPerUser: selectdPlan?.amountPerUser[country] ?? 0,
            amountPerBusinessCardUser: selectdPlan?.amountPerBusinessCardUser[country] ?? 0,
            amountPerClaimUser: selectdPlan?.amountPerClaimUser[country] ?? 0,
            amountPerSubscription: selectdPlan?.amountPerSubscription[country] ?? 0,
            minimumBillingFee: selectdPlan?.minimumBillingFee[country] ?? 0,
            freeClaimForBusinessCardUser:
              selectdPlan?.freeClaimForBusinessCardUser[country] ?? false,
            fixedFee: selectdPlan?.fixedFee[country] ?? 0,
            freeClaimUsers: selectdPlan?.freeClaimUsers[country] ?? 0,
            freeBusinessCardUsers: selectdPlan?.freeBusinessCardUsers[country] ?? 0,
            freeSubscriptionCards: selectdPlan?.freeSubscriptionCards[country] ?? 0,
          })
        }}
      />
    </Wrapper>
  )
}
