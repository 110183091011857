import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { DetailsTextArea, InfoWrapper } from '../../Prospects/styles'
import { DetailsHeader, DetailsUnderline, ModalInputLabel } from '../styles'
import { useOrganizationDetailsStore } from './store'
import { SaveButton } from '../../../lib/styles'
import { Plan } from '../../../GeneratedGraphQLTypes'
import { useRequestAdditionalTermsUpdate } from '../../../lib/hooks'

const AdditionalTermsWrapper = tw(InfoWrapper)`w-110 p-0 pt-8`

export const AdditionalTerms = () => {
  const { organization } = useOrganizationDetailsStore()
  const [terms, setTerms] = useState('')

  const { updateAdditionalTerms, loading } = useRequestAdditionalTermsUpdate()

  useEffect(() => {
    setTerms(organization?.plan?.additionalTerms ?? '')
  }, [organization])

  if (!organization) {
    return (
      <>
        <DetailsHeader>Additional terms</DetailsHeader>
        <DetailsUnderline />
        <ModalInputLabel>Unavailable</ModalInputLabel>
      </>
    )
  }

  const handleSave = async () => {
    await updateAdditionalTerms(terms, organization.id, organization?.plan as Plan)
  }

  return (
    <AdditionalTermsWrapper>
      <DetailsHeader>Additional Terms</DetailsHeader>
      <DetailsUnderline />
      <DetailsTextArea
        value={terms ?? ''}
        onChange={(e) => {
          setTerms(e.target.value)
        }}
      />
      <SaveButton onClick={handleSave} disabled={loading}>
        Save Additional terms
      </SaveButton>
    </AdditionalTermsWrapper>
  )
}
