import { LOCAL_STORAGE_VARIABLES } from '../constants'

export const accessTokenGet = () => {
  const accessTokenJSON = localStorage.getItem(LOCAL_STORAGE_VARIABLES.ACCESS_TOKEN)
  if (accessTokenJSON) {
    return JSON.parse(accessTokenJSON)
  }
  return false
}

export const refreshTokenGet = () => {
  const refreshTokenJSON = localStorage.getItem(LOCAL_STORAGE_VARIABLES.REFRESH_TOKEN)
  if (refreshTokenJSON) {
    return JSON.parse(refreshTokenJSON)
  }
  return false
}
