import {
  ArrayInput,
  Edit,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useGetList,
  useGetOne,
  useNotify,
  useUpdate,
  Confirm,
  useRefresh,
  SelectInput,
} from 'react-admin'
import { useNavigate, useParams } from 'react-router-dom'
import BackButtonArrow from '../../../lib/components/BackButton'
import { ContentWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  BenefitCategory,
  KycMerchantApplication,
  KycMerchantBenefitInput,
  KycMerchantLocation,
  KycOpeningHour,
  Transaction,
} from '../../../GeneratedGraphQLTypes'
import { agreementChannelOptions, BenefitTypeIDs, BenefitTypes, DayMapping } from './constants'
import { BenefitCategoryType } from 'admin-client/src/Benefits/BenefitsServicesOffered/types'
import { BenefitOptionSections } from './BenefitOptionSections'
import { isEqual, sortBy } from 'lodash'
import { BenefitApplicationInvites } from './BenefitApplicationInvites'
import { Button } from '@zevoy/common/src/components'
import { Sizes } from '@zevoy/common/src/constants'
import tw, { css } from 'twin.macro'
import { Stack } from '@zevoy/common/src/components/Spacing'
import { TextInputNew } from '@zevoy/common/src/components/TextInput/TextInputNew'
import { BenefitApplicationSigners } from './BenefitApplicationSigners'
import MerchantLocations from '../Merchant/MerchantLocations'
import { formatTimeOpeningHour, sortOpeningHours, validateAgreementChannel } from './utils'

const AssociateMerchantButtonWrapper = tw.div`flex ml-4 mt-4`

export type KycMerchantLocationWithID = KycMerchantLocation & {
  id: number
}

export const generateUniqueNumberId = () => {
  return Date.now() + Math.floor(Math.random() * 10000)
}

export const BenefitApplicationEdit = () => {
  const navigate = useNavigate()
  const notify = useNotify()
  const [update] = useUpdate()
  const refresh = useRefresh()

  const { id } = useParams()

  const emptyBenefitLocation = () => ({
    name: '',
    location: '',
    id: generateUniqueNumberId(),
    googleID: '',
    geometry: undefined,
    address: '',
    openingHours: undefined,
  })

  const [transactionID, setTransactionID] = useState<string>('')
  const [confirmEnrollMerchantID, setConfirmEnrollMerchantID] = useState<boolean>(false)

  const { data: transactionByIDData } = useGetOne<Transaction>(
    'transaction',
    {
      id: transactionID,
    },
    {
      enabled: confirmEnrollMerchantID,
    },
  )

  const { data: benefitApplicationData } = useGetOne<KycMerchantApplication>(
    'benefitApplication',
    {
      id: id as any,
    },
    {
      onSuccess: (data) => {
        const getInitialBenefitTags = (categoryType: BenefitTypes, categoryID: BenefitTypeIDs) => {
          return (
            data?.benefits
              ?.find((benefit) => benefit.category === categoryType)
              ?.tags?.map((tag) => ({
                id: tag.id,
                categoryID: categoryID,
                title: tag.title,
              })) || []
          )
        }

        const initialLunchBenefitTags = getInitialBenefitTags(
          BenefitTypes.LUNCH,
          BenefitTypeIDs.LUNCH,
        )
        const initialCultureBenefitTags = getInitialBenefitTags(
          BenefitTypes.CULTURE,
          BenefitTypeIDs.CULTURE,
        )
        const initialSportBenefitTags = getInitialBenefitTags(
          BenefitTypes.SPORT,
          BenefitTypeIDs.SPORT,
        )

        setLunchBenefitSelectedOptions(initialLunchBenefitTags)
        setCultureBenefitSelectOptions(initialCultureBenefitTags)
        setSportBenefitSelectOptions(initialSportBenefitTags)

        if (data.locations) {
          const locationsWithIds = data.locations.map((location) => ({
            ...location,
            id: generateUniqueNumberId(),
          }))
          setMerchantLocations(locationsWithIds)
        }
      },
    },
  )
  const { data: benefitCategoryData } = useGetList<BenefitCategory>('benefitCategory', {})
  const [merchantLocations, setMerchantLocations] = useState<KycMerchantLocationWithID[]>([])
  const [lunchBenefitSelectedOptions, setLunchBenefitSelectedOptions] = useState<
    BenefitCategoryType[]
  >([])
  const [cultureBenefitSelectOptions, setCultureBenefitSelectOptions] = useState<
    BenefitCategoryType[]
  >([])
  const [sportBenefitSelectOptions, setSportBenefitSelectOptions] = useState<BenefitCategoryType[]>(
    [],
  )
  const [confirmNavigate, setConfirmNavigate] = useState<boolean>(false)

  const getSortedBenefitCategories = (langID: BenefitTypes) => {
    const benefitTypeTags =
      benefitCategoryData?.find((benefitType) => benefitType.langID === langID)?.tags || []

    return benefitTypeTags.slice().sort((a, b) => {
      if (a.title === 'Other') return 1 // Move "Other" towards the end
      if (b.title === 'Other') return -1 // Keep "Other" at the end if it's `b`
      return 0 // Keep original order if neither is "Other"
    })
  }

  const lunchBenefitCategories = getSortedBenefitCategories(BenefitTypes.LUNCH)
  const cultureBenefitCategories = getSortedBenefitCategories(BenefitTypes.CULTURE)
  const sportBenefitCategories = getSortedBenefitCategories(BenefitTypes.SPORT)

  const handleOptionClick = (option: BenefitCategoryType, benefitType: BenefitTypes) => {
    const updateSelectedOptions = (selectedOptions: BenefitCategoryType[]) => {
      const isSelected = selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      if (isSelected) {
        return selectedOptions.filter((selectedOption) => selectedOption.id !== option.id)
      } else {
        return [...selectedOptions, option]
      }
    }

    if (benefitType === BenefitTypes.LUNCH) {
      setLunchBenefitSelectedOptions((prevOptions) => updateSelectedOptions(prevOptions))
    } else if (benefitType === BenefitTypes.CULTURE) {
      setCultureBenefitSelectOptions((prevOptions) => updateSelectedOptions(prevOptions))
    } else if (benefitType === BenefitTypes.SPORT) {
      setSportBenefitSelectOptions((prevOptions) => updateSelectedOptions(prevOptions))
    }
  }

  const updateMerchantIDByTransaction = (data: any) => {
    update(
      'availableTransactionMerchantID',
      {
        data: {
          transactionID: transactionID,
          applicationID: id,
        },
        previousData: {},
      },
      {
        onSuccess: () => {
          notify('Update successfully', { type: 'success' })
          setConfirmEnrollMerchantID(false)
          setTransactionID('')
          refresh()
        },
        onError: (error: any) => {
          notify(`Update failed: ${error?.message ?? 'Unknown error'}`, { type: 'error' })
        },
      },
    )
  }

  const saveBasicInfo = (data: any) => {
    const transformSelectedOptions = (selectedOptions: BenefitCategoryType[]) => {
      return selectedOptions.map(({ id, title }) => ({ id, title }))
    }

    let benefits: KycMerchantBenefitInput[] = []

    const addBenefitIfSelected = (
      selectedOptions: BenefitCategoryType[],
      category: BenefitTypes,
    ) => {
      if (selectedOptions.length > 0) {
        benefits.push({
          category: category,
          tags: transformSelectedOptions(selectedOptions),
        })
      }
    }

    const filterLocations = (locations: KycMerchantLocation[]) => {
      return locations.map((location) => {
        const { geometry, ...rest } = location
        if (geometry && (geometry.latitude === null || geometry.longitude === null)) {
          return rest
        }
        return location
      })
    }

    const locationWithoutID = merchantLocations.map(({ id, ...rest }) => rest)

    // Only add the benefit tags if it is being triggered
    addBenefitIfSelected(lunchBenefitSelectedOptions, BenefitTypes.LUNCH)
    addBenefitIfSelected(cultureBenefitSelectOptions, BenefitTypes.CULTURE)
    addBenefitIfSelected(sportBenefitSelectOptions, BenefitTypes.SPORT)

    update(
      'benefitApplication',
      {
        id: data.id,
        data: {
          application: {
            applicationID: data.id,
            name: data.name,
            businessID: data.businessID,
            contactName: data.contactName,
            contactEmail: data.contactEmail,
            contactPhoneNumber: data.contactPhoneNumber,
            locations: filterLocations(locationWithoutID),
            benefits,
            externalMerchantIDs: data.externalMerchantIDs,
            agreementChannel: data.agreementChannel,
          },
        },
        previousData: {},
      },
      {
        onSuccess: () => {
          notify('Update successfully', { type: 'success' })
        },
        onError: (error: any) => {
          notify(`Update failed: ${error?.message ?? 'Unknown error'}`, { type: 'error' })
        },
      },
    )
  }

  const isDataModified = useMemo(() => {
    const { benefits } = benefitApplicationData || {}

    const checkCategoryCondition = (
      categoryType: BenefitTypes,
      selectedOptions: BenefitCategoryType[],
    ) => {
      const filteredBenefits = sortBy(
        benefits
          ?.find((benefit) => benefit.category === categoryType)
          ?.tags?.map(({ id, title }) => ({ id, title })),
        ['id', 'title'],
      )
      const sortedSelectedOptions = sortBy(
        selectedOptions.map(({ id, title }) => ({ id, title })),
        ['id', 'title'],
      )

      return !isEqual(filteredBenefits, sortedSelectedOptions)
    }

    const isLunchConditionOrModified = checkCategoryCondition(
      BenefitTypes.LUNCH,
      lunchBenefitSelectedOptions,
    )
    const isCultureConditionOrModified = checkCategoryCondition(
      BenefitTypes.CULTURE,
      cultureBenefitSelectOptions,
    )
    const isSportConditionOrModified = checkCategoryCondition(
      BenefitTypes.SPORT,
      sportBenefitSelectOptions,
    )

    return isLunchConditionOrModified || isCultureConditionOrModified || isSportConditionOrModified
  }, [
    benefitApplicationData,
    lunchBenefitSelectedOptions,
    cultureBenefitSelectOptions,
    sportBenefitSelectOptions,
  ])

  const serverMerchantData = benefitApplicationData?.locations
  const locationWithoutID = merchantLocations.map(({ id, ...rest }) => rest)
  const isLocationModified = !isEqual(sortBy(serverMerchantData), sortBy(locationWithoutID))

  const autocompleteRefs = useRef<{ [key: string]: google.maps.places.Autocomplete | null }>({})

  const onPlaceChanged = (locationId: number) => {
    if (autocompleteRefs.current[locationId]) {
      const place = autocompleteRefs.current[locationId]?.getPlace()

      if (place) {
        const geometry = place.geometry?.location
          ? {
              longitude: place.geometry.location.lng(),
              latitude: place.geometry.location.lat(),
            }
          : undefined

        let openingHours: KycOpeningHour[] | undefined = place.opening_hours?.periods
          ?.map((period) => {
            return {
              closingTime:
                period.close && formatTimeOpeningHour(period.close.hours, period.close.minutes),
              dayOfWeek: DayMapping[period.open.day],
              openingTime:
                period.close && formatTimeOpeningHour(period.open.hours, period.open.minutes),
              isOpen24h: !period.close,
            }
          })
          .filter((hour) => hour !== undefined) as KycOpeningHour[]

        // Special case check: If only one entry, closingTime is undefined, and dayOfWeek is Sunday
        if (
          openingHours &&
          openingHours.length === 1 &&
          openingHours[0].closingTime === undefined &&
          openingHours[0].dayOfWeek === DayMapping[0] // DayMapping[0] is sunday
        ) {
          // Set openingTime and closingTime to undefined for all days
          openingHours = Object.values(DayMapping).map((day) => ({
            dayOfWeek: day,
            openingTime: undefined,
            closingTime: undefined,
            isOpen24h: true,
          }))
        }

        if (openingHours) {
          sortOpeningHours(openingHours)
        }

        handleLocationChange(locationId, {
          name: place.name ?? '',
          location: place.url ?? '',
          googleID: place.place_id ?? '',
          address: place.formatted_address ?? '',
          geometry,
          openingHours,
        })
      }
    }
  }

  const handleAddAnotherLocation = () => {
    setMerchantLocations([...merchantLocations, emptyBenefitLocation()])
  }

  const handleRemoveLocation = (id: number) => {
    setMerchantLocations((currentLocations) => {
      return currentLocations.filter((location) => location.id !== id)
    })
  }

  const handleLocationChange = (id: number, fields: Partial<KycMerchantLocationWithID>) => {
    setMerchantLocations((currentLocations) =>
      currentLocations.map((location) =>
        location.id === id ? { ...location, ...fields } : location,
      ),
    )
  }

  const onLoad = useCallback((id: number, autocomplete) => {
    autocompleteRefs.current[id] = autocomplete
  }, [])

  return (
    <>
      <ContentWrapper>
        <Header
          title={'Edit merchant application'}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate('/admin/benefitApplication')
              }}
            />
          }
        />

        <AssociateMerchantButtonWrapper>
          <Button
            onClick={() => {
              setConfirmNavigate(true)
            }}
            size={Sizes.Medium}
          >
            Associate merchant by name
          </Button>
        </AssociateMerchantButtonWrapper>

        <Confirm
          isOpen={confirmNavigate}
          title={`Confirm`}
          content={'All unsaved changes will be discarded'}
          onConfirm={() => navigate('available-merchant-id')}
          onClose={() => {
            setConfirmNavigate(false)
          }}
        />

        <Stack
          width={'500px'}
          styled={css`
             {
              margin-top: 10px;
              margin-left: 15px;
            }
          `}
        >
          <TextInputNew
            label={'Transaction ID'}
            value={transactionID}
            isNumeric
            onChange={(e) => setTransactionID(e.target.value)}
          />
          <Button
            onClick={() => setConfirmEnrollMerchantID(true)}
            size={Sizes.Medium}
            width={'250px'}
            disabled={transactionID.length === 0}
          >
            Add merchant id by transaction
          </Button>

          <Confirm
            isOpen={confirmEnrollMerchantID}
            title={`Confirm`}
            content={`Do you want to enroll merchant IDs for merchant ${transactionByIDData?.text}`}
            onConfirm={updateMerchantIDByTransaction}
            onClose={() => {
              setConfirmEnrollMerchantID(false)
            }}
          />
        </Stack>

        <Edit>
          <SimpleForm toolbar={false} onSubmit={saveBasicInfo}>
            <Stack width={'500px'}>
              <SelectInput
                source="agreementChannel"
                choices={agreementChannelOptions}
                validate={validateAgreementChannel}
              />
              <TextInput source="name" label="Official company name" fullWidth />
              <TextInput source="businessID" label="Business ID" fullWidth />
              <TextInput source="contactName" label="Contact person name" fullWidth />
              <TextInput
                source="contactEmail"
                label="Contact person email"
                fullWidth
                type={'email'}
              />
              <TextInput
                source="contactPhoneNumber"
                label="Contact person phone number"
                fullWidth
              />
              <ArrayInput source="externalMerchantIDs" label={'Merchant IDs'}>
                <SimpleFormIterator fullWidth>
                  <TextInput source="merchantID" label="Merchant ID" fullWidth required />
                  <TextInput
                    source="acquireReferenceNumber"
                    label="Acquire reference number"
                    fullWidth
                    required
                  />
                </SimpleFormIterator>
              </ArrayInput>

              <MerchantLocations
                benefitLocations={merchantLocations}
                handleRemoveLocation={handleRemoveLocation}
                handleAddAnotherLocation={handleAddAnotherLocation}
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
                handleLocationChange={handleLocationChange}
              />
            </Stack>

            <SaveButton label="Save info" alwaysEnable={isLocationModified} />

            <BenefitOptionSections
              benefitsList={lunchBenefitCategories}
              selectedOptions={lunchBenefitSelectedOptions}
              handleOptionClick={(option) => handleOptionClick(option, BenefitTypes.LUNCH)}
              benefitTitle="Lunch tags"
            />

            <BenefitOptionSections
              benefitsList={cultureBenefitCategories}
              selectedOptions={cultureBenefitSelectOptions}
              handleOptionClick={(option) => handleOptionClick(option, BenefitTypes.CULTURE)}
              benefitTitle="Culture tags"
            />

            <BenefitOptionSections
              benefitsList={sportBenefitCategories}
              selectedOptions={sportBenefitSelectOptions}
              handleOptionClick={(option) => handleOptionClick(option, BenefitTypes.SPORT)}
              benefitTitle="Sport tags"
            />

            <SaveButton label="Save tags" alwaysEnable={isDataModified || isLocationModified} />
          </SimpleForm>
        </Edit>

        <BenefitApplicationInvites />

        <BenefitApplicationSigners />
      </ContentWrapper>
    </>
  )
}
