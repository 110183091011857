import React, { useState, useEffect, useRef } from 'react'
import tw, { css, styled } from 'twin.macro'

import { ReactComponent as ChevronDown } from '../assets/icons/chevronDown.svg'
const Button = styled.div`
  ${tw`pl-3 pr-1 py-2 rounded-lg flex flex-row justify-between shadow-heavy font-slussenLight w-full`}
  ${(props) =>
    props.disabled &&
    css`
      ${tw`bg-lightGrey shadow-none`}
    `}
`

const DropdownWrapper = tw.div`relative`
const DropdownContainer = tw.div`
  max-h-24 bg-white  border-lightGrey border-2 rounded-xl flex flex-col rounded mt-1 p-2 text-sm space-y-1 shadow-heavy
  absolute overflow-y-auto z-10 w-full
`
const ChevronWrapper = tw.div`
  flex items-center justify-center h-6 w-6
`

export const DropdownSelectWrapper = styled.div(({ fullWidth }) => [
  fullWidth ? tw`w-full` : tw`w-100`,
])

const SelectText = styled.div`
  ${tw`text-menuGrey text-sm`}
  ${(props) =>
    props.placeholder &&
    css`
      ${tw`opacity-25`}
    `}
`

// recommended as aa wrapper for the options when implementing them
export const SelectOption = tw.div`text-sm hover:bg-lightGrey w-full px-2 flex justify-start`

const Select = (props) => {
  const [toggle, toggleDropdown] = useState(false)

  const wrapperRef = useRef(null)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleDropdown(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)
  return (
    <DropdownSelectWrapper ref={wrapperRef}>
      <Button
        onClick={() => {
          !props.disabled && toggleDropdown(!toggle)
        }}
        disabled={props.disabled}
      >
        <SelectText
          placeholder={props.placeholder && !props.selected}
          defaultValue={props.defaultValue && !props.selected}
        >
          {props.selected || props.placeholder || props.defaultValue}
        </SelectText>
        <ChevronWrapper>
          <ChevronDown />
        </ChevronWrapper>
      </Button>
      {toggle && (
        <DropdownWrapper>
          <DropdownContainer onClick={() => toggleDropdown(!toggle)}>
            {props.options}
          </DropdownContainer>
        </DropdownWrapper>
      )}
    </DropdownSelectWrapper>
  )
}

export default Select
