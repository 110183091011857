import { addOneMonthToDate, roundDateToMonth } from '../../lib/utils'
import { PlanCondition, PlanType } from '../../GeneratedGraphQLTypes'
import { PLAN_ZEVOY_GO } from '../Prospects/components/ProspectsList/CreatePropspect/constants'
import { CountryKey } from '@zevoy/common/src/types/Generic'

export const getDefaultPlan = (country: CountryKey, date?: Date): PlanCondition => {
  const startDate = date ?? roundDateToMonth(addOneMonthToDate(new Date()))
  return {
    startDate,
    amountPerUser: PLAN_ZEVOY_GO.amountPerUser[country] ?? 0,
    amountPerBusinessCardUser: PLAN_ZEVOY_GO.amountPerBusinessCardUser[country] ?? 0,
    amountPerClaimUser: PLAN_ZEVOY_GO.amountPerClaimUser[country] ?? 0,
    amountPerSubscription: PLAN_ZEVOY_GO.amountPerSubscription[country] ?? 0,
    minimumBillingFee: PLAN_ZEVOY_GO.minimumBillingFee[country] ?? 0,
    fixedFee: PLAN_ZEVOY_GO.fixedFee[country] ?? 0,
    freeClaimUsers: PLAN_ZEVOY_GO.freeClaimUsers[country] ?? 0,
    freeBusinessCardUsers: PLAN_ZEVOY_GO.freeBusinessCardUsers[country] ?? 0,
    freeSubscriptionCards: PLAN_ZEVOY_GO.freeSubscriptionCards[country] ?? 0,
    freeClaimForBusinessCardUser: PLAN_ZEVOY_GO.freeClaimForBusinessCardUser[country] ?? false,
    type: PlanType.ZevoyGo,
  }
}
