import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Notifications,
  NotificationWrapper,
  SidebarButton,
  RowWrapper,
  IconWrapper,
} from './styles'

const MenuItem = (props) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(props.route)
  }

  return (
    <RowWrapper selected={props.isSelected} onClick={handleClick}>
      <IconWrapper>{props.icon}</IconWrapper>
      <SidebarButton selected={props.isSelected}>{props.title}</SidebarButton>

      {props.notifications > 0 && (
        <NotificationWrapper>
          <Notifications selected={props.isSelected}>{props.notifications}</Notifications>
        </NotificationWrapper>
      )}
    </RowWrapper>
  )
}

export default MenuItem
