import { Edit, SaveButton, SimpleForm, TextInput, useNotify, useUpdate } from 'react-admin'

import { useNavigate } from 'react-router-dom'
import BackButtonArrow from '../../../lib/components/BackButton'
import { ContentWrapper, ScreenWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import React from 'react'

export const IncidentCommunicationEdit = () => {
  const navigate = useNavigate()
  const notify = useNotify()
  const [update] = useUpdate()

  const saveMessage = (data: any) => {
    update(
      'incidentMessage',
      {
        id: data.id,
        data: {
          id: data.id,
          message: data.message,
        },
        previousData: {},
      },
      {
        onSuccess: () => {
          notify('Update successfully', { type: 'success' })
          navigate('/admin/incidentMessage')
        },
        onError: (error: any) => {
          notify(`Update failed: ${error?.message ?? 'Unknown error'}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <ScreenWrapper>
      <ContentWrapper>
        <Header
          title={'Edit incident message'}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate('/admin/incidentMessage')
              }}
            />
          }
        />

        <Edit>
          <SimpleForm toolbar={false} onSubmit={saveMessage}>
            <TextInput
              source="message"
              label="Incident message"
              fullWidth={true}
              multiline
              minRows={4}
            />
            <SaveButton />
          </SimpleForm>
        </Edit>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
