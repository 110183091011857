import { ReactElement } from 'react'

export type Option = {
  label: string
  value: string | number
  group?: string
}

export type ContextAction = {
  cb: () => void
  isNegative?: boolean
  message: string | ReactElement
  skip?: boolean
}

export type RadioButtonSettings = {
  label: string
  value: string
  disabled?: boolean
}

export enum Locales {
  en_GB = 'en-GB',
  et_EE = 'et-EE',
  lv_LV = 'lv-LV',
  fi_FI = 'fi-FI',
  lt_LT = 'lt-LT',
  sv_SE = 'sv-SE',
  no_NO = 'no-NO',
  da_DK = 'da-DK',
}

export type Locale =
  | Locales.en_GB
  | Locales.et_EE
  | Locales.fi_FI
  | Locales.sv_SE
  | Locales.lv_LV
  | Locales.lt_LT
  | Locales.da_DK
  | Locales.no_NO

export enum Countries {
  EE = 'Estonia',
  FI = 'Finland',
  SE = 'Sweden',
  LV = 'Latvia',
  LT = 'Lithuania',
  NO = 'Norway',
  DK = 'Denmark',
}

export type CountryKey = keyof typeof Countries

export enum CurrencySigns {
  EE = '€',
  FI = '€',
  LV = '€',
  LT = '€',
  SE = 'SEK',
  DK = 'DKK',
  NO = 'NOK',
}

export enum CurrencyISO {
  EE = 'EUR',
  LV = 'EUR',
  LT = 'EUR',
  FI = 'EUR',
  SE = 'SEK',
  DK = 'DKK',
  NO = 'NOK',
}

export enum DefaultPlanTypeNames {
  zevoyGo = 'Zevoy Go',
  zevoyGoLegacy = 'Zevoy Go Legacy',
  freeTrial = 'Free Trial',
  custom = 'Custom',
}

export const BusinessIdValidationRules = [
  { countryCode: 'FI', regex: /^\d{7}-\d{1}$/, example: '1234567-1' },
  { countryCode: 'EE', regex: /^[0-9]{8}$/, example: '12345678' },
  { countryCode: 'LV', regex: /^[0-9]{11}$/, example: '12345678901' },
  { countryCode: 'SE', regex: /^\d{6}-\d{4}$/, example: '123456-1234' },
  { countryCode: 'LT', regex: /^[0-9]{9}$/, example: '123456789' },
  { countryCode: 'NO', regex: /^([0-9]{9})(MVA)?$/, example: '123456789MVA or 123456789' },
  { countryCode: 'DK', regex: /^[0-9]{8}$/, example: '12345678' },
]

export interface AuthClientContext {
  headers?: Record<string, any>
  redirectUrl?: string
}
