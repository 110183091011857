import React from 'react'
import { useLocation } from 'react-router-dom'

import { SidebarWrapper, ButtonsWrapper } from './styles'

import { ReactComponent as Home } from '../../lib/assets/Sidebar/home.svg'
import { ReactComponent as Expenses } from '../../lib/assets/Sidebar/expenses.svg'
import { ReactComponent as Card } from '../../lib/assets/Sidebar/card.svg'
import { ReactComponent as Profile } from '../../lib/assets/Sidebar/profile.svg'
import { ReactComponent as Incident } from '../../lib/assets/Sidebar/incident.svg'
import { ReactComponent as BenefitApplications } from '../../lib/assets/Sidebar/benefitApplications.svg'
import { ReactComponent as BenefitMerchants } from '../../lib/assets/Sidebar/benefitMerchants.svg'
import { ReactComponent as Payment } from '../../lib/assets/Sidebar/payment.svg'

import SidebarLogo from './Logo'
import MenuItem from './MenuItem'
import User from './User'

const Sidebar = () => {
  const { pathname } = useLocation()

  const newMenu = [
    {
      id: 0,
      title: 'Prospects',
      route: '/admin/prospect',
    },
    {
      id: 1,
      title: 'Organizations',
      route: '/admin/organization',
    },
    {
      id: 2,
      title: 'Credit Application',
      route: '/admin/creditApplication',
    },
    {
      id: 3,
      title: 'Card Requests',
      route: '/admin/cardrequest',
    },
    {
      id: 4,
      title: 'Zevoy users',
      route: '/admin/user',
    },
    {
      id: 5,
      title: 'Virtual Wallet',
      route: '/virtual-wallet',
    },
    {
      id: 9,
      title: 'Incident Communication',
      route: '/admin/incidentMessage',
    },
    {
      id: 10,
      title: 'Benefit Application',
      route: '/admin/benefitApplication',
    },
    {
      id: 11,
      title: 'Benefit Merchants',
      route: '/admin/merchant',
    },
    {
      id: 12,
      title: 'Payment',
      route: '/admin/paymentBatch',
    },
  ]
  return (
    <SidebarWrapper>
      <SidebarLogo />
      <ButtonsWrapper>
        {newMenu.map((item) => {
          const showIcon = () => {
            switch (item.id) {
              case 0:
                return <Home />
              case 1:
                return <Card />
              case 2:
                return <Expenses />
              case 3:
                return <Card />
              case 4:
                return <Profile />
              case 5:
                return <Card />
              case 6:
                return <Home />
              case 7:
                return <Profile />
              case 8:
                return <Card />
              case 9:
                return <Incident />
              case 10:
                return <BenefitApplications />
              case 11:
                return <BenefitMerchants />
              case 12:
                return <Payment />
              default:
                return <div />
            }
          }

          const isSelected = pathname === item.route

          return <MenuItem {...item} key={item.id} icon={showIcon()} isSelected={isSelected} />
        })}
      </ButtonsWrapper>
      <User />
    </SidebarWrapper>
  )
}

export default Sidebar
