import React from 'react'
import { DetailsLabel, DetailsTextArea, InputWrapper } from '../../styles'
import { useProspectCardState } from './store'
import { SaveButton } from '../../../../lib/styles'
import { Plan } from '../../../../GeneratedGraphQLTypes'
import { useRequestCommentsUpdate } from '../../../../lib/hooks'
import tw from 'twin.macro'
const Wrapper = tw(InputWrapper)`pt-4`

export const Comments = () => {
  const { prospect, updateProspectPlan } = useProspectCardState()
  const { updateComments, loading } = useRequestCommentsUpdate()

  const prospectPlan = prospect?.plan ?? undefined

  if (!prospectPlan || !prospect?.id) {
    return null
  }

  const handleSave = async () => {
    await updateComments(prospectPlan.comments, prospect.id, prospect?.plan as Plan)
  }
  return (
    <>
      <Wrapper>
        <DetailsLabel>Zevoy Comment:</DetailsLabel>
        <DetailsTextArea
          value={prospect?.plan?.comments ?? ''}
          onChange={(e) => {
            updateProspectPlan({ comments: e.target.value })
          }}
        />
      </Wrapper>
      <SaveButton onClick={handleSave} disabled={loading}>
        Save Comments
      </SaveButton>
    </>
  )
}
