import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  DateInput,
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { ZevoyPaymentTransactionType } from '../../../GeneratedGraphQLTypes'
import { EnfuceTextInput } from './PaymentTransactionCreate'
import { ContentWrapper, ScreenWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import BackButtonArrow from '../../../lib/components/BackButton'
import ImageEye from '@mui/icons-material/RemoveRedEye'
import { EnfuceDateTypes, EnfuceTextTypes, ZevoyTypeOptions } from './constants'

const CustomToolbar = () => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
  </Toolbar>
)

const InternalEdit = () => {
  const record = useRecordContext()
  const [zevoyType, setZevoyType] = useState<ZevoyPaymentTransactionType>(
    record.zevoyType as ZevoyPaymentTransactionType,
  )

  return (
    <SimpleForm minWidth={'300px'} maxWidth={'450px'} toolbar={<CustomToolbar />}>
      <SelectInput
        label="Zevoy Type"
        source="zevoyType"
        fullWidth
        required
        choices={ZevoyTypeOptions}
        defaultValue={zevoyType}
        onChange={(e) => {
          setZevoyType(e.target.value)
        }}
      />
      {zevoyType !== ZevoyPaymentTransactionType.AccountFeeReversal && (
        <>
          <TextInput source="amount" fullWidth required />
          <TextInput source="currency" disabled fullWidth required />
        </>
      )}
      {EnfuceDateTypes.includes(zevoyType) && (
        <DateInput source="enfuceDate" label="Date" fullWidth required />
      )}
      {EnfuceTextTypes.includes(zevoyType) && <EnfuceTextInput source="enfuceText" />}
      {zevoyType === ZevoyPaymentTransactionType.CustomerPayment && (
        <TextInput source="externalId" label="External ID" fullWidth />
      )}
      {zevoyType === ZevoyPaymentTransactionType.AccountFeeReversal && (
        <TextInput source="enfuceTransactionId" label="Enfuce Transaction Id" fullWidth />
      )}
      <TextInput source="comments" multiline={true} rows={4} fullWidth />
    </SimpleForm>
  )
}

const EditActions = ({ batchId }: { batchId: string | null }) => {
  const navigate = useNavigate()
  const record = useRecordContext()
  return (
    <TopToolbar>
      <Button
        onClick={() => {
          navigate(`/admin/paymentTransaction/${record.id}/show?batchId=${batchId}`)
        }}
        label="ra.action.show"
      >
        <ImageEye />
      </Button>
    </TopToolbar>
  )
}

export const PaymentTransactionEdit = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const refresh = useRefresh()
  const notify = useNotify()

  const batchId = new URLSearchParams(location.search).get('batchId')

  const onSuccess = () => {
    notify('Changes to post transaction saved', { type: 'success' })
    navigate(`/admin/paymentTransaction?filter={"batchId": ${batchId}}`)
    refresh()
  }

  return (
    <ScreenWrapper>
      <ContentWrapper>
        <Header
          title={'Edit Payment Transaction'}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate(`/admin/paymentTransaction?filter={"batchId": ${batchId}}`)
              }}
            />
          }
        />
        <Edit
          mutationOptions={{ onSuccess }}
          mutationMode="pessimistic"
          actions={<EditActions batchId={batchId} />}
        >
          <InternalEdit />
        </Edit>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
