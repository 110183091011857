import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CardStatusModalWrapper from './CardStatusModalWrapper'
import { ModalPromptPadding, ApproveButton, DismissButton, StyledHeader } from '../../lib/styles'
import { PromptInfo } from './styles'
import { OrganizationStatus, useSetOrganizationStatusMutation } from '../../GeneratedGraphQLTypes'

const CardStatusPrompt = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [setOrganizationStatus] = useSetOrganizationStatusMutation()

  const handleClick = async () => {
    try {
      await setOrganizationStatus({
        variables: { orgID: params.id, status: OrganizationStatus.CardOrderAutomatic },
      })
      navigate('/admin/organization')
    } catch (e) {
      const err = e as Error
      console.error(err?.message ?? e)
    }
  }

  return (
    <CardStatusModalWrapper>
      <ModalPromptPadding>
        <StyledHeader>Change Status</StyledHeader>
        <PromptInfo>Not possible to reverse after CardOrderAutomaic</PromptInfo>
        <ApproveButton onClick={handleClick}>Yes</ApproveButton>
        <DismissButton
          onClick={() => {
            navigate(-1)
          }}
        >
          No
        </DismissButton>
      </ModalPromptPadding>
    </CardStatusModalWrapper>
  )
}

export default CardStatusPrompt
