import { ZevoyPaymentTransactionType } from '../../../GeneratedGraphQLTypes'

export const EnfuceDateTypes = [
  ZevoyPaymentTransactionType.CustomerPayment,
  ZevoyPaymentTransactionType.AdHocCustomerPayment,
]

export const EnfuceTextMandatoryTypes = [
  ZevoyPaymentTransactionType.CustomerPayment,
  ZevoyPaymentTransactionType.CustomerCashback,
  ZevoyPaymentTransactionType.CustomerCashbackReversal,
  ZevoyPaymentTransactionType.RedemptionAccountFunds,
  ZevoyPaymentTransactionType.RedemptionAccountFundsReversal,
  ZevoyPaymentTransactionType.FraudTransactionCredit,
]

export const EnfuceTextTypes = [
  ...EnfuceTextMandatoryTypes,
  ZevoyPaymentTransactionType.AdHocCustomerPayment,
  ZevoyPaymentTransactionType.AdHocCustomerPaymentReversal,
]

export const ZevoyTypeOptions = [
  { id: ZevoyPaymentTransactionType.CustomerPayment, name: 'Customer Payment' },
  { id: ZevoyPaymentTransactionType.AdHocCustomerPayment, name: 'Ad-hoc Customer Payment' },
  {
    id: ZevoyPaymentTransactionType.AdHocCustomerPaymentReversal,
    name: 'Ad-hoc Customer Payment Reversal',
  },
  {
    id: ZevoyPaymentTransactionType.CustomerCashback,
    name: 'Customer Cashback',
  },
  {
    id: ZevoyPaymentTransactionType.CustomerCashbackReversal,
    name: 'Customer Cashback Reversal',
  },
  { id: ZevoyPaymentTransactionType.RedemptionAccountFunds, name: 'Redemption of Account Funds' },
  {
    id: ZevoyPaymentTransactionType.RedemptionAccountFundsReversal,
    name: 'Redemption of Account Fund Reversal',
  },
  { id: ZevoyPaymentTransactionType.OverdueInterestedReversal, name: 'Overdue Interest Reversal' },
  {
    id: ZevoyPaymentTransactionType.GenericAccountFeeReversal,
    name: 'Generic Account Fee Reversal',
  },
  {
    id: ZevoyPaymentTransactionType.ServiceFeeFiVat,
    name: 'Service Fee (FI VAT)',
  },
  {
    id: ZevoyPaymentTransactionType.ServiceFeeReverseVat,
    name: 'Service Fee (Reverse VAT)',
  },
  {
    id: ZevoyPaymentTransactionType.OnboardingFeeFiVat,
    name: 'Onboarding Fee (FI VAT)',
  },
  {
    id: ZevoyPaymentTransactionType.OnboardingFeeReverseVat,
    name: 'Onboarding Fee (Reverse VAT)',
  },
  {
    id: ZevoyPaymentTransactionType.LatePaymentFee,
    name: 'Late Payment Fee',
  },
  {
    id: ZevoyPaymentTransactionType.FraudTransactionCredit,
    name: 'Credit of Fraud Transaction',
  },
  {
    id: ZevoyPaymentTransactionType.AccountFeeReversal,
    name: 'Account Fee Reversal',
  },
]
