import { ModalStyledHeader, HeaderSectionWrapper } from '../../styles'

const Header = ({ title }) => {
  return (
    <HeaderSectionWrapper>
      <ModalStyledHeader>{title}</ModalStyledHeader>
    </HeaderSectionWrapper>
  )
}

export default Header
