import { useState } from 'react'
import tw from 'twin.macro'
import { InfoWrapper } from '../../Prospects/styles'
import { TextInput } from '@zevoy/common/src/components/TextInput'
import { DetailsUnderline, DetailsHeader } from '../styles'
import { Button } from '@zevoy/common/src/components/Buttons'
import { ComponentVariants, Sizes } from '@zevoy/common/src/constants'
import DeleteConfirmModal from './DeleteConfirmModal'
import { useParams } from 'react-router-dom'
import {
  useGetOrganizationM2Query,
  useM2ClearOrganizationIdMutation,
  useM2SetOrganizationIdMutation,
} from '../../../GeneratedGraphQLTypes'
import { toast } from 'react-toastify'
import { useLoadingToast } from '../../../lib/utils'

const MAX_USER_ID_LENGTH = 16

const OrganizationM2InputWrapper = tw(InfoWrapper)`w-110 p-0 pt-8`
const M2Input = tw(TextInput)`my-0`
const RoleDescription = tw.div`text-greyBlue text-opacity-70`
const ButtonWrapper = tw.div`flex flex-row justify-start items-center pt-4 gap-2`
const SaveButton = tw(Button)``
const DeleteButton = tw(Button)``

const OrganizationM2Input = () => {
  const { id: orgID } = useParams()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [M2OrganizationId, setM2OrganiztionId] = useState<string>('')
  const [cancelDialog, toggleCancelDialog] = useState<boolean>(false)
  const [enrollM2, { loading }] = useM2SetOrganizationIdMutation()
  const [clearM2Id] = useM2ClearOrganizationIdMutation()

  useLoadingToast(loading)
  const { data, refetch } = useGetOrganizationM2Query({
    variables: {
      id: orgID,
    },
    onCompleted: (data) => {
      setM2OrganiztionId(data?.organization?.m2OrgID ?? '')
    },
  })

  const handleSave = () => {
    setErrorMessage(undefined)
    if (!M2OrganizationId) {
      setErrorMessage(
        `${'The input cannot be empty!'} ${
          data?.organization.m2OrgID ? 'Click "Delete" button to delete User ID.' : ''
        }`,
      )
      return
    }
    enrollM2({
      variables: {
        orgID,
        m2OrganizationID: M2OrganizationId,
      },
      onCompleted: () => {
        toast.success('Saving Success')
        refetch()
      },
      onError: (error) => {
        console.error(error)
        toast.error('Saving fail')
      },
    })
  }

  const handleDelete = () => {
    setErrorMessage(undefined)
    clearM2Id({
      variables: {
        orgID,
      },
      onCompleted: () => {
        toggleCancelDialog(false)
        toast.success('Delete Success')
        refetch()
      },
      onError: (error) => {
        console.error(error)
        toast.error('Fail to delete')
      },
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(undefined)

    // Trim leading and trailing spaces
    const trimmedValue = event.target.value.trim()

    // Check for special characters
    if (/[@#$%^&*()+=[\]{}|,;:"'´`<>/?.!]/.test(trimmedValue.replace('-', ''))) {
      return
    }

    // Check length
    if (trimmedValue.length >= MAX_USER_ID_LENGTH) {
      setErrorMessage('The maximum number of characters allowed in this input field is 16.')
      return
    }

    setM2OrganiztionId(event.target.value.trim())
  }
  return (
    <OrganizationM2InputWrapper>
      <DetailsHeader>Enroll organization to M2</DetailsHeader>
      <DetailsUnderline />
      <RoleDescription>
        Enroll this organization to M2 by entering their M2 organization ID
      </RoleDescription>
      <M2Input
        errorMessage={errorMessage}
        placeholder="Organization M2 ID"
        value={M2OrganizationId}
        width="260px"
        onChange={handleInputChange}
      />
      <ButtonWrapper>
        <SaveButton variant={ComponentVariants.Primary} size={Sizes.Small} onClick={handleSave}>
          Enroll
        </SaveButton>
        <DeleteButton
          variant={ComponentVariants.Secondary}
          size={Sizes.Small}
          onClick={() => {
            toggleCancelDialog(true)
          }}
          disabled={!data?.organization.m2OrgID}
        >
          Remove
        </DeleteButton>
      </ButtonWrapper>

      {/* Modal pop up to confirm delete behavior. */}
      {cancelDialog && (
        <DeleteConfirmModal
          handleDelete={handleDelete}
          message={'We will stop seding transaction data for this card to M2.'}
          toggleCancelDialog={toggleCancelDialog}
        />
      )}
    </OrganizationM2InputWrapper>
  )
}

export default OrganizationM2Input
