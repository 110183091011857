import React from 'react'
import { Plans } from '../../Plans/Plans'
import { DetailsHeader, DetailsInputWrapper, DetailsUnderline } from '../../Prospects/styles'
import { InfoWrapper, InputRow, SaveButton } from '../../../lib/styles'
import tw from 'twin.macro'
import { Organization } from '../../../GeneratedGraphQLTypes'
import { CountryKey } from '@zevoy/common/src/types/Generic'
import { useRequestPlanUpdate } from '../../../lib/hooks'

const PlansWrapper = tw(InfoWrapper)`w-110 p-0 pt-8`

interface OrganizationProps {
  organization: Organization
}
export const OrganizationPlans = ({ organization }: OrganizationProps) => {
  const { updatePlans, loading } = useRequestPlanUpdate(false)

  const handleSave = async () => {
    await updatePlans(organization.id)
  }

  return (
    <PlansWrapper>
      <DetailsHeader>Plans</DetailsHeader>
      <DetailsUnderline />
      <Plans country={organization?.country as CountryKey} noTitleLabel plansActive />
      <DetailsInputWrapper>
        <InputRow>
          <SaveButton onClick={handleSave} disabled={loading}>
            Save Plans
          </SaveButton>
        </InputRow>
      </DetailsInputWrapper>
    </PlansWrapper>
  )
}
