import React from 'react'
import {
  AdditionalInfoText,
  DetailsHeader,
  DetailsLabel,
  DetailsTextArea,
  DetailsUnderline,
  InputWrapper,
} from '../../styles'
import { useRequestAdditionalTermsUpdate } from '../../../../lib/hooks'
import { useProspectCardState } from './store'
import { Plan } from '../../../../GeneratedGraphQLTypes'
import { SaveButton } from '../../../../lib/styles'

export const AdditionalTerms = () => {
  const { prospect, updateProspectPlan } = useProspectCardState()
  const { updateAdditionalTerms, loading } = useRequestAdditionalTermsUpdate()

  const prospectPlan = prospect?.plan ?? undefined

  if (!prospectPlan || !prospect?.id) {
    return null
  }

  const handleSave = async () => {
    await updateAdditionalTerms(prospectPlan.additionalTerms, prospect.id, prospect?.plan as Plan)
  }
  return (
    <>
      <InputWrapper>
        <DetailsHeader>Additional Information</DetailsHeader>
        <DetailsUnderline />
        <AdditionalInfoText>
          Be aware that any information added below will be displayed under the page Business Terms
          phasing the Prospect. Avoid it, or at least try to push it into non-binding terms using
          Zevoy comment.
        </AdditionalInfoText>
      </InputWrapper>
      <InputWrapper>
        <DetailsLabel>Zevoy Addtional Term:</DetailsLabel>
        <DetailsTextArea
          value={prospect.plan?.additionalTerms ?? ''}
          onChange={(e) => {
            updateProspectPlan({ additionalTerms: e.target.value })
          }}
        />
      </InputWrapper>
      <SaveButton onClick={handleSave} disabled={loading}>
        Save Additional Terms
      </SaveButton>
    </>
  )
}
