import { IncidentMessageType } from '../../../GeneratedGraphQLTypes'

export const messageTypeOptions = [
  {
    name: 'Hub',
    id: IncidentMessageType.Hub,
  },
  { name: 'Login', id: IncidentMessageType.Login },
  { name: 'App Header', id: IncidentMessageType.AppHeader },
  { name: 'App Subtitle', id: IncidentMessageType.AppSubtitle },
  { name: 'App Body', id: IncidentMessageType.AppBody },
]
