import React, { useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { ContentWrapper, ScreenWrapper } from '../../lib/styles'
import Header from '../../lib/components/Header'
import BackButtonArrow from '../../lib/components/BackButton'
import Details from './Details'
import creditApplicationsStore from './store'
import { getCreditApplication } from './queries-legacy'

const CreditDetails = () => {
  const { creditApplication, resetSelectCreditApplication, setCreditApplication } =
    creditApplicationsStore()
  const navigate = useNavigate()
  const { id } = useParams()

  //const { data, loading, error } = useQuery(GET_CREDIT_APPLICATION, { variables: {}, client: authClient})

  useEffect(
    () => {
      resetSelectCreditApplication()
      getCreditApplication(id).then((res) => {
        if (res) {
          const creditApplication = res?.data?.creditApplication
          setCreditApplication(creditApplication)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  )

  return (
    <ScreenWrapper>
      <Sidebar />
      <ContentWrapper>
        <Header
          title={creditApplication?.organization?.name ?? ''}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate(-1)
              }}
            />
          }
          customLabel={'CREDIT APPLICATIONS /'}
        />
        <Details creditApplication={creditApplication?.id ?? null} />
      </ContentWrapper>
    </ScreenWrapper>
  )
}

export default CreditDetails
