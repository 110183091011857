import { useMemo, useState } from 'react'
import { DetailsHeader, DetailsUnderline, ModalInputWrapper } from '../styles'
import tw from 'twin.macro'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Option } from '@zevoy/common/src/types/Generic'
import { InfoWrapper } from '../../Prospects/styles'
import {
  Organization,
  OrganizationStatus,
  useSetOrganizationStatusMutation,
} from '../../../GeneratedGraphQLTypes'
import { SaveButton } from '../../../lib/styles'
import { ErrorMessage } from '@zevoy/common/src/components/ErrorMessage'
import { toast } from 'react-toastify'

const Wrapper = tw(InfoWrapper)`p-0 pt-8`

type Props = {
  organization: Organization
}

export const OrganizationStatusSelect = ({ organization }: Props) => {
  const [status, setStatus] = useState<OrganizationStatus>(organization.status)

  const [setOrganizationStatus] = useSetOrganizationStatusMutation({
    variables: {
      orgID: organization.id,
      status: status,
    },
  })

  const handleSave = async () => {
    try {
      await setOrganizationStatus()
      toast.success('Organization status saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }

  const statusOptions: Option[] = [
    {
      label: 'Archived',
      value: OrganizationStatus.Archived,
    },
    {
      label: 'Card Order Automatic',
      value: OrganizationStatus.CardOrderAutomatic,
    },
    {
      label: 'Card Order Restricted',
      value: OrganizationStatus.CardOrderRestricted,
    },
    {
      label: 'Prospect',
      value: OrganizationStatus.Prospect,
    },
  ]
  const archiveOrgWithActiveCard = useMemo(
    () => status === OrganizationStatus.Archived && organization.hasActiveCard,
    [status, organization.hasActiveCard],
  )

  return (
    <Wrapper>
      <DetailsHeader>Status</DetailsHeader>
      <DetailsUnderline />
      <ModalInputWrapper>
        <SelectComponent
          options={statusOptions}
          value={statusOptions.find((option: Option) => option.value === status)}
          onChange={(newValue) => {
            setStatus(newValue?.value as OrganizationStatus)
          }}
        />
      </ModalInputWrapper>
      {archiveOrgWithActiveCard && (
        <ErrorMessage error="There are still active cards so organization can not be archived" />
      )}
      <SaveButton
        disabled={status === organization.status || archiveOrgWithActiveCard}
        onClick={handleSave}
      >
        Save Status
      </SaveButton>
    </Wrapper>
  )
}
