import { useCallback } from 'react'
import { useListContext } from 'react-admin'
import { CreditApplicationStatus } from '../../../GeneratedGraphQLTypes'
import { Tab, Tabs } from '../styles'

const statuses = [
  { id: CreditApplicationStatus.Pending, name: 'Pending' },
  { id: CreditApplicationStatus.Approved, name: 'Approved' },
  { id: CreditApplicationStatus.Rejected, name: 'Rejected' },
]

export const TabFilter = () => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext

  const handleClick = useCallback(
    (value: string) => {
      setFilters &&
        setFilters(
          { ...filterValues, statuses: [value] }, // API expects statuses to be an array of statuses so we pass value to an array here
          displayedFilters,
          false, // no debounce, we want the filter to fire immediately
        )
    },
    [displayedFilters, filterValues, setFilters],
  )
  return (
    <Tabs>
      {statuses.map((status) => {
        return (
          <Tab
            key={status.id}
            onClick={() => handleClick(status.id)}
            selected={filterValues?.statuses?.find(
              (item: CreditApplicationStatus) => item === status.id,
            )}
          >
            {status.name}
          </Tab>
        )
      })}
    </Tabs>
  )
}
