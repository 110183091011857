import tw from 'twin.macro'

export const ButtonsRow = tw.div`flex flex-row justify-start items-center space-x-4 w-full`

export const SelectRow = tw(ButtonsRow)`hover:bg-lightGrey font-slussenLight`

export const SectionWrapper = tw.section`
flex flex-col w-full sm:w-2/3 xl:w-1/3 space-y-2
h-full items-center justify-center 
`
export const LoginBodyWrapper = tw.div`
  flex bg-white h-screen w-screen overflow-hidden items-center justify-center
`
export const WelcomeButtonWrapper = tw.div`
  pt-10 w-4/5 sm:w-2/5 text-sm
`
export const LoginButtonWrapper = tw(WelcomeButtonWrapper)`
  sm:w-110
`
export const Button = tw.button`
  bg-white hover:opacity-75 py-0 px-14 rounded-full shadow-heavy
`
export const OrangeButton = tw(Button)`
  bg-brightOrange rounded-full
  py-3 px-6 shadow-heavy sm:shadow-heavy
  text-white w-full font-slussenSemibold disabled:bg-lightGrey border-0
`
export const ButtonTextWrapper = tw.section`flex flex-row items-center justify-center space-x-2`

export const PageTitle = tw.div`text-2xl sm:text-4xl w-110 flex justify-start items-center font-slussenSemibold pl-4`
