import create from 'zustand'
import { Organization } from '../../../GeneratedGraphQLTypes'

interface OrganizationDetailsState {
  organization: Organization | null
  setOrganization: (organization: Organization) => void
  salesRep: string
  setSalesRep: (salesRep: string) => void
}
export const useOrganizationDetailsStore = create<OrganizationDetailsState>((set) => ({
  organization: null,
  salesRep: '',
  setOrganization: (organization: Organization) => set({ organization }),
  setSalesRep: (salesRep: string) => set({ salesRep }),
}))
