import {
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  Identifier,
  Link,
  List,
  TextField,
  TextFieldProps,
  useRecordContext,
} from 'react-admin'
import { batchFilters } from './Filters'
import get from 'lodash/get'

export const EditLinkField = ({ source }: TextFieldProps) => {
  const record = useRecordContext()
  const value = get(record, source!)
  return (
    <Link
      to={`/admin/paymentBatch/${value}`}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      Edit
    </Link>
  )
}

export const DeleteButtonField = ({
  source,
}: {
  source: string
  label: string
  sortable: boolean
}) => {
  const record = useRecordContext()
  const transactionCount = get(record, source!)

  return (
    <DeleteWithConfirmButton
      label="Delete"
      disabled={transactionCount !== 0}
      confirmTitle={'Cancel selected transaction'}
      confirmContent={'Are you sure you want to delete this item?'}
    />
  )
}

export const PaymentBatchList = () => (
  <List filters={batchFilters} perPage={50}>
    <Datagrid
      rowClick={(id: Identifier) => `/admin/paymentTransaction?filter={"batchId": ${id}}`}
      bulkActionButtons={false}
    >
      <TextField source="id" />
      <DateField label="Create date" source="createTime" />
      <TextField label="Created by" source="creator.fullName" />
      <TextField label="Closed by" source="closer.fullName" />
      <TextField label="Posted by" source="poster.fullName" />
      <TextField label="Close date" source="closeTime" />
      <TextField label="Transaction" source="summary.transactionCount" textAlign="center" />
      <TextField source="status" />
      <TextField source="description" />
      <EditLinkField label="Edit" source="id" />
      <DeleteButtonField label="Delete" source={'summary.transactionCount'} sortable={false} />
    </Datagrid>
  </List>
)
