import React from 'react'
import tw, { styled } from 'twin.macro'

const SliderWrapper = styled.div(({ selected, disabled }) => [
  tw`flex flex-row items-center justify-start px-1 w-10 h-6 rounded-full bg-lightGrey shadow-outline cursor-pointer`,
  selected && tw`bg-vividBlue justify-end`,
  disabled && tw`bg-lightGrey`,
])

const SliderBall = tw.div`
  w-5 h-5 rounded-full bg-white shadow-2xl
`

const Slider = (props) => (
  <SliderWrapper disabled={props.disabled} onClick={props.onClick} selected={props.selected}>
    <SliderBall />
  </SliderWrapper>
)

export default Slider
