import React from 'react'
import {
  DetailsChevronWrapper,
  DetailsInviteButton,
  DetailsInviteButtonWrapper,
  DetailsInviteCol,
  DetailsInviteHeading,
  DetailsInviteSwitcherWrapper,
} from '../styles'
import Slider from '../../../lib/components/Slider'
import { useUpdateOrganizationFeaturesMutation } from '../../../GeneratedGraphQLTypes'

type PersonalLiabilityActivationProps = {
  isPersonalLiabilityActive: boolean
  organizationId: string
}
export const PersonalLiabilityActivation = ({
  isPersonalLiabilityActive,
  organizationId,
}: PersonalLiabilityActivationProps) => {
  const [updateOrganizationFeatures] = useUpdateOrganizationFeaturesMutation()

  const togglePersonalLiability = () => {
    updateOrganizationFeatures({
      variables: {
        input: {
          personalLiabilityCardsEnabled: !isPersonalLiabilityActive,
          organizationID: organizationId,
        },
      },
    })
  }

  return (
    <DetailsInviteSwitcherWrapper>
      <DetailsInviteCol>
        <DetailsInviteHeading>Activate Personal Liability</DetailsInviteHeading>
      </DetailsInviteCol>
      <DetailsInviteButtonWrapper>
        <DetailsInviteButton>{isPersonalLiabilityActive ? 'On' : 'Off'}</DetailsInviteButton>
      </DetailsInviteButtonWrapper>
      <DetailsChevronWrapper>
        <Slider
          selected={isPersonalLiabilityActive}
          onClick={() => {
            togglePersonalLiability()
          }}
        />
      </DetailsChevronWrapper>
    </DetailsInviteSwitcherWrapper>
  )
}
