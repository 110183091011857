import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { ToastContainer } from 'react-toastify'

ReactDOM.render(
  <>
    <ToastContainer />
    <App />
  </>,
  document.querySelector('#root'),
)
