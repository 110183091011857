import React, { useRef, useEffect } from 'react'
import { HubInviteButtonWrapper } from '../styles'
import { ReactComponent as Union } from '../assets/icons/Union.svg'
import { hubUrl } from '../environments'

const HubInviteButton = ({ label, id, url }) => {
  const wrapperRef = useRef(null)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {}
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)
  return (
    <HubInviteButtonWrapper
      ref={wrapperRef}
      onClick={(e) => {
        e.stopPropagation()
        window.open(`${hubUrl}/${url}/${id}`)
      }}
    >
      <Union />
      {label}
    </HubInviteButtonWrapper>
  )
}

export default HubInviteButton
