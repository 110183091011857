import moment from 'moment'
import { refreshToken, authToken } from '../constants'
import { getAuthToken, getRefreshToken } from '../queries'
import { refreshTokenGet } from '@zevoy/common/src/utils/auth-utils'

export const isAuthenticated = () => {
  const rToken = localStorage.getItem(refreshToken)
  return rToken && rToken.length > 0
}

export const firstLogin = async () => {
  const url = window.location.href
  const stringAuth = url.split('redirect?')[1]
  const isRedirected = stringAuth !== undefined
  const signicatToken = isRedirected && stringAuth.split('code=')[1]
  const refreshTokenFetch = await getRefreshToken(
    signicatToken ? decodeURIComponent(signicatToken) : '',
  )
  const refresh = refreshTokenFetch.data.refreshToken
  localStorage.setItem(refreshToken, `{ "token": "${refresh}", "time": "${moment()}"}`)
  const refToken = refreshTokenGet()
  const authTokenFetch = await getAuthToken(refToken.token)
  const auth = authTokenFetch.data.accessToken
  localStorage.setItem(authToken, auth)
}
