import tw, { styled } from 'twin.macro'

export const ScreenWrapper = tw.section`
  flex bg-white sm:h-screen overflow-auto
`
export const ContentWrapper = tw.div`flex-grow w-full px-10 max-h-screen pb-4 overflow-y-scroll`

export const Input = tw.input`
  bg-white flex-grow p-2 text-sm shadow-heavy rounded-lg focus:outline-none border-0
`
export const DetailsInput = styled.input(({ disabled }) => [
  tw`bg-white flex-grow p-2 text-sm rounded-lg focus:outline-none border-0 shadow-heavy`,
  disabled && tw`bg-lightGrey`,
])

export const SaveButton = styled.button(({ disabled }) => [
  tw`bg-brightOrange hover:opacity-75 py-2 px-4 text-white rounded-xl shadow-heavy border-0 cursor-pointer text-center`,
  disabled && tw`bg-lightGrey hover:opacity-100 text-darkGrey cursor-not-allowed`,
])

export const TextArea = tw.textarea`bg-white flex-grow font-slussenLight p-2 text-sm shadow-heavy h-24 border-0`

export const Button = tw.button`
  justify-self-end bg-white text-sm
  hover:opacity-75 shadow-heavy py-0 px-6 rounded-full border-0
`
export const OrangeButtonWrapper = tw.div`sm:pt-10 flex-grow w-full sm:w-1/2`
export const OrangeButton = tw(Button)`
  bg-brightOrange font-slussenSemibold disabled:bg-lightGrey 
  rounded-full py-2 px-4 shadow-heavy text-white min-w-full border-0`

export const InviteOrangeButton = tw(
  OrangeButton,
)`text-sm px-4 font-slussenSemibold border-0 py-3 shadow-lg disabled:shadow-none disabled:bg-searchBackground min-w-0`

export const InputSection = tw.div`w-full`
export const InputWrapper = tw.div`flex flex-col py-1 w-full space-y-2`
export const InputRow = tw.div`flex flex-row w-full space-x-4`

export const InputLabel = tw.label`font-slussenSemibold text-sm text-labelsGrey`

export const InfoWrapper = tw.div`flex flex-col w-full items-start px-5 space-y-5 pt-2 w-full`
export const InfoHeader = tw.div`font-slussenSemibold border-b-2 border-opacity-25 w-full flex flex-row justify-start items-center text-sm pb-3`
export const ButtonsSection = tw.div`font-slussenSemibold flex flex-row justify-start items-center`

export const ModalInputLabel = tw.label`flex justify-start font-slussenLight text-sm text-labelsGrey opacity-50`

export const SaveProspectButton = styled.button`
  ${tw`bg-brightOrange hover:opacity-75 py-2 px-12 text-white rounded-xl shadow-heavy border-0 w-full cursor-pointer`}
  ${({ disabled }) => disabled && tw`bg-lightGrey hover:opacity-100 text-darkGrey`}
`

export const RejectButton = tw.button`
  bg-zevoyNegative hover:opacity-75 pt-4 py-2 px-12 text-white rounded-xl shadow-heavy border-0 cursor-pointer
`

export const DismissButton = tw.button`
  bg-lightGrey hover:opacity-75 pt-4 py-2 px-12 text-darkGrey rounded-xl shadow-heavy border-0 w-full cursor-pointer
`

export const ApproveButton = tw.button`
  bg-brightOrange hover:opacity-75 pt-4 py-2 px-12 text-white rounded-xl shadow-heavy border-0 cursor-pointer
`

export const CloseButton = tw.div`
  items-end justify-end w-full flex flex-row cursor-pointer
`
export const SvgButtonWrapper = tw.div` rounded-full hover:bg-gray-300 h-6`

export const ModalButtonWrapper = tw.div`
  pt-4 pb-2
`

export const HubInviteButtonWrapper = tw.button`
  bg-white justify-self-end py-1 text-black rounded-md shadow-heavy border-0 
  cursor-pointer whitespace-nowrap hover:bg-lightGrey mr-2
`

export const Divider = tw.div`px-10 border-b-2 border-menuGrey`

export const CheckboxWrapper = tw.div`sm:shadow-2xl flex justify-start items-start`
export const Checkbox = tw.input` shadow-heavy checked:bg-blue-600 checked:border-transparent w-4 h-4 focus:outline-none`

// Modal

export const ModalPadding = tw.div`px-6 py-4 flex flex-col space-y-1`

export const ModalPromptPadding = tw.div`px-10 py-8 flex flex-col space-y-2 space-x-2`

export const ModalMultipleButtonWrapper = tw.div`
  flex flex-row pt-2 pb-0 space-x-3 items-center w-full justify-center
`

export const ModalTwoButtonWrapper = tw.div`
  flex flex-row pt-2 pb-0 space-x-3 items-center w-full justify-center
`
export const StyledHeader = tw.div`
  text-menuGrey text-3xl font-slussenSemibold
`
export const SectionWrapper = tw.div`
  flex flex-col w-full p-2 
`
export const HeaderSectionWrapper = tw(SectionWrapper)`
  border-b border-gray-800
`

export const AddNewWrapper = tw.div`
font-slussenLight text-sm pt-2 text-menuGrey text-opacity-50
flex flex-row space-x-2 px-2 items-center
hover:opacity-50
`
export const AddNewText = tw.div`items-center cursor-default select-none`

export const ButtonsRow = tw.div`flex flex-row justify-start items-center space-x-4 w-full`
export const SelectRow = tw(ButtonsRow)`hover:bg-lightGrey font-slussenLight`

// back button
export const BackButtonWrapper = tw.div`flex justify-center items-center rounded-full px-4 py-4 shadow-heavy`

export const Loading = tw.div`
  px-6 py-6 text-menuGrey text-base font-slussenSemibold
`
