import tw, { styled } from 'twin.macro'
import { ModalInputLabel } from '../../lib/styles'
import { Button } from '../Prospects/styles'
import { HTMLProps } from 'react'

export const PlanLabel = tw(ModalInputLabel)`text-xs mb-1`

interface DatePickerButtonProps extends HTMLProps<HTMLButtonElement> {
  isDisabled?: boolean
}
export const DatePickerButton = styled(Button)<DatePickerButtonProps>`
  ${tw`cursor-pointer px-4 py-2 rounded-lg hover:bg-vividBlue hover:bg-opacity-25 text-sm shadow-inputShadow font-slussenLight`}
  ${({ isDisabled }) =>
    isDisabled &&
    tw`bg-lightGrey text-labelsGrey opacity-75 hover:bg-lightGrey hover:bg-opacity-0 cursor-default`}
`
