import create from 'zustand'
import { Prospect, SalesRepresentatives } from './types'
import { GetProspectsQuery } from '../../GeneratedGraphQLTypes'
import { getProspects } from './queries'
import { mapProspectsFromData } from './utils'
import { authClient } from '../../lib/apolloClient'

interface ProspectsState {
  prospects: Prospect[]
  setProspects: (prospects: Prospect[]) => void
  addProspect: (prospect: Prospect) => void
  salesReps: SalesRepresentatives
  setSalesReps: (salesReps: SalesRepresentatives) => void
  nextCursor: string | null
  setNextCursor: (nextCursor: string | null) => void
  nextPage: boolean
  setNextPage: (nextPage: boolean) => void
  resetProspects: () => void
  refreshProspects: () => void
}

export const useProspectsState = create<ProspectsState>((set) => ({
  prospects: [],
  salesReps: [],
  nextCursor: null,
  nextPage: true,

  setProspects: (prospects) =>
    set((state) => ({
      ...state,
      prospects: [...state.prospects, ...prospects],
    })),

  addProspect: (prospect) =>
    set((state) => ({
      ...state,
      prospects: [...state.prospects, prospect],
      prospect: {},
    })),

  setSalesReps: (salesReps) => set({ salesReps }),

  setNextCursor: (nextCursor) => set({ nextCursor }),

  setNextPage: (nextPage) => set({ nextPage }),

  resetProspects: () => set({ prospects: [], nextCursor: null, nextPage: true }),

  refreshProspects: async () => {
    try {
      const apolloClient = authClient()
      const state = useProspectsState.getState()

      const { data, error } = await apolloClient.query<GetProspectsQuery>({
        query: getProspects,
        variables: {
          nextCursor: state.nextCursor,
          page: state.nextPage,
          fetchPolicy: 'network-only',
        },
      })

      if (error) {
        throw new Error(error?.message)
      }

      const updatedProspects = mapProspectsFromData(data)
      const pageInfo = data?.prospects?.pageInfo ?? null
      set({
        prospects: [...state.prospects, ...updatedProspects],
        nextCursor: pageInfo?.endCursor ?? null,
        nextPage: !!pageInfo?.hasNextPage,
      })
    } catch (e) {
      const err = e as Error
      console.error(err?.message ?? e)
    }
  },
}))
