import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { InputWrapper } from './styled'
import { animated, useSpring } from 'react-spring'
import { useOutsideClick } from '../../utils/hooks'
import { getInputLabelAnimationConfig } from './utils'
import { ErrorMessage } from '../ErrorMessage'
import { InfoBox } from '../InfoBox'
import { ColorVariants } from '../../constants'
import { Stack } from '../Spacing'
import { isNumericalAmountForInput } from '../../utils/utils'
import { FlattenSimpleInterpolation } from 'styled-components'

type WrapperProps = {
  width?: string
}
const Wrapper = styled.div<WrapperProps>`
  ${({ width }) => width && `width: ${width}`}
`

export const basicInputLabelStyles = tw`font-slussenLight text-zevoyGray3 uppercase text-xs basis-full`
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
/* @ts-ignore: https://github.com/pmndrs/react-spring/issues/1515 */
const InputLabel = styled(animated.div)`
  ${basicInputLabelStyles}
`
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
/* @ts-ignore: https://github.com/pmndrs/react-spring/issues/1515 */
const InputLabelShifted = styled(animated.div)`
  ${basicInputLabelStyles}
  ${css`
    // if placeholder or value or focused, simulate the animation resolution
    transform: translateY(-6px);
  `}
`

interface RightInnerElementWrapperProps {
  rightInnerElementWidth?: number | null
}
const RightInnerElementWrapper = styled.div<RightInnerElementWrapperProps>`
  ${tw`flex items-center`}
  ${({ rightInnerElementWidth }: RightInnerElementWrapperProps) =>
    !!rightInnerElementWidth &&
    css`
      margin-left: -${rightInnerElementWidth}px;
      z-index: 1000;
    `}
`

interface LeftInnerElementWrapperProps {
  leftInnerElementWidth?: number | null
}
const LeftInnerElementWrapper = styled.div<LeftInnerElementWrapperProps>`
  ${tw`flex items-center`}
  ${({ leftInnerElementWidth }: LeftInnerElementWrapperProps) =>
    !!leftInnerElementWidth &&
    css`
      margin-right: -${leftInnerElementWidth}px;
      z-index: 1000;
    `}
`

type InnerProps = {
  rightInnerElementWidth?: number | null
  leftInnerElementWidth?: number | null
  isShifted?: boolean
}
const Inner = styled.div<InnerProps>`
  ${tw`w-full`}

  // animation is using transform: translateY(-6px) to move the label up.
  // This shift is not included in the height of the container, so to center the input vertically
  // we need to add the same shift to the top margin
  ${({ isShifted }) => isShifted && tw`mt-[6px]`}
  ${({ rightInnerElementWidth }) =>
    rightInnerElementWidth &&
    css`
      padding-right: ${rightInnerElementWidth}px;
    `}
  ${({ leftInnerElementWidth }) =>
    leftInnerElementWidth &&
    css`
      padding-left: ${leftInnerElementWidth}px;
    `}
`

type InputProps = {
  isClickableReadOnly?: boolean
  show?: boolean
}

const Input = styled.input<InputProps>`
  ${tw`-mb-[5px] border-none outline-none bg-transparent text-zevoyBlueBlack basis-0 absolute w-0 h-0 focus:basis-full focus:static focus:w-full focus:h-auto`}
  ${css`
    &::placeholder,
    &:disabled {
      -webkit-text-fill-color: ${theme`colors.zevoyGray3`};
      color: ${theme`colors.zevoyGray3`};
    }
    &:focus {
      flex-basis: 100%;
      position: static;
      width: 100%;
      height: auto;
    }
  `}
  ${({ isClickableReadOnly }) =>
    isClickableReadOnly &&
    css`
      cursor: pointer;
    `}
  ${({ show }) =>
    show &&
    tw`
      static
      basis-full
      w-full
      h-auto
    `}
`

export type TextInputProps = {
  errorMessage?: string
  infoMessage?: string
  infoMessageVariant?: ColorVariants
  width?: string
  height?: string
  label?: string | React.ReactNode
  isNumeric?: boolean
  isAmount?: boolean
  isAmountWithUnlimitedDigitsAfterDot?: boolean
  rightInnerElement?: React.FC
  leftInnerElement?: React.FC
  onInputClick?: () => void
  styles?: FlattenSimpleInterpolation
} & React.InputHTMLAttributes<HTMLInputElement>

export const TextInputNew = ({
  errorMessage,
  infoMessage,
  width,
  height,
  label,
  isNumeric,
  isAmount,
  isAmountWithUnlimitedDigitsAfterDot,
  rightInnerElement: RightInnerElement,
  leftInnerElement: LeftInnerElement,
  placeholder,
  value,
  onInputClick,
  infoMessageVariant,
  styles,
  ...props
}: TextInputProps) => {
  const [labelAnimation, setLabelAnimation] = useSpring(() => null)
  const [isFocused, setIsFocused] = useState(false)
  const inputWrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const rightInnerElementRef = useRef<HTMLDivElement>(null)
  const [rightInputPaddingWidth, setRightInputPaddingWidth] = useState<number | null>(null)
  useLayoutEffect(() => {
    if (!!rightInnerElementRef?.current) {
      setRightInputPaddingWidth(rightInnerElementRef.current.getBoundingClientRect().width)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightInnerElementRef.current, RightInnerElement])

  const leftInnerElementRef = useRef<HTMLDivElement>(null)
  const [leftInputPaddingWidth, setLeftInputPaddingWidth] = useState<number | null>(null)
  // const [showInput, setShowInput] = useState(!!initialValue || !!placeholder)

  useLayoutEffect(() => {
    if (!!leftInnerElementRef?.current) {
      setLeftInputPaddingWidth(leftInnerElementRef.current.getBoundingClientRect().width)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftInnerElementRef.current, LeftInnerElement])

  const handleClick = () => {
    const value = inputRef.current?.value
    const isInputActive = !!inputRef.current && document.activeElement === inputRef.current
    if (!isInputActive && isFocused) {
      inputRef.current?.focus()
      return
    }
    if (isFocused || props.disabled) return
    setIsFocused(true)
    if (value || placeholder) return
    setLabelAnimation({
      ...getInputLabelAnimationConfig(true),
    })
  }

  useOutsideClick(inputWrapperRef, () => {
    const value = inputRef.current?.value
    if (!isFocused || placeholder || value) return
    setIsFocused(false)
    setLabelAnimation(getInputLabelAnimationConfig(false))
  })

  useEffect(() => {
    if (!isFocused) return
    inputRef.current?.focus()
  }, [isFocused])

  const showInput = !!(isFocused || placeholder || value || (isNumeric && value === 0))
  return (
    <Wrapper
      width={width}
      onClick={(e) => {
        onInputClick?.()
      }}
    >
      <Stack>
        <InputWrapper
          onClick={handleClick}
          ref={inputWrapperRef}
          isError={!!errorMessage}
          isDisabled={props.disabled}
          height={height}
          styles={styles}
        >
          {!!LeftInnerElement ? (
            <LeftInnerElementWrapper
              ref={leftInnerElementRef}
              leftInnerElementWidth={leftInputPaddingWidth}
            >
              <LeftInnerElement />
            </LeftInnerElementWrapper>
          ) : null}
          <Inner
            leftInnerElementWidth={leftInputPaddingWidth}
            rightInnerElementWidth={rightInputPaddingWidth}
            isShifted={!!label && showInput}
          >
            {
              // react-spring passes the animated.div props straight to the DOM and with custom props
              // it throws a warning. To avoid that, we need to simulate the same behaviour without
              // passing the custom props
              showInput ? (
                <InputLabelShifted style={labelAnimation}>{label}</InputLabelShifted>
              ) : (
                <InputLabel style={labelAnimation}>{label}</InputLabel>
              )
            }
            <Input
              {...props}
              ref={inputRef}
              show={showInput}
              placeholder={placeholder}
              value={value}
              onKeyPress={(event) => {
                if (isNumeric) {
                  const char = event.key
                  if (!/^\d+$/.test(char)) {
                    event.preventDefault()
                  }
                }

                if (isAmount || isAmountWithUnlimitedDigitsAfterDot) {
                  const el = event.target as HTMLInputElement
                  const char = event.key
                  const selectionStart = el.selectionStart ?? 0
                  const selectionEnd = el.selectionEnd ?? 0
                  const stringValue = String(value)
                  const valueCandidate =
                    stringValue.substring(0, selectionStart) +
                    char +
                    stringValue.substring(
                      selectionStart !== selectionEnd ? selectionEnd : selectionStart,
                    )

                  if (
                    !isNumericalAmountForInput(valueCandidate, isAmountWithUnlimitedDigitsAfterDot)
                  ) {
                    event.preventDefault()
                  }
                }
              }}
              onChange={(event) => {
                if (props.onChange) {
                  props.onChange(event)
                }
              }}
              onMouseDown={(e) => {
                if (onInputClick) {
                  e.preventDefault()
                  return
                }

                props.onMouseDown?.(e)
              }}
              isClickableReadOnly={!!onInputClick && props.readOnly}
            />
          </Inner>
          {!!RightInnerElement ? (
            <RightInnerElementWrapper
              ref={rightInnerElementRef}
              rightInnerElementWidth={rightInputPaddingWidth}
            >
              <RightInnerElement />
            </RightInnerElementWrapper>
          ) : null}
        </InputWrapper>
        {infoMessage && (
          <InfoBox message={infoMessage} type={infoMessageVariant || ColorVariants.Info} />
        )}
        {errorMessage && <ErrorMessage error={errorMessage} isFieldError />}
      </Stack>
    </Wrapper>
  )
}
