import { Datagrid, List, TextField } from 'react-admin'
import { cardFilters } from './Filters'
import { ZevoyList } from '../styles'

export const CardsList = () => (
  <List filters={cardFilters}>
    <ZevoyList>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="cardName" />
        <TextField source="lastFour" />
        <TextField source="cardType" />
        <TextField source="status" />
        <TextField source="cardHolderName" />
      </Datagrid>
    </ZevoyList>
  </List>
)
