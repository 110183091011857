import React from 'react'
import tw from 'twin.macro'
import { Datagrid, FunctionField, List, RefreshButton, TextField } from 'react-admin'
import { ZevoyList } from '../styles'
import { LabelComponent, ListActions } from '../BenefitApplication/BenefitApplicationList'
import { map, uniq } from 'lodash'
import { FormattedDate } from '../IncidentCommunication/IncidentCommunicationList'
import { BenefitMerchant } from '../../../GeneratedGraphQLTypes'
import { postFilters } from './Filters'

const Header = tw.div`text-[32px] font-slussenSemibold mt-10`

export const MerchantList = () => {
  return (
    <>
      <Header>Benefit merchants</Header>
      <List actions={<ListActions />} filters={postFilters}>
        <ZevoyList>
          <RefreshButton />
          <Datagrid bulkActionButtons={false} rowClick={'edit'}>
            <TextField
              source="name"
              sortable={true}
              label={<LabelComponent labelName={'Merchant name'} />}
            />
            <TextField
              source="businessID"
              sortable={false}
              label={<LabelComponent labelName={'Business id'} />}
            />
            <FunctionField
              source="createTime"
              label={<LabelComponent labelName={'Create time'} />}
              render={(record: BenefitMerchant) => <FormattedDate value={record.createTime} />}
            />
            <FunctionField
              source="tags"
              label={<LabelComponent labelName={'Type'} />}
              render={(record: BenefitMerchant) => {
                const categoryIDs = map(record.tags, 'categoryID')
                const uniqueCategoryIDs = uniq(categoryIDs)
                const uniqueCount = uniqueCategoryIDs.length

                if (uniqueCount === 0) {
                  return 'None'
                } else if (uniqueCount === 1) {
                  switch (uniqueCategoryIDs[0]) {
                    case 1:
                      return 'Lunch'
                    case 2:
                      return 'Culture'
                    case 3:
                      return 'Sport'
                    case 4:
                      return 'Transport'
                    default:
                      return 'Other'
                  }
                } else {
                  return 'Several'
                }
              }}
            />
            <FunctionField
              source="locations"
              label={<LabelComponent labelName={'Locations Count'} />}
              render={(record: BenefitMerchant) => (record.locations ? record.locations.length : 0)}
            />
          </Datagrid>
        </ZevoyList>
      </List>
    </>
  )
}
