import React from 'react'
import { PlanCondition } from '../../GeneratedGraphQLTypes'
import tw, { styled, theme } from 'twin.macro'
import { StartingDate } from './StartingDate'
import { PlanSelection } from './PlanSelection'
import { PlanPrice } from './PlanPrice'
import { ReactComponent as Close } from '@zevoy/common/assets/icons/close.svg'
import { usePlanConditionsState } from './store'
import { CountryKey } from '@zevoy/common/src/types/Generic'
import { EndDate } from './EndDate'
import { PlanToggle } from './PlanToggle'
import { PlanNumber } from './PlanNumber'
import { ReactComponent as CardBase } from '../../lib/assets/icons/card_base.svg'
import ClaimsMobileImage from '../../lib/assets/images/claims_mobile.png'
import PhysicalCardLayerImage from '../../lib/assets/images/physical_card_layer.png'
import VirtualSubscriptionCardImage from '../../lib/assets/images/subscription_card_layer.png'

interface WrapperProps {
  isOngoing?: boolean
}
const Wrapper = styled.div`
  ${tw` text-left font-slussenLight box-border bg-lightGrey rounded-lg px-4 py-4`}
  ${({ isOngoing }: WrapperProps) => isOngoing && tw`border-solid border-2 border-brightOrange`}
`
const ConditionWrapper = tw.div`inline-flex flex-row w-full gap-4  text-left box-border  items-center`

const ConditionDetails = tw.div`flex flex-wrap gap-4 items-center`
const DeleteConditionButton = tw.div`ml-auto`
const CloseIcon = tw(Close)`cursor-pointer fill-labelsGrey hover:fill-menuGrey`
const OngoingLabel = tw.div`text-xs text-brightOrange pb-2`
const ConditionDetailsWrapper = tw.div`flex flex-col gap-4`
const DateWrapper = tw.div`flex flex-row gap-4`
const FlexRow = tw.div`w-full flex flex-row gap-4`
const FlexWrap = tw.div`w-full flex flex-wrap gap-x-4`
const FlexColumn = tw.div`flex flex-col items-start w-full`
const IconWrapper = tw.div`relative flex`
const CustomCardLayer = tw.img`absolute h-auto w-[63px]`
const CustomCardBase = tw(CardBase)`h-auto w-[63px]`
const CustomMobileBase = tw(CardBase)`h-[113px] w-[63px]`

interface ConditionProps {
  country: CountryKey
  currentCondition: PlanCondition
  index: number
  endDate?: Date
  isReadOnly?: boolean
  isOngoing?: boolean
  isLastOne?: boolean
}
export const Condition = ({
  currentCondition,
  country,
  isReadOnly,
  index,
  isOngoing,
  endDate,
  isLastOne,
}: ConditionProps) => {
  const { deletePlanConditions, setError } = usePlanConditionsState()

  return (
    <Wrapper isOngoing={isOngoing}>
      {isOngoing && <OngoingLabel>Ongoing</OngoingLabel>}
      <ConditionWrapper>
        <ConditionDetailsWrapper>
          <DateWrapper>
            <PlanSelection
              currentCondition={currentCondition}
              country={country}
              isReadOnly={isReadOnly}
            />
            <StartingDate
              currentCondition={currentCondition}
              isReadOnly={isReadOnly}
              index={index}
            />
            <EndDate endDate={endDate} />
          </DateWrapper>
          <ConditionDetails>
            <PlanPrice
              priceKey="minimumBillingFee"
              label="Minimum billing fee:"
              currentCondition={currentCondition}
              country={country}
              isReadOnly={isReadOnly}
            />
            <PlanPrice
              priceKey="fixedFee"
              label="Fixed fee:"
              currentCondition={currentCondition}
              country={country}
              isReadOnly={isReadOnly}
            />
            {Number(currentCondition.amountPerUser) > 0 && (
              <PlanPrice
                priceKey="amountPerUser"
                label="Amount per user:"
                currentCondition={currentCondition}
                country={country}
                isReadOnly={isReadOnly}
              />
            )}

            <FlexRow>
              <IconWrapper>
                <CustomMobileBase fill={'none'} />
                <CustomCardLayer src={PhysicalCardLayerImage} />
              </IconWrapper>
              <FlexWrap>
                <PlanPrice
                  priceKey="amountPerBusinessCardUser"
                  label="Price/Card user:"
                  currentCondition={currentCondition}
                  country={country}
                  isReadOnly={isReadOnly}
                />
                <PlanNumber
                  numberKey="freeBusinessCardUsers"
                  label="Free card users:"
                  currentCondition={currentCondition}
                  isReadOnly={isReadOnly}
                />
              </FlexWrap>
            </FlexRow>
            <FlexRow>
              <IconWrapper>
                <CustomMobileBase fill={'none'} />
                <CustomCardLayer src={ClaimsMobileImage} />
              </IconWrapper>
              <FlexWrap>
                <PlanPrice
                  priceKey="amountPerClaimUser"
                  label="Price/Claim user:"
                  currentCondition={currentCondition}
                  country={country}
                  isReadOnly={isReadOnly}
                />
                <PlanNumber
                  numberKey="freeClaimUsers"
                  label="Free claim users:"
                  currentCondition={currentCondition}
                  isReadOnly={isReadOnly}
                />
                <PlanToggle
                  toggleKey="freeClaimForBusinessCardUser"
                  label="Free claim for card user:"
                  currentCondition={currentCondition}
                  isReadOnly={isReadOnly}
                />
              </FlexWrap>
            </FlexRow>
            <FlexRow>
              <IconWrapper>
                <CustomCardBase fill={`${theme('colors.zevoyAerospaceOrange')}`} />
                <CustomCardLayer src={VirtualSubscriptionCardImage} />
              </IconWrapper>
              <FlexColumn>
                <FlexWrap>
                  <PlanPrice
                    priceKey="amountPerSubscription"
                    label="Price/Subscription:"
                    currentCondition={currentCondition}
                    country={country}
                    isReadOnly={isReadOnly}
                  />
                  <PlanNumber
                    numberKey="freeSubscriptionCards"
                    label="Free subscriptions:"
                    currentCondition={currentCondition}
                    isReadOnly={isReadOnly}
                  />
                </FlexWrap>
              </FlexColumn>
            </FlexRow>
          </ConditionDetails>
        </ConditionDetailsWrapper>
        {!isReadOnly && !isLastOne && (
          <DeleteConditionButton
            onClick={() => {
              deletePlanConditions(currentCondition.startDate)
              setError('')
            }}
          >
            <CloseIcon />
          </DeleteConditionButton>
        )}
      </ConditionWrapper>
    </Wrapper>
  )
}
