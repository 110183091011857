import { Datagrid, Link, List, TextField, TextFieldProps, useRecordContext } from 'react-admin'
import get from 'lodash/get'
import { tagFilters } from './Filters'

export const TagValuesLinkField = ({ source }: TextFieldProps) => {
  const record = useRecordContext()
  const value = get(record, source!)
  return (
    <Link
      to={`/admin/tagValue?filter={"tagID": "${value}"}`}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      tag values
    </Link>
  )
}

export const TagList = () => (
  <List filters={tagFilters} pagination={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="status" />
      <TagValuesLinkField label="TagValues" source="id" />
    </Datagrid>
  </List>
)
