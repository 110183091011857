import React, { useEffect, useState } from 'react'
import Header from '../../../lib/components/Header'
import BackButtonArrow from '../../../lib/components/BackButton'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Datagrid,
  TextField,
  List,
  Button,
  useListContext,
  useNotify,
  useUpdate,
  useGetOne,
  Confirm,
} from 'react-admin'
import { LabelComponent } from '../BenefitApplication/BenefitApplicationList'
import { postFilters } from './Filters'
import tw from 'twin.macro'
import ContentAdd from '@mui/icons-material/Add'
import { KycMerchantApplication } from '../../../GeneratedGraphQLTypes'
import { useUnselectAll } from 'ra-core'
import { TransactionMerchant } from './types'

const ZevoyList = tw.div`mt-10 border border-r-4 border-lightGrey border-solid`
const BulkActionButtons = () => {
  const { selectedIds, data: merchantIDsData, isLoading } = useListContext()
  const unselectAll = useUnselectAll('availableTransactionMerchantID')

  const navigate = useNavigate()
  const notify = useNotify()
  const [update] = useUpdate()
  const { applicationID } = useParams()

  const [confirmAddMerchantID, setConfirmAddMerchantID] = useState(false)
  const [transactionMerchants, setTransactionMerchants] = useState<TransactionMerchant[]>([])

  const { data } = useGetOne<KycMerchantApplication>('benefitApplication', {
    id: applicationID as any,
  })

  const handleAddMerchantIDClick = () => {
    const formattedMerchantIDs = transactionMerchants.map((enfuceMerchantID) => {
      return {
        acquireReferenceNumber: enfuceMerchantID?.acquireReferenceNumber,
        merchantID: enfuceMerchantID?.merchantID,
      }
    })

    const serverExternalMerchantIDs = data?.externalMerchantIDs || []

    const combinedMerchantIds = [...serverExternalMerchantIDs, ...formattedMerchantIDs]

    update(
      'benefitApplication',
      {
        id: data?.id,
        data: {
          application: {
            applicationID: data?.id,
            name: data?.name,
            businessID: data?.businessID,
            contactName: data?.contactName,
            contactEmail: data?.contactEmail,
            contactPhoneNumber: data?.contactPhoneNumber,
            locations: data?.locations,
            benefits: data?.benefits,
            externalMerchantIDs: combinedMerchantIds,
          },
        },
        previousData: {},
      },
      {
        onSuccess: () => {
          notify('Added successfully', { type: 'success' })
          navigate(`/admin/benefitApplication/${applicationID}`)
          unselectAll()
        },
        onError: (error: any) => {
          notify(`Update failed: ${error?.message ?? 'Unknown error'}`, { type: 'error' })
        },
      },
    )
  }

  useEffect(() => {
    merchantIDsData.forEach((merchantID) => {
      if (
        selectedIds.includes(merchantID.id) &&
        !transactionMerchants.some((item) => item.id === merchantID.id)
      ) {
        setTransactionMerchants((prevState) => [...prevState, merchantID])
      }
    })

    // Remove merchant IDs that are no longer selected
    transactionMerchants.forEach((item) => {
      if (!selectedIds.includes(item.id)) {
        setTransactionMerchants((prevState) =>
          prevState.filter((merchant) => merchant.id !== item.id),
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds])

  return (
    <>
      <Button
        disabled={isLoading}
        style={{ marginTop: '-8px' }}
        startIcon={<ContentAdd />}
        label="Add merchant id"
        onClick={() => {
          setConfirmAddMerchantID(true)
        }}
      />
      <Confirm
        isOpen={confirmAddMerchantID}
        title={`Confirm`}
        content={'Confirm add merchant id?'}
        onConfirm={handleAddMerchantIDClick}
        onClose={() => {
          setConfirmAddMerchantID(false)
        }}
      />
    </>
  )
}

export const AvailableMerchantIDList = () => {
  const navigate = useNavigate()
  const { applicationID } = useParams()
  const unselectAll = useUnselectAll('availableTransactionMerchantID')

  return (
    <>
      <Header
        title={'Available transaction merchant ID list'}
        backButton={
          <BackButtonArrow
            onClick={() => {
              unselectAll()
              navigate(`/admin/benefitApplication/${applicationID}`)
            }}
          />
        }
      />
      <List filters={postFilters} resource={'availableTransactionMerchantID'}>
        <ZevoyList>
          <Datagrid bulkActionButtons={<BulkActionButtons />} rowClick="toggleSelection">
            <TextField
              source="merchantName"
              label={<LabelComponent labelName={'Merchant Name'} />}
            />
            <TextField source="merchantID" label={<LabelComponent labelName={'Merchant ID'} />} />
            <TextField
              css={{ display: 'flex', justifyContent: 'center' }}
              source="acquireReferenceNumber"
              label={<LabelComponent labelName={'Acquire Reference Number'} />}
            />
          </Datagrid>
        </ZevoyList>
      </List>
    </>
  )
}
