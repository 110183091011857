import tw, { styled, css } from 'twin.macro'

export const OrganizationHeaderWrapper = tw.div`flex-grow flex flex-row pt-4 text-xs font-slussenLight`
export const OrganizationHeaderName = tw.div`w-full font-slussenSemibold pl-20 pt-1`
export const OrganizationItemName = tw.div`w-full font-slussenSemibold pt-1 pl-6`
export const OrganizationCreditApplicationItem = tw.div`w-3/4 font-slussenSemibold opacity-50 pt-1 pl-8`
export const OrganizationHeaderLabel = tw(OrganizationHeaderName)`opacity-50 pl-2 pt-1`
export const OrganizationHeaderTypeItem = tw.div`w-1/4 font-slussenSemibold pt-1 pl-20`
export const OrganizationHeaderDateItem = tw(OrganizationHeaderName)`w-3/4 opacity-50 pl-16 pt-1`
export const OrganizationHeaderInvitesItem = tw(OrganizationHeaderName)`w-3/4 opacity-50 pl-16 pt-1`
export const OrganizationHeaderSalesItem = tw(OrganizationHeaderName)`w-3/4 opacity-50 pl-12 pt-1`
export const OrganizationHeaderStatusItem = tw(OrganizationHeaderName)`w-3/4 opacity-50 pl-8 pt-1`
export const OrganizationHeaderBalanceItem = tw(OrganizationHeaderName)`w-3/4 opacity-50 pl-4 pt-1`
export const OrganizationHeaderCreditApplicationsItem = tw(
  OrganizationHeaderName,
)`w-3/4 opacity-50 pt-1`

export const OrganizationTypeLabel = tw.div`w-1/4 font-slussenSemibold pt-1 pl-10`
export const OrganizationDateLabel = tw.div`w-3/4 font-slussenSemibold pl-16 pt-1`
export const OrganizationInvitesLabel = tw.div`w-3/4 font-slussenSemibold pl-16 pt-1`

export const OrganizationPickerWrapper = tw.div`flex flex-row justify-start pl-6 py-4 space-x-4 justify-start items-center text-sm`
export const OrganizationFilterOptions = styled.section(({ selected }) => [
  tw`flex flex-row text-menuGrey opacity-50 space-x-2 py-2 hover:bg-lightGrey cursor-default justify-center items-center font-slussenSemibold`,
  selected && tw`font-slussenSemibold border-b-2 border-menuGrey opacity-100`,
])

export const FullWidthTable = tw.table`w-full`
export const TableHeader = tw.thead`text-left font-slussenLight text-sm`
export const TableRow = tw.tr`hover:shadow-heavy rounded-xl py-2 text-sm border-b-2 cursor-pointer`
export const TableCell = styled.td`
  ${tw`h-10 px-1 font-slussenSemibold`}
  ${css`--webkit-line-clamp-2;`}
`
export const TableCellLight = tw(TableCell)`opacity-50`
export const TableHeaderCell = tw(TableCell)``
export const TableCellCompact = tw.td`px-1`

export const OrganizationTableContainer = tw.div`pl-5 pr-1`

export const SectionWrapper = tw.div`
  flex flex-col w-full p-2 
`
export const HeaderSectionWrapper = tw(SectionWrapper)`
  border-b border-gray-800
`
export const ModalStyledHeader = tw.div`
  flex flex-row items-start text-menuGrey text-lg font-slussenSemibold
`
export const OrganizationListRow = tw(OrganizationHeaderWrapper)`
  hover:shadow-heavy rounded-xl py-2 text-sm border-b-2
`
export const OrganizationListWrapper = tw.div`
  flex flex-col space-y-2 pt-6
`
export const OrganizationOptionsButton = tw.div`hover:bg-lightGrey h-4 w-4 rounded-full flex items-center justify-center px-1 py-1 mr-4`

export const ModalInputWrapper = tw.div`flex flex-col py-1 space-y-3`

export const ModalInputLabel = tw.label`flex justify-start font-slussenLight text-sm text-labelsGrey opacity-50`

export const ModalPadding = tw.div`px-6 py-4 flex flex-col space-y-1`
export const PromptInfo = tw.div`font-slussenLight text-sm flex space-y-3 pt-4 pb-4`

export const DismissButton = tw.button`
  bg-white hover:opacity-75 pt-4 py-2 px-12 text-darkGrey rounded-xl shadow-heavy border-0 w-full
`

// Organization details

export const DetailsScreenWrapper = tw.section`flex bg-white`
export const DetailsHeader = tw.div`font-slussenSemibold border-b-2 border-opacity-25 w-full flex flex-row justify-start items-center text-base`
export const DetailsWrapper = tw.div`w-full pt-4 h-full pb-4`
export const AdditionalInfoText = tw.div`font-slussenLight text-sm flex-grow w-110`
export const DetailsLabel = tw.div`flex justify-start font-slussenLight text-sm text-darkGrey `
export const DetailsInputWrapper = tw.div`flex flex-col py-1 w-110 space-y-3`
export const DetailsTextArea = tw.textarea`bg-white font-slussenLight p-2 text-sm shadow-heavy h-24 border-0 w-100`
export const DetailsInviteWrapper = tw.a`flex flex-row bg-white pt-2 pb-4 pl-2 text-sm shadow-heavy rounded-lg focus:outline-none border-0 w-100 items-center cursor-pointer no-underline`
export const DetailsInviteSwitcherWrapper = tw.div`flex flex-row bg-white pt-2 pb-4 pl-2 text-sm shadow-heavy rounded-lg focus:outline-none border-0 w-100 items-center`
export const DetailsInviteHeading = tw.div`flex justify-start font-slussenSemibold text-base text-black pt-2 pb-2 pl-2`
export const DetailsInviteText = tw.div`flex justify-start font-slussenLight text-sm text-labelsGrey pl-2`
export const DetailsInviteButton = tw.div`font-slussenSemibold text-sm bg-brightOrange bg-opacity-10 text-brightOrange text-opacity-80 rounded flex justify-center items-center ml-2 mr-2 mt-2 p-1`
export const DetailsInviteCol = tw.div`flex flex-col justify-start items-start w-3/4`
export const DetailsInviteButtonRow = tw.div`flex-row w-full justify-end items-end`
export const DetailsInviteButtonWrapper = tw.div`px-6 h-full`
export const DetailsChevronWrapper = tw.div`flex justify-center items-center mr-2 mt-2 p-1`
export const DetailsUnderline = tw.div`w-110 shadow-heavy`
export const DetailsTwoButtonWrapper = tw.div`flex flex-row pt-2 pb-0 space-x-3 items-center w-110 justify-center`
export const DetailsButtonWrapper = tw.div`w-1/6 pt-4 h-full pb-4`
export const DetailsCreditApplicationStatus = tw.div`font-slussenSemibold ml-2`
export const DetailsInput = tw.input`bg-white flex-grow p-2 text-sm shadow-heavy rounded-lg focus:outline-none border-0`
export const ActiveUsersWrapper = tw.div`w-110`
export const ActiveUsersHeader = tw(DetailsHeader)`pt-8`
export const ActiveUserListItem = tw.div`font-slussenLight hover:underline py-2 text-sm cursor-pointer`

export const FlexRow = tw.div`flex flex-row items-center gap-4`
export const Currency = tw.div`px-2 font-slussenLight text-sm text-darkGrey`
