import { DetailsHeader, DetailsUnderline, ModalInputLabel } from '../styles'
import { SaveButton } from '../../../lib/styles'
import tw from 'twin.macro'
import { InfoWrapper } from '../../Prospects/styles'
import { TextInput } from '@zevoy/common/src/components/TextInput'
import { ChangeEvent, useEffect, useState } from 'react'
import { useOrganizationDetailsStore } from './store'
import { useRequestCashbackUpdate } from '../../../lib/hooks'
import { Plan } from '../../../GeneratedGraphQLTypes'

const Wrapper = tw(InfoWrapper)`p-0 pt-8`

export const Cashback = () => {
  const { organization } = useOrganizationDetailsStore()
  const [percentage, setPercentage] = useState<string>(
    organization?.plan?.cashback?.percentage.toFixed(2) ?? '0.00',
  )

  useEffect(() => {
    if (organization?.plan?.cashback?.percentage !== undefined) {
      setPercentage(organization.plan.cashback.percentage.toFixed(2))
    }
  }, [organization])
  const [validationError, setValidationError] = useState<string>()
  const { updateCashback, loading } = useRequestCashbackUpdate()

  const validate = (value: string) => {
    const regexp = new RegExp(/^(?:\d+|\d*\.\d+)$/)
    const valid = regexp.test(value)
    if (!valid) {
      setValidationError('Cashback must be a number between 0.00 ~ 100.00')
      return false
    }
    if (parseFloat(value) < 0 || parseFloat(value) > 100) {
      setValidationError('Cashback must be a number between 0.00 ~ 100.00')
      return false
    }
    setValidationError(undefined)
    return true
  }

  if (!organization) {
    return (
      <>
        <DetailsHeader>Cashback</DetailsHeader>
        <DetailsUnderline />
        <ModalInputLabel>Unavailable</ModalInputLabel>
      </>
    )
  }

  const handleSave = async () => {
    await updateCashback(
      { percentage: parseFloat(percentage) },
      organization.id,
      organization?.plan as Plan,
    )
  }

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    validate(e.target.value)
    setPercentage(e.target.value)
  }

  return (
    <Wrapper>
      <DetailsHeader>Cashback</DetailsHeader>
      <DetailsUnderline />
      <TextInput errorMessage={validationError} value={percentage} onChange={onChangeHandler} />
      <SaveButton disabled={loading || !!validationError} onClick={handleSave}>
        Save
      </SaveButton>
    </Wrapper>
  )
}
