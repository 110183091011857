import React from 'react'
import tw from 'twin.macro'
import { isEmpty } from 'lodash'
import { TextInput } from '@zevoy/common/src/components/TextInput'

import { ModalInputLabel } from '../../../lib/styles'
import { InputWrapper } from '../../CreditApplication/styles'
import { CountryKey, CurrencySigns } from '@zevoy/common/src/types/Generic'
import { NilableNumber } from './types'

type Props = {
  country: CountryKey
  spendPotential: NilableNumber
  setSpendPotential: (potential: number | null) => void
}
const CurrencyLabel = tw.div`mx-2`

export const SpendPotential = ({ country, spendPotential, setSpendPotential }: Props) => {
  return (
    <InputWrapper>
      <ModalInputLabel>Spend Potential:</ModalInputLabel>
      <TextInput
        isNumeric
        value={spendPotential ?? ''}
        onChange={({ target: { value } }) => {
          setSpendPotential(!isEmpty(value) ? Number.parseInt(value) : null)
        }}
        rightInnerElement={() => <CurrencyLabel>{CurrencySigns[country]}</CurrencyLabel>}
      />
    </InputWrapper>
  )
}
