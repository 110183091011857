import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { DetailsTextArea, InfoWrapper } from '../../Prospects/styles'
import { DetailsHeader, DetailsUnderline, ModalInputLabel } from '../styles'
import { useOrganizationDetailsStore } from './store'
import { SaveButton } from '../../../lib/styles'
import { Plan } from '../../../GeneratedGraphQLTypes'
import { useRequestCommentsUpdate } from '../../../lib/hooks'

const CommentsWrapper = tw(InfoWrapper)`w-110 p-0 pt-8`

export const Comments = () => {
  const { organization } = useOrganizationDetailsStore()
  const [comments, setComments] = React.useState(organization?.plan?.comments ?? '')
  const { updateComments, loading } = useRequestCommentsUpdate()

  useEffect(() => {
    setComments(organization?.plan?.comments ?? '')
  }, [organization])

  if (!organization) {
    return (
      <>
        <DetailsHeader>Comments</DetailsHeader>
        <DetailsUnderline />
        <ModalInputLabel>Unavailable</ModalInputLabel>
      </>
    )
  }

  const handleSave = async () => {
    await updateComments(comments, organization.id, organization?.plan as Plan)
  }

  return (
    <CommentsWrapper>
      <DetailsHeader>Comments</DetailsHeader>
      <DetailsUnderline />
      <DetailsTextArea
        value={comments ?? ''}
        onChange={(e) => {
          setComments(e.target.value)
        }}
      />
      <SaveButton onClick={handleSave} disabled={loading}>
        Save Comments
      </SaveButton>
    </CommentsWrapper>
  )
}
