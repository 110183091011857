import React from 'react'
import { ModalInputLabel } from '../../../../../lib/styles'
import { ModalInputWrapper } from '../../../styles'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Option } from '@zevoy/common/src/types/Generic'
import { InputWrapper } from '../../../../CreditApplication/styles'
import tw from 'twin.macro'
import { useProspectCardState } from '../store'
import { useSalesReps } from '../../../hooks'
import { getSalesRepOptions } from '../../../../../lib/utils'

const Wrapper = tw(InputWrapper)`text-left font-slussenLight`

export const SalesRepresentative = () => {
  const { prospect, updateProspect, setError } = useProspectCardState()
  const { salesReps, error } = useSalesReps()

  if (error) {
    setError(error)
  }

  const salesRepOptions: Option[] = getSalesRepOptions(salesReps)
  return (
    <Wrapper>
      <ModalInputLabel>Sales Representative:</ModalInputLabel>
      <ModalInputWrapper>
        <SelectComponent
          options={salesRepOptions}
          value={salesRepOptions.find(
            (salesRepOption: Option) => salesRepOption.value === prospect?.salesRep?.id,
          )}
          onChange={(newValue) => {
            updateProspect({
              salesRep: {
                id: newValue?.value as string,
                fullName: newValue?.label as string,
              },
            })
          }}
        />
      </ModalInputWrapper>
    </Wrapper>
  )
}
