import React, { forwardRef, HTMLProps } from 'react'
import DatePicker from 'react-datepicker'
import tw from 'twin.macro'
import { DatePickerButton, PlanLabel } from './styles'
import { DateTime } from 'luxon'

const DatePickerWrapper = tw.div``
interface EndDateProps {
  endDate?: Date
}
export const EndDate = ({ endDate }: EndDateProps) => {
  const DatePickerCustom = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>(
    ({ value }, ref) => (
      <DatePickerButton isDisabled={true} ref={ref}>
        {value
          ? DateTime.fromFormat(value as string, 'MM/dd/yyyy').toFormat('dd-MM-yyyy')
          : 'No end date'}
      </DatePickerButton>
    ),
  )
  return (
    <DatePickerWrapper>
      <PlanLabel>End date:</PlanLabel>
      <DatePicker
        disabled={true}
        selected={endDate}
        onChange={() => {}}
        customInput={<DatePickerCustom />}
        onKeyDown={(event) => {
          event.preventDefault()
        }}
      />
    </DatePickerWrapper>
  )
}
