import React from 'react'
import { DetailsHeader, DetailsInputWrapper, DetailsUnderline } from '../../styles'
import { Plans } from '../../../Plans/Plans'
import { CountryKey } from '@zevoy/common/src/types/Generic'
import { InputRow, SaveButton } from '../../../../lib/styles'
import { useProspectCardState } from './store'
import { useRequestPlanUpdate } from '../../../../lib/hooks'

export const ProspectPlans = () => {
  const { prospect } = useProspectCardState()
  const { updatePlans, loading } = useRequestPlanUpdate(true)

  const handleSave = async () => {
    await updatePlans(prospect?.id)
  }

  return (
    <>
      <DetailsHeader>Plans</DetailsHeader>
      <DetailsUnderline />
      <Plans country={prospect?.country as CountryKey} noTitleLabel />
      <DetailsInputWrapper>
        <InputRow>
          <SaveButton onClick={handleSave} disabled={loading}>
            Save changes
          </SaveButton>
        </InputRow>
      </DetailsInputWrapper>
    </>
  )
}
