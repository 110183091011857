import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { PlanLabel } from './styles'
import { PlanCondition, PlanType } from '../../GeneratedGraphQLTypes'
import { usePlanConditionsState } from './store'
import { CountryKey, CurrencySigns } from '@zevoy/common/src/types/Generic'
import { TextInput } from '@zevoy/common/src/components/TextInput'

const Wrapper = tw.div``
const PriceInput = styled(TextInput)`
  ${tw`font-slussenLight`}
  ${css`
    flex-basis: 80px;
    width: 110px;
  `}
`
const CurrencyLabel = tw.div`mx-2`

type PriceKey =
  | 'amountPerUser'
  | 'amountPerBusinessCardUser'
  | 'amountPerClaimUser'
  | 'amountPerSubscription'
  | 'minimumBillingFee'
  | 'fixedFee'

interface PricePerUserProps {
  priceKey: PriceKey
  label?: string
  country: CountryKey
  currentCondition: PlanCondition
  isReadOnly?: boolean
}
export const PlanPrice = ({
  priceKey,
  label,
  currentCondition,
  country,
  isReadOnly,
}: PricePerUserProps) => {
  const { updatePlanCondition } = usePlanConditionsState()
  return (
    <Wrapper>
      {label && <PlanLabel>{label}</PlanLabel>}
      <PriceInput
        disabled={currentCondition.type !== PlanType.Custom || isReadOnly}
        isSmall
        value={currentCondition[priceKey]}
        onChange={(e) => {
          let amount = e.target.value.replace(',', '.')
          const numericAmount = Number(amount)
          if (isNaN(numericAmount)) {
            return
          }
          if (Number(numericAmount.toFixed(2)) !== numericAmount) {
            return
          }
          updatePlanCondition(currentCondition.startDate, {
            [priceKey]: amount,
          })
        }}
        rightInnerElement={() => <CurrencyLabel>{CurrencySigns[country]}</CurrencyLabel>}
      />
    </Wrapper>
  )
}
