import React from 'react'
import { ActiveUserListItem, ActiveUsersWrapper, DetailsHeader, DetailsUnderline } from '../styles'
import { User, UserOrganization } from '../../../GeneratedGraphQLTypes'
import tw from 'twin.macro'
import { useOrganizationDetailsStore } from './store'
import { useNavigate } from 'react-router-dom'
import { InfoWrapper } from '../../Prospects/styles'

const Wrapper = tw(InfoWrapper)`w-110 p-0 pt-8`
export const ActiveUsers = () => {
  const { organization } = useOrganizationDetailsStore()
  const navigate = useNavigate()
  const activeUsers = organization?.members?.edges.map((e) => e.edge) ?? []
  const handleClick = (user: User) => {
    const organizationId = organization?.id
    const userId = user?.id

    if (organizationId && userId) {
      navigate(`/organizations/${organizationId}/users/${userId}`)
    }
  }
  return (
    <Wrapper>
      <DetailsHeader>Active users</DetailsHeader>
      <DetailsUnderline />
      <ActiveUsersWrapper>
        {activeUsers.map(
          (item: UserOrganization) =>
            !!item?.user?.fullName && (
              <ActiveUserListItem key={item.user.id} onClick={() => handleClick(item.user)}>
                {item.user.fullName}
              </ActiveUserListItem>
            ),
        )}
      </ActiveUsersWrapper>
    </Wrapper>
  )
}
