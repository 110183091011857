import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { styled, css } from 'twin.macro'

const ModalContent = styled.div`
  ${tw`bg-white rounded-2xl w-120  text-center`}
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
  ${(props) =>
    props.width &&
    css`
      width: '${props.width}';
    `}
`

const ModalContentWrapper = tw.div`
  fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50
`

const ModalBackground = tw.div`
 absolute inset-0 z-40 opacity-25 bg-black
`

const Modal = (props) => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  return (
    <Fragment>
      <ModalBackground />
      <ModalContentWrapper onClick={props.exit || goBack}>
        <ModalContent
          maxWidth={props.maxWidth}
          width={props.width}
          onClick={(e) => e.stopPropagation()}
        >
          {props.children}
        </ModalContent>
      </ModalContentWrapper>
    </Fragment>
  )
}
export default Modal
