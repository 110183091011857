import React from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated } from '../auth'
import useStore from '../coreStore'

type Props = {
  children?: React.ReactNode
}

const PrivateRoute = ({ children }: Props): JSX.Element => {
  const { loginProgress, setLoginProgress } = useStore()
  if (!loginProgress) {
    if (isAuthenticated() !== null) {
      setLoginProgress(true)
    }
    return <div className="loading-wrap">Loading...</div>
  } else if (loginProgress && isAuthenticated()) {
    return <>{children}</>
  }
  return <Navigate to="/login" replace />
}

export default PrivateRoute
