import React, { Component } from 'react'
import { ReactComponent as ErrorImage } from '@zevoy/common/assets/icons/error_page_image.svg'
import { ReactComponent as Copy } from '@zevoy/common/assets/icons/copy.svg'
import {
  ContentWrapper,
  CopyButton,
  CopyButtonContainer,
  ErrorFrame,
  ErrorHeader,
  ErrorWrapper,
  SupportText,
  VerticallyAndHorizontallyCentered,
} from '@zevoy/common/src/components/Error/styled'

export class ErrorBoundary extends Component {
  state: { hasError: boolean; errorInfo: Error | null }
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, errorInfo: null }
  }

  componentDidCatch(error: Error, info: any) {
    // Display fallback UI
    this.setState({ hasError: true })
    this.setState({ errorInfo: info })
    // You can also log the error to an error reporting service
    console.log(error, info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ContentWrapper>
          <VerticallyAndHorizontallyCentered>
            <ErrorHeader>Error</ErrorHeader>
            <ErrorImage />
            <SupportText />
            <ErrorWrapper>
              <ErrorFrame>
                <CopyButtonContainer>
                  <CopyButton
                    onClick={async () =>
                      await navigator.clipboard.writeText(
                        JSON.stringify(this.state.errorInfo, null, 2),
                      )
                    }
                  >
                    Copy to clipboard
                    <Copy />
                  </CopyButton>
                </CopyButtonContainer>
                {JSON.stringify(this.state.errorInfo, null, 2)}
              </ErrorFrame>
            </ErrorWrapper>
          </VerticallyAndHorizontallyCentered>
        </ContentWrapper>
      )
    }
    return this.props.children
  }
}
