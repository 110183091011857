import React, { useState, useEffect, useRef } from 'react'
import tw from 'twin.macro'
import { refreshToken, authToken, accessToken } from '../../lib/constants'
import { ReactComponent as Dots } from '../../lib/assets/icons/trippleDots.svg'
import { useNavigate } from 'react-router-dom'
import { OptionsButton } from './styles'
import useStore from '../../lib/coreStore'

const DropdownWrapper = tw.div`relative`
const DropdownContainer = tw.div`
  bg-white shadow-heavy rounded-xl flex flex-col rounded text-sm space-y-1
  absolute py-2 font-slussenLight select-none w-32 left-auto
`
const TeamItem = tw.div`hover:bg-lightGrey`
const Text = tw.div`w-full px-4 cursor-pointer`

const SidebarUserOptions = () => {
  const [toggle, toggleDropdown] = useState(false)
  const navigate = useNavigate()
  const wrapperRef = useRef(null)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleDropdown(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const { setLoginProgress } = useStore()
  useOutsideAlerter(wrapperRef)
  return (
    <div ref={wrapperRef}>
      <OptionsButton
        onClick={(e) => {
          e.stopPropagation()
          toggleDropdown(!toggle)
        }}
      >
        <Dots />
      </OptionsButton>
      {toggle && (
        <DropdownWrapper>
          <DropdownContainer
            style={{ bottom: '2.1rem', right: '0rem' }}
            onClick={() => toggleDropdown(!toggle)}
          >
            <TeamItem>
              <Text
                onClick={(e) => {
                  e.stopPropagation()
                  toggleDropdown(false)
                  localStorage.removeItem(refreshToken)
                  localStorage.removeItem(authToken)
                  localStorage.removeItem(accessToken)
                  setLoginProgress(false)
                  navigate('/login')
                }}
              >
                <div>Logout</div>
              </Text>
            </TeamItem>
          </DropdownContainer>
        </DropdownWrapper>
      )}
    </div>
  )
}

export default SidebarUserOptions
