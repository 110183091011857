import React from 'react'
import { ModalInputLabel } from '../../../lib/styles'
import { InputWrapper } from '../../CreditApplication/styles'
import { Toggle } from '@zevoy/common/src/components/Toggle/Toggle'
import { NilableBoolean } from './types'

type Props = {
  claimsUsers: NilableBoolean
  setClaimsUsers: (claimsUsers: boolean) => void
}

export const HasClaimUsers = ({ claimsUsers, setClaimsUsers }: Props) => {
  return (
    <InputWrapper>
      <ModalInputLabel>Claims Users:</ModalInputLabel>
      <Toggle
        initialValue={claimsUsers ?? false}
        onChange={(value) => {
          setClaimsUsers(value)
        }}
      />
    </InputWrapper>
  )
}
