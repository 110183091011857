import React from 'react'
import { InfoWrapper, UserName, UserWrapper, Company } from './styles'
import UserOptions from './UserOptions'
import { useGetCurrentUserDataQuery } from '../../GeneratedGraphQLTypes'

const User = () => {
  const { data } = useGetCurrentUserDataQuery({
    fetchPolicy: 'cache-and-network',
  })

  return (
    <UserWrapper>
      <InfoWrapper>
        <UserName>{data?.me.fullName || ''}</UserName>
        <Company>Company Name</Company>
      </InfoWrapper>
      <UserOptions />
    </UserWrapper>
  )
}

export default User
