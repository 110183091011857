import { useState } from 'react'
import {
  Button,
  Datagrid,
  ExportButton,
  List,
  RaRecord,
  TextField,
  TopToolbar,
  downloadCSV,
  useNotify,
  useRefresh,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'

import Upload from '@mui/icons-material/Upload'
import { tagValueFilters } from './Filters'
import { TagValueStatus, useImportTagValuesMutation } from '../../../../GeneratedGraphQLTypes'
import { csvToJson } from '../../utils'

const ListActions = () => {
  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null)
  const [importTagVlues] = useImportTagValuesMutation()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const content = e.target?.result
        if (typeof content === 'string') {
          const values = csvToJson(content)
          const tagValues = values
            .filter((item) => item.status === TagValueStatus.Enabled)
            .map((item) => {
              return {
                tagID: item.tagID,
                name: item.name,
                value: item.value,
              }
            })
          importTagVlues({
            variables: {
              input: {
                tagValues,
              },
            },
            onCompleted: () => {
              notify('Import tag values successflly', { type: 'success' })
              refresh()
            },
            onError: () => {
              notify('Import tag values failed', { type: 'error' })
            },
          })
        }
      }
      reader.readAsText(file)
    }
  }

  const openFileInput = () => {
    if (fileInput) {
      fileInput.click()
    }
  }

  return (
    <TopToolbar>
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={(input) => setFileInput(input)}
      />
      <Button startIcon={<Upload />} label="Import" onClick={openFileInput} />
      <ExportButton />
    </TopToolbar>
  )
}

const exporter = (tagValues: RaRecord[]) => {
  const tagValuesForExport = tagValues.map((tagValue) => {
    const { name, value, tag, status } = tagValue // omit backlinks and author
    return {
      name,
      value,
      tagID: tag.id,
      status,
    }
  })
  jsonExport(
    tagValuesForExport,
    {
      headers: ['tagID', 'name', 'value', 'status'], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'tagValues') // download as 'tagValues.csv` file
    },
  )
}

export const TagValueList = () => (
  <List filters={tagValueFilters} actions={<ListActions />} exporter={exporter}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <TextField source="status" />
    </Datagrid>
  </List>
)
