import React from 'react'
import { ApolloProvider } from '@apollo/client'
import './App.css'
import { RouterWrapper } from './lib/routing/router'
import { BrowserRouter } from 'react-router-dom'
import { authClient } from './lib/apolloClient'
import { ErrorBoundary } from '@zevoy/common/src/components/Error/ErrorBoundary'

const App = () => {
  return (
    <>
      <ErrorBoundary>
        <ApolloProvider client={authClient()}>
          <BrowserRouter>
            <RouterWrapper />
          </BrowserRouter>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
