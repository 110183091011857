import React, { useState } from 'react'
import { InputRow, ModalInputLabel, SaveButton } from '../../../../lib/styles'
import { useProspectCardState } from './store'
import { DetailsHeader, DetailsInputWrapper, DetailsUnderline } from '../../styles'
import { OnboardingFeeComponent } from '../OnboardingFeeComponent'
import { toast } from 'react-toastify'
import {
  useDeleteOnboardingFeeMutation,
  useUpdateOnboardingFeeMutation,
} from '../../../../GeneratedGraphQLTypes'
import { CountryKey } from '@zevoy/common/src/types/Generic'

export const OnboardingFee = () => {
  const { prospect, updateProspect } = useProspectCardState()
  const [loading, setLoading] = useState(false)
  const [saveOnboardingFee] = useUpdateOnboardingFeeMutation()
  const [deleteOnboardingFee] = useDeleteOnboardingFeeMutation()

  if (!prospect) {
    return (
      <>
        <DetailsHeader>Onboarding fee</DetailsHeader>
        <DetailsUnderline />
        <ModalInputLabel>Unavailable</ModalInputLabel>
      </>
    )
  }

  const handleSave = async () => {
    try {
      if (!prospect?.id) {
        return
      }
      setLoading(true)
      if (prospect.onboardingFee === null) {
        await deleteOnboardingFee({
          variables: {
            id: prospect.id,
          },
        })
        toast.success('Onboarding fee was successfully removed!')
        return
      }

      await saveOnboardingFee({
        variables: {
          id: prospect.id,
          fee: prospect.onboardingFee,
          date: prospect.onboardingFeeBillingDate,
        },
      })

      toast.success('Successfully saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <DetailsHeader>Onboarding fee</DetailsHeader>
      <DetailsUnderline />
      <OnboardingFeeComponent
        onboardingFee={prospect.onboardingFee}
        onboardingFeeBillingDate={new Date(prospect.onboardingFeeBillingDate)}
        setOnboardingFee={(fee) => {
          updateProspect({ onboardingFee: fee })
        }}
        setOnboardingFeeBillingDate={(date) => {
          updateProspect({ onboardingFeeBillingDate: date })
        }}
        country={prospect.country as CountryKey}
      />

      <DetailsInputWrapper>
        <InputRow>
          <SaveButton onClick={handleSave} disabled={loading}>
            Save changes
          </SaveButton>
        </InputRow>
      </DetailsInputWrapper>
    </>
  )
}
