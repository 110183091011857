import { authQuery } from '../../lib/apolloClient'

export const getOrganizationsDropdown = async () =>
  await authQuery(`
    query getOrganizationsDropdown {
      organizations(
        input: {from: null, limit: null}
      ) {
        totalCount
        edges {
          cursor
          edge {
            id
            name
          }
        }
        pageInfo {
          startCursor
          endCursor
          nextCursor
          size
          hasNextPage
        }
    }
  }
`)

export const GetOrgWalletTokens = async (id) => {
  const res = await authQuery(`
  query GetOrgWalletTokens {
    organization(id: "${id}") {
      cards(input: {limit: null}) {
        edges {
          edge {
            cardHolderName
            maskedCardNumber
            walletTokens {
              provider
              tokenUniqueReferenceID
              requestorID
              status
            }
          }
        }
      }
    }
  }
`)
  return res.data.organization
}
