import create, { SetState } from 'zustand'
import { Prospect, ProspectPlan } from '../../types'

interface ProspectCardState {
  prospect: Prospect | null
  setProspect: (prospect: Prospect) => void
  updateProspect: (prospect: Partial<Prospect>) => void
  updateProspectPlan: (plan: Partial<ProspectPlan>) => void
  error: string
  setError: (error: string) => void
}

export const useProspectCardState = create<ProspectCardState>(
  (set: SetState<ProspectCardState>) => ({
    prospect: null,
    error: '',
    setProspect: (prospect: Prospect) => set({ prospect }),
    updateProspect: (prospect: Partial<Prospect>) => {
      const currentProspect = useProspectCardState.getState().prospect
      if (currentProspect) {
        set({ prospect: { ...currentProspect, ...prospect } })
      }
    },
    updateProspectPlan: (plan: Partial<ProspectPlan>) => {
      const currentProspect = useProspectCardState.getState().prospect
      if (!currentProspect) {
        return
      }

      const currentPlan = currentProspect.plan
      if (currentPlan) {
        set({
          prospect: {
            ...currentProspect,
            plan: { ...currentPlan, ...plan },
          },
        })
      }
    },
    setError: (error: string) => set({ error }),
  }),
)
