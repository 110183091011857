import React from 'react'
import { ModalInputLabel } from '../../../../../lib/styles'
import { ModalInputWrapper } from '../../../styles'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Option } from '@zevoy/common/src/types/Generic'
import { InputWrapper } from '../../../../CreditApplication/styles'
import tw from 'twin.macro'
import { useNewProspectState } from './store'
import { useSalesReps } from '../../../hooks'

const Wrapper = tw(InputWrapper)`text-left font-slussenLight`

export const SalesRepresentative = () => {
  const { salesRepId, setSalesRepId, setError } = useNewProspectState()
  const { salesReps, error } = useSalesReps()

  if (error) {
    setError(error)
  }

  const salesRepOptions: Option[] = salesReps.map((salesRep) => ({
    label: salesRep.fullName,
    value: salesRep.id,
  }))

  return (
    <Wrapper>
      <ModalInputLabel>Sales Representative:</ModalInputLabel>
      <ModalInputWrapper>
        <SelectComponent
          options={salesRepOptions}
          value={salesRepOptions.find(
            (salesRepOption: Option) => salesRepOption.value === salesRepId,
          )}
          onChange={(newValue) => {
            setSalesRepId(newValue?.value as string)
          }}
        />
      </ModalInputWrapper>
    </Wrapper>
  )
}
