import { SelectInput, TextInput } from 'react-admin'
import { CardStatus, CardType } from '../../../GeneratedGraphQLTypes'

const types = [
  { id: CardType.Physical, name: 'Physical' },
  { id: CardType.Virtual, name: 'Virtual' },
  { id: CardType.Subscription, name: 'Subscription' },
]
const statuses = [
  { id: CardStatus.CardOk, name: 'CardOK' },
  { id: CardStatus.CardOrdered, name: 'CardOrdered' },
  { id: CardStatus.CardNotActivated, name: 'CardNotActivated' },
  { id: CardStatus.CardFrozen, name: 'CardFrozen' },
  { id: CardStatus.CardExpired, name: 'CardExpired' },
  { id: CardStatus.CardClosed, name: 'CardClosed' },
]
export const cardFilters = [
  <SelectInput
    variant="standard"
    multiline
    choices={types}
    label="Types"
    source="types"
    alwaysOn
  />,
  <SelectInput
    variant="standard"
    multiline
    choices={statuses}
    label="Status"
    source="statuses"
    alwaysOn
  />,
  <TextInput variant="standard" label="Search" source="q" alwaysOn />,
]
