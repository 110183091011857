import {
  VirtualWalletHeaderWrapper,
  VirtualWalletCardholderHeaderName,
  VirtualWalletMaskedCardNumber,
} from './styles'

const VirtualWalletHeader = () => {
  return (
    <VirtualWalletHeaderWrapper>
      <VirtualWalletCardholderHeaderName>Cardholder</VirtualWalletCardholderHeaderName>
      <VirtualWalletMaskedCardNumber>Masked Card Number</VirtualWalletMaskedCardNumber>
    </VirtualWalletHeaderWrapper>
  )
}

export default VirtualWalletHeader
