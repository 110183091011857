import * as React from 'react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { ErrorMessage } from '../ErrorMessage'
import { CSSProp, FlattenSimpleInterpolation } from 'styled-components'
import { Label } from '../Label'
import { TextInputNew } from './TextInputNew'
import { InfoBox } from '../InfoBox'
import { ColorVariants } from '../../constants'
import { Stack } from '../Spacing'

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode
  labelCss?: CSSProp<any>
  hasError?: boolean
  width?: string
  height?: string
  errorMessage?: string
  isSmall?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  rightInnerElement?: React.FC
  leftInnerElement?: React.FC
  fullWidth?: boolean
  isNumeric?: boolean
  isAmount?: boolean
  isAmountWithUnlimitedDigitsAfterDot?: boolean
  isNew?: boolean
  labelIcon?: React.FC
  infoMessage?: string
  infoMessageVariant?: ColorVariants
  onInputClick?: () => void
  styles?: FlattenSimpleInterpolation
}

const Wrapper = styled.div<TextInputProps>`
  ${({ isSmall }: TextInputProps) => isSmall && tw`text-sm`}
  ${({ width }) =>
    !!width &&
    css`
      width: ${width};
    `}
`

interface RightInnerElementWrapperProps {
  rightInnerElementWidth?: number | null
}

const RightInnerElementWrapper = styled.div<RightInnerElementWrapperProps>`
  ${tw`flex items-center`}
  ${({ rightInnerElementWidth }: RightInnerElementWrapperProps) =>
    !!rightInnerElementWidth
      ? css`
          margin-left: -${rightInnerElementWidth}px;
        `
      : ``}
`

interface LeftInnerElementWrapperProps {
  leftInnerElementWidth?: number | null
}

const LeftInnerElementWrapper = styled.div<LeftInnerElementWrapperProps>`
  ${tw`flex items-center`}
  ${({ leftInnerElementWidth }: LeftInnerElementWrapperProps) =>
    !!leftInnerElementWidth
      ? css`
          margin-right: -${leftInnerElementWidth}px;
          z-index: 1000;
          padding-right: 10px;
          padding-left: 10px;
        `
      : ``}
`

interface StyledInputProps extends TextInputProps {
  rightInnerElementWidth?: number | null
  leftInnerElementWidth?: number | null
}
export const StyledInput = styled.input<StyledInputProps>`
  ${tw`bg-white flex-grow p-2 text-sm rounded-lg focus:outline-none focus:border-outlineBlue border-0 box-border ml-[1px]
  disabled:bg-lightGrey disabled:text-menuGrey disabled:opacity-75 w-full`}
  ${({ hasError }) => (hasError ? tw`shadow-inputShadowError` : tw`shadow-inputShadow`)}
  ${({ rightInnerElementWidth }) =>
    rightInnerElementWidth &&
    css`
      padding-right: ${rightInnerElementWidth}px;
    `}
    ${({ leftInnerElementWidth }) =>
    leftInnerElementWidth &&
    css`
      padding-left: ${leftInnerElementWidth}px;
    `}
  ${({ isSmall }) => isSmall && tw`text-sm h-[30px]`}
  ${({ readOnly }) => (readOnly ? tw`hover:cursor-pointer text-brightOrange` : undefined)}
`

export const InputWrapper = tw.div`inline-flex flex-row w-full`

export const TextInput = ({
  fullWidth,
  width,
  height,
  label,
  hasError,
  errorMessage,
  className,
  labelCss,
  isSmall,
  inputRef,
  rightInnerElement: RightInnerElement,
  leftInnerElement: LeftInnerElement,
  isNumeric,
  isAmount,
  isAmountWithUnlimitedDigitsAfterDot,
  isNew,
  labelIcon,
  infoMessageVariant,
  infoMessage,
  onInputClick,
  styles,
  ...props
}: TextInputProps) => {
  const rightInnerElementRef = useRef<HTMLDivElement>(null)
  const [rightInputPaddingWidth, setRightInputPaddingWidth] = useState<number | null>(null)
  useEffect(() => {
    if (!!rightInnerElementRef?.current) {
      setRightInputPaddingWidth(rightInnerElementRef.current.getBoundingClientRect().width)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightInnerElementRef.current, RightInnerElement])

  const leftInnerElementRef = useRef<HTMLDivElement>(null)
  const [leftInputPaddingWidth, setLeftInputPaddingWidth] = useState<number | null>(null)
  useLayoutEffect(() => {
    if (!!leftInnerElementRef?.current) {
      setLeftInputPaddingWidth(leftInnerElementRef.current.getBoundingClientRect().width)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftInnerElementRef.current, LeftInnerElement])

  if (isNew) {
    return (
      <TextInputNew
        {...props}
        label={label}
        errorMessage={errorMessage}
        infoMessageVariant={infoMessageVariant}
        width={width}
        height={height}
        infoMessage={infoMessage}
        isNumeric={isNumeric}
        isAmount={isAmount}
        rightInnerElement={RightInnerElement}
        leftInnerElement={LeftInnerElement}
        onInputClick={onInputClick}
        isAmountWithUnlimitedDigitsAfterDot={isAmountWithUnlimitedDigitsAfterDot}
        styles={styles}
      />
    )
  }
  return (
    <Wrapper
      className={className}
      isSmall={isSmall}
      width={fullWidth ? '100%' : width}
      onClick={() => {}}
    >
      {label && (
        <Label icon={labelIcon} width={fullWidth ? '100%' : width} labelStyle={labelCss}>
          {label}
        </Label>
      )}
      <Stack>
        <InputWrapper>
          {!!LeftInnerElement ? (
            <LeftInnerElementWrapper
              ref={leftInnerElementRef}
              leftInnerElementWidth={leftInputPaddingWidth}
            >
              <LeftInnerElement />
            </LeftInnerElementWrapper>
          ) : null}

          <StyledInput
            {...props}
            ref={inputRef}
            type="text"
            hasError={hasError || !!errorMessage}
            leftInnerElementWidth={leftInputPaddingWidth}
            rightInnerElementWidth={rightInputPaddingWidth}
            onKeyPress={(event) => {
              if (isNumeric) {
                const char = event.key
                if (!/^\d+$/.test(char)) {
                  event.preventDefault()
                }
              }

              // TODO: check if this regex is actually working (noticed that there are some issues with it in UI).
              //  If it doesn't, substitute with isNumericalAmountForInput function from utils.
              if (isAmount) {
                if (!/^\d+(\.\d{0,2})?$/.test(`${props.value}` + event.key)) {
                  event.preventDefault()
                }
              }
            }}
          />

          {!!RightInnerElement ? (
            <RightInnerElementWrapper
              ref={rightInnerElementRef}
              rightInnerElementWidth={rightInputPaddingWidth}
            >
              <RightInnerElement />
            </RightInnerElementWrapper>
          ) : null}
        </InputWrapper>
        {infoMessage && <InfoBox message={infoMessage} type={ColorVariants.Info} />}
        {errorMessage && <ErrorMessage error={errorMessage} isFieldError />}
      </Stack>
    </Wrapper>
  )
}
