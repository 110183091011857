import {
  PaymentTransactionInput,
  ZevoyPaymentTransactionType,
} from '../../../GeneratedGraphQLTypes'
import { EnfuceDateTypes, EnfuceTextMandatoryTypes, ZevoyTypeOptions } from './constants'

function validateTransactionEnfuceText(enfuceText: string, zevoyType: ZevoyPaymentTransactionType) {
  if (EnfuceTextMandatoryTypes.includes(zevoyType) && !enfuceText) {
    return 'enfuceText is required for this transaction type'
  }
  if (
    zevoyType === ZevoyPaymentTransactionType.CustomerPayment &&
    !enfuceText.startsWith('Payment 20')
  ) {
    return 'enfuceText must start with "Payment 20" for this transaction type'
  }
  if (
    zevoyType === ZevoyPaymentTransactionType.CustomerCashback ||
    zevoyType === ZevoyPaymentTransactionType.CustomerCashbackReversal
  ) {
    if (enfuceText !== 'Cashback') {
      return 'enfuceText must be "Cashback" for this transaction type'
    }
  }
  if (
    zevoyType === ZevoyPaymentTransactionType.RedemptionAccountFunds ||
    zevoyType === ZevoyPaymentTransactionType.RedemptionAccountFundsReversal
  ) {
    if (enfuceText !== 'Redemption of account funds') {
      return 'enfuceText must be "Redemption of account funds" for this transaction type'
    }
  }
  return null
}

export function validatePaymentTransaction(transaction: PaymentTransactionInput) {
  if (!transaction.zevoyType) {
    return 'zevoyType is required'
  }
  if (!transaction.organizationId) {
    return 'organizationId is required'
  }
  if (!transaction.enfuceAccountId) {
    return 'enfuceAccountId is required'
  }
  if (transaction.zevoyType !== ZevoyPaymentTransactionType.AccountFeeReversal) {
    if (!transaction.amount) {
      return 'amount is required for this transaction type'
    }
    if (!transaction.currency) {
      return 'currency is required for this transaction type'
    }
  }
  if (EnfuceDateTypes.includes(transaction.zevoyType) && !transaction.enfuceDate) {
    return 'enfuceDate is required for this transaction type'
  }
  if (
    transaction.zevoyType === ZevoyPaymentTransactionType.AccountFeeReversal &&
    !transaction.enfuceTransactionId
  ) {
    return 'enfuceTransactionId is required for AccountFeeReversal'
  }
  return validateTransactionEnfuceText(transaction.enfuceText ?? '', transaction.zevoyType)
}

export function validatePaymentTransactions(transactions: PaymentTransactionInput[]) {
  transactions.forEach((transaction, index) => {
    const error = validatePaymentTransaction(transaction)
    if (error) {
      throw new Error(`row: ${index + 1}, error: ${error}`)
    }
  })
}

export function getReadableZevoyType(zevoyType: ZevoyPaymentTransactionType) {
  return ZevoyTypeOptions.find((option) => option.id === zevoyType)?.name ?? zevoyType
}
