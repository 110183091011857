import create from 'zustand'
import { PlanCondition } from '../../GeneratedGraphQLTypes'
import { getDefaultPlan } from './utils'

export interface PlanConditionsState {
  error: string
  planConditions: PlanCondition[]
  setPlanConditions: (plans: PlanCondition[]) => void
  updatePlanCondition: (startDate: Date, newChunk: Partial<PlanCondition>) => void
  deletePlanConditions: (startDate: Date) => void
  resetPlanConditions: () => void
  setError: (error: string) => void
}

export const usePlanConditionsState = create<PlanConditionsState>((set, get) => ({
  error: '',
  planConditions: [],
  setPlanConditions: (plans: PlanCondition[]) => set({ planConditions: plans }),
  updatePlanCondition: (startDate: Date, newChunk: Partial<PlanCondition>) => {
    const currentPlans = get().planConditions
    const updatedPlans = currentPlans.map((plan) => {
      if (plan.startDate.getTime() === startDate.getTime()) {
        return { ...plan, ...newChunk }
      }
      return plan
    })
    set({ planConditions: updatedPlans })
  },
  deletePlanConditions: (startDate: Date) => {
    const currentPlans = get().planConditions
    const updatedPlans = currentPlans.filter(
      (plan) => plan.startDate.getTime() !== startDate.getTime(),
    )
    set({ planConditions: updatedPlans })
  },
  resetPlanConditions: () => {
    set({ planConditions: [getDefaultPlan('FI')] })
  },
  setError: (error: string) => set({ error }),
}))
