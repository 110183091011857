import { gql } from '@apollo/client'
import { ORG_NOTE } from '../Organizations/Note/queries'

export const updateProspect = gql`
  mutation updateProspect($input: UpdateProspectInput!) {
    updateProspect(input: $input) {
      success
    }
  }
`

export const getProspects = gql`
  query getProspects($nextCursor: ID) {
    prospects(input: { from: $nextCursor, limit: 100 }) {
      totalCount
      edges {
        cursor
        edge {
          id
          name
          businessID
          country
          salesRep {
            id
            fullName
          }
          plan {
            organization {
              id
            }
            type
            startTime
            additionalTerms
            comments
          }
          status
          createTime
          KYCSessions {
            token
            createdAt
            organization {
              employeeRange {
                range
              }
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        nextCursor
        size
        hasNextPage
      }
    }
  }
`

export const getProspect = gql`
  ${ORG_NOTE}
  query getProspect($id: OrganizationID!) {
    organization(id: $id) {
      id
      name
      businessID
      country
      salesRep {
        id
        fullName
      }
      onboardingFee
      onboardingFeeBillingDate
      plan {
        organization {
          id
        }
        type
        startTime
        additionalTerms
        comments
        conditions {
          startDate
          type
          amountPerUser
          amountPerBusinessCardUser
          amountPerClaimUser
          amountPerSubscription
          minimumBillingFee
          fixedFee
          freeClaimUsers
          freeBusinessCardUsers
          freeSubscriptionCards
          freeClaimForBusinessCardUser
        }
      }
      employeeRange {
        range
      }
      status
      KYCSessions {
        token
        createdAt
      }
      note {
        ...orgNote
      }
    }
  }
`
export const openKycSession = gql`
  mutation openKycSession($id: OrganizationID!) {
    openKYCSession(id: $id) {
      session {
        token
      }
    }
  }
`
export const updateOnboardingFee = gql`
  mutation updateOnboardingFee($id: OrganizationID!, $fee: MonetaryAmount!, $date: Date!) {
    updateOnboardingFee(id: $id, fee: $fee, date: $date) {
      success
    }
  }
`

export const deleteOnboardingFee = gql`
  mutation deleteOnboardingFee($id: OrganizationID!) {
    deleteOnboardingFee(id: $id) {
      success
    }
  }
`
