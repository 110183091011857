import {
  CashbackInput,
  Plan,
  PlanInput,
  useSetOrganizationPlanMutation,
  useUpdatePlanConditionsMutation,
} from '../GeneratedGraphQLTypes'
import { DateTime } from 'luxon'

import { convertUTCWithoutChangingTime, useLoadingToast } from './utils'
import { usePlanConditionsState } from '../components/Plans/store'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useOrganizationDetailsStore } from '../components/Organizations/OrganizationDetails/store'
import { Organization } from '../GeneratedGraphQLTypes'

export function useRequestPlanUpdate(isProspects: boolean) {
  const [updatePlanConditions] = useUpdatePlanConditionsMutation()
  const { planConditions, setError } = usePlanConditionsState()
  const [loading, setLoading] = useState(false)

  const updatePlans = async (organizationId: string) => {
    try {
      if (!planConditions.length) {
        setError('Please add at least one plan.')
        toast.error('Failed to save!')
        return
      }
      if (!organizationId) {
        setError('No organization id.')
        toast.error('Failed to save!')
        return
      }

      const firstDayOfNextMonth = DateTime.now().plus({ months: 1 }).startOf('month').toISODate() // Add 1 month and set to start of the month
      const firstStartDateInPlanConditions = DateTime.fromJSDate(
        new Date(planConditions[0].startDate),
      ).toISODate()

      if (isProspects && firstDayOfNextMonth !== firstStartDateInPlanConditions) {
        setError('The first plan starting date should be next month')
        toast.error('Failed to save!')
        return
      }

      await updatePlanConditions({
        variables: {
          id: organizationId,
          conditions: planConditions.map((plan) => ({
            ...plan,
            startDate: convertUTCWithoutChangingTime(plan.startDate),
          })),
        },
      })
      toast.success('Plans are successfully updated!')
    } catch (e) {
      const err: Error = e as Error
      setError(err?.message ?? e)
      toast.error('Failed to save!')
    } finally {
      setLoading(false)
    }
  }

  return { updatePlans, loading }
}

export function useRequestCommentsUpdate() {
  const [setAdvancedOrganizationPlan, { loading }] = useSetOrganizationPlanMutation()
  const { setOrganization, organization } = useOrganizationDetailsStore()
  useLoadingToast(loading)

  const updateComments = async (comments: string, organizationId: string, currentPlan: Plan) => {
    if (!currentPlan) {
      console.error('No plan found. Cannot update comments.')
      toast.error('Failed to save!')
    }
    const { additionalTerms, conditions, startTime, type, fxFee, cashback } = currentPlan
    const planInput: PlanInput = {
      comments,
      additionalTerms,
      conditions,
      organizationID: currentPlan.organization.id,
      startTime,
      type,
      fxFee,
      cashback,
    }
    setAdvancedOrganizationPlan({
      variables: {
        organizationId,
        input: planInput,
      },
      onCompleted: () => {
        toast.success('Comments are successfully saved!')
        const updatedOrg = {
          ...organization,
          plan: {
            ...organization?.plan,
            comments,
          },
        }
        setOrganization(updatedOrg as Organization)
      },
      onError: (error) => {
        console.error(error)
        toast.error('Failed to save!')
      },
    })
  }

  return { updateComments, loading }
}

export function useRequestAdditionalTermsUpdate() {
  const [setAdvancedOrganizationPlan, { loading }] = useSetOrganizationPlanMutation()
  const { setOrganization, organization } = useOrganizationDetailsStore()

  useLoadingToast(loading)

  const updateAdditionalTerms = async (
    additionalTerms: string,
    organizationId: string,
    currentPlan: Plan,
  ) => {
    if (!currentPlan) {
      console.error('No plan found. Cannot update additional terms.')
      toast.error('Failed to save!')
    }
    const { comments, conditions, startTime, type, fxFee, cashback } = currentPlan
    const planInput: PlanInput = {
      comments,
      additionalTerms,
      conditions,
      organizationID: currentPlan.organization.id,
      startTime,
      type,
      fxFee,
      cashback,
    }

    setAdvancedOrganizationPlan({
      variables: {
        organizationId,
        input: planInput,
      },
      onCompleted: () => {
        toast.success('Additional terms are successfully saved!')
        const updatedOrg = {
          ...organization,
          plan: {
            ...organization?.plan,
            additionalTerms,
          },
        }
        setOrganization(updatedOrg as Organization)
      },
      onError: (error) => {
        console.error(error)
        toast.error('Failed to save!')
      },
    })
  }

  return { updateAdditionalTerms, loading }
}

export function useRequestFxFeeUpdate() {
  const [setAdvancedOrganizationPlan, { loading }] = useSetOrganizationPlanMutation()
  const { setOrganization, organization } = useOrganizationDetailsStore()

  useLoadingToast(loading)

  const updateFXFee = async (fxFee: boolean, organizationId: string, currentPlan: Plan) => {
    if (!currentPlan) {
      console.error('No plan found. Cannot update additional terms.')
      toast.error('Failed to save!')
    }
    const { comments, conditions, startTime, type, additionalTerms, cashback } = currentPlan
    const planInput: PlanInput = {
      comments,
      additionalTerms,
      conditions,
      organizationID: currentPlan.organization.id,
      startTime,
      type,
      fxFee,
      cashback,
    }
    setAdvancedOrganizationPlan({
      variables: {
        organizationId,
        input: planInput,
      },
      onCompleted: () => {
        toast.success('FX fee setting is successfully saved!')
        const updatedOrg = {
          ...organization,
          plan: {
            ...organization?.plan,
            fxFee,
          },
        }
        setOrganization(updatedOrg as Organization)
      },
      onError: (error) => {
        console.error(error)
        toast.error('Failed to save!')
      },
    })
  }

  return { updateFXFee, loading }
}

export function useRequestCashbackUpdate() {
  const [setAdvancedOrganizationPlan, { loading }] = useSetOrganizationPlanMutation()
  const { setOrganization, organization } = useOrganizationDetailsStore()

  useLoadingToast(loading)

  const updateCashback = async (
    cashback: CashbackInput,
    organizationId: string,
    currentPlan: Plan,
  ) => {
    if (!currentPlan) {
      console.error('No plan found. Cannot update cashback.')
      toast.error('Failed to save!')
    }
    const { comments, conditions, startTime, type, additionalTerms, fxFee } = currentPlan
    const planInput: PlanInput = {
      comments,
      additionalTerms,
      conditions,
      organizationID: currentPlan.organization.id,
      startTime,
      type,
      fxFee,
      cashback,
    }
    setAdvancedOrganizationPlan({
      variables: {
        organizationId,
        input: planInput,
      },
      onCompleted: () => {
        toast.success('Cashback setting is successfully saved!')
        const updatedOrg = {
          ...organization,
          plan: {
            ...organization?.plan,
            cashback,
          },
        }
        setOrganization(updatedOrg as Organization)
      },
      onError: (error) => {
        console.error(error)
        toast.error('Failed to save!')
      },
    })
  }

  return { updateCashback, loading }
}
