import React, { Suspense } from 'react'
import { Navigate, useRoutes, RouteObject } from 'react-router-dom'

import HomeScreen from '../../components/Home'
import { OrganizationDetails } from '../../components/Organizations/OrganizationDetails'
import CreditPrompt from '../../components/CreditApplication/CreditPrompt'
import CreditDetails from '../../components/CreditApplication/CreditDetails'
import VirtualWalletScreen from '../../components/VirtualWallet'
import { ProspectDetails } from '../../components/Prospects/components/ProspectCard/ProspectDetails'

import Login from '../../components/Login'
import ErrorScreen from '../../components/Error'

import PrivateRoute from './PrivateRoute'

import AuthRedirect from '../Redirect'

import { isAuthenticated } from '../auth'
import useStore from '../coreStore'
import errorStore from '../../components/Error/store'
import { ReactAdminApp } from '../../components/ReactAdmin'
import { Loading } from '../styles'

const DefaultPage = () => {
  const { loginProgress } = useStore()
  return loginProgress || isAuthenticated() ? (
    <Navigate to="/admin/prospect" replace />
  ) : (
    <Navigate to="/login" replace />
  )
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <DefaultPage />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/redirect',
    element: <AuthRedirect />,
  },
  {
    path: '/home',
    element: (
      <PrivateRoute>
        <HomeScreen />
      </PrivateRoute>
    ),
  },
  {
    path: '/prospect/:id/details',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <ProspectDetails />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/organization/:id/details/*',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <OrganizationDetails />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/credit-applications/:id/details',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <CreditDetails />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/credit-applications/:id/details/prompt',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <CreditPrompt />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/virtual-wallet',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <VirtualWalletScreen />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/admin/*',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <ReactAdminApp />
        </Suspense>
      </PrivateRoute>
    ),
  },
]
const Router = () => {
  return useRoutes(routes)
}
export const RouterWrapper = () => {
  const { error } = errorStore()
  return error ? <ErrorScreen /> : <Router />
}
