import React, { HTMLProps, forwardRef, useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { DateTime } from 'luxon'
import { Checkbox, CheckboxWrapper, ModalInputLabel } from '../../../lib/styles'
import { InputWrapper } from '../../CreditApplication/styles'
import { CountryKey, CurrencySigns } from '@zevoy/common/src/types/Generic'
import { TextInput } from '@zevoy/common/src/components/TextInput'
import DatePicker from 'react-datepicker'
import {
  addOneMonthToDate,
  convertUTCWithoutChangingTime,
  roundDateToMonth,
} from '../../../lib/utils'
import { DatePickerButton } from '../../Plans/styles'
import { ErrorMessage } from '@zevoy/common/src/components/ErrorMessage'

const OnboardingFeeCheckboxWrapper = tw(CheckboxWrapper)`items-center gap-2`
const OnboardingFeeWrapper = tw.div`w-full flex gap-6 self-start justify-start items-start`
const OnboardingFeeInputWrapper = tw(InputWrapper)`text-left`
const OnboardingFeeInput = tw(TextInput)`w-[80px] font-slussenLight`
const CurrencyLabel = tw.div`mx-2`

interface OnboardingFeeProps {
  onboardingFee: string | null
  onboardingFeeBillingDate: Date | null
  setOnboardingFee: (onboardingFee: string | null) => void
  setOnboardingFeeBillingDate: (date: Date | null) => void
  country: CountryKey
}
export const OnboardingFeeComponent = ({
  onboardingFee,
  onboardingFeeBillingDate,
  setOnboardingFee,
  setOnboardingFeeBillingDate,
  country,
}: OnboardingFeeProps) => {
  const [isInputShown, setIsInputShown] = useState(onboardingFee !== null)
  const [wasCheckboxClicked, setWasCheckboxCLicked] = useState(false)
  const [error, setError] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const checkboxActive = onboardingFee !== null
    setIsInputShown(checkboxActive)

    if (checkboxActive && wasCheckboxClicked && inputRef?.current) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingFee])

  const handleCheckboxClick = () => {
    setWasCheckboxCLicked(true)
  }

  const nextDate = convertUTCWithoutChangingTime(roundDateToMonth(addOneMonthToDate(new Date())))

  return (
    <InputWrapper>
      <OnboardingFeeCheckboxWrapper>
        <Checkbox
          type="checkbox"
          checked={isInputShown}
          onChange={(e) => {
            setIsInputShown(e.target.checked)
            setOnboardingFee(e.target.checked ? '' : null)
            setOnboardingFeeBillingDate(nextDate)
            handleCheckboxClick()
          }}
        />
        <ModalInputLabel>Add onboarding fee</ModalInputLabel>
      </OnboardingFeeCheckboxWrapper>
      {isInputShown && (
        <OnboardingFeeWrapper>
          <OnboardingFeeInputWrapper>
            <ModalInputLabel>Onboarding fee:</ModalInputLabel>
            <OnboardingFeeInput
              inputRef={inputRef}
              value={onboardingFee?.toString() ?? ''}
              onChange={(e) => {
                setOnboardingFee(e.target.value)
              }}
              isSmall
              rightInnerElement={() => <CurrencyLabel>{CurrencySigns[country]}</CurrencyLabel>}
            />
          </OnboardingFeeInputWrapper>
          <BillingDate
            billingDate={onboardingFeeBillingDate}
            setError={setError}
            onChange={(d) => setOnboardingFeeBillingDate(convertUTCWithoutChangingTime(d))}
          />
        </OnboardingFeeWrapper>
      )}
      {error && <ErrorMessage error={error} />}
    </InputWrapper>
  )
}

interface BillingDateProps {
  billingDate: Date | null
  onChange: (date: Date) => void
  setError: (err: string) => void
}
export const BillingDate = ({ billingDate, onChange, setError }: BillingDateProps) => {
  const DatePickerCustom = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>(
    ({ value, onClick }, ref) => (
      <DatePickerButton onClick={onClick} ref={ref}>
        {DateTime.fromFormat(value as string, 'MM/dd/yyyy').toFormat('dd-MM-yyyy')}
      </DatePickerButton>
    ),
  )

  const handleChange = (date: Date) => {
    if (!date) {
      setError('Billing date is required.')
      return
    }
    const dateMonth = roundDateToMonth(date)

    if (dateMonth < new Date()) {
      setError('Billing month should be later than a current month.')
      return
    }
    setError('')
    onChange(dateMonth)
  }
  return (
    <OnboardingFeeInputWrapper>
      <ModalInputLabel>Billing date:</ModalInputLabel>
      <DatePicker
        selected={billingDate}
        onChange={handleChange}
        customInput={<DatePickerCustom />}
        showMonthYearPicker
        onKeyDown={(event) => {
          event.preventDefault()
        }}
      />
    </OnboardingFeeInputWrapper>
  )
}
