import React from 'react'

import { ContentWrapper, ScreenWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import BackButtonArrow from '../../../lib/components/BackButton'
import { useNavigate } from 'react-router-dom'
import { Create, required, SelectInput, SimpleForm, TextInput, SaveButton } from 'react-admin'
import { IncidentMessageType } from '../../../GeneratedGraphQLTypes'
import { messageTypeOptions } from './constants'

// Validation rule for select input
const validateMessageType = required('A message type must be selected')

export const IncidentCommunicationCreate = () => {
  const navigate = useNavigate()

  return (
    <ScreenWrapper>
      <ContentWrapper>
        <Header
          title={'Create new incident message'}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate('/admin/incidentMessage')
              }}
            />
          }
        />

        <Create>
          <SimpleForm toolbar={false}>
            <SelectInput
              source="messageType"
              choices={messageTypeOptions}
              defaultValue={IncidentMessageType.Hub}
              validate={validateMessageType}
            />
            <TextInput
              fullWidth
              multiline
              source="message"
              minRows={4}
              label="Incident message"
              required
            />
            <SaveButton />
          </SimpleForm>
        </Create>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
