import React from 'react'
import { BenefitApplicationTabFilter } from './BenefitApplicationTabFilter'
import tw from 'twin.macro'
import {
  Datagrid,
  ExportButton,
  List,
  TextField,
  TopToolbar,
  RefreshButton,
  FunctionField,
} from 'react-admin'
import { ZevoyList } from '../styles'
import { KycMerchantApplication } from '../../../GeneratedGraphQLTypes'
import { postFilters } from '../Merchant/Filters'

const Header = tw.div`text-[32px] font-slussenSemibold mt-10`
const Label = tw.div`font-slussenSemibold capitalize`

interface LabelComponentProps {
  labelName: string
}

export const ListActions = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  )
}

export const LabelComponent = ({ labelName }: LabelComponentProps) => {
  return <Label>{labelName}</Label>
}

export const BenefitApplicationList = () => {
  return (
    <>
      <Header>Merchant benefit applications</Header>

      <List actions={<ListActions />} filters={postFilters}>
        <ZevoyList>
          <BenefitApplicationTabFilter />
          <RefreshButton />
          <Datagrid bulkActionButtons={false} rowClick={'edit'}>
            <TextField
              source="name"
              sortable={true}
              label={<LabelComponent labelName={'Merchant application name'} />}
            />
            <TextField
              source="createdByUser.fullName"
              sortable={false}
              label={<LabelComponent labelName={'Created by'} />}
            />
            <TextField
              source="contactName"
              sortable={false}
              label={<LabelComponent labelName={'Contact name'} />}
            />
            <TextField
              source="businessID"
              sortable={false}
              label={<LabelComponent labelName={'Business id'} />}
            />
            <FunctionField
              source="locations"
              label={<LabelComponent labelName={'Locations Count'} />}
              render={(record: KycMerchantApplication) =>
                record.locations ? record.locations.length : 0
              }
            />
          </Datagrid>
        </ZevoyList>
      </List>
    </>
  )
}
