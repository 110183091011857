import {
  Datagrid,
  DateField,
  Identifier,
  List,
  TextField,
  TextFieldProps,
  Link,
  useRecordContext,
} from 'react-admin'
import get from 'lodash/get'
import last from 'lodash/last'
import HubInviteButton from '../../../lib/components/HubInviteButton'
import { SalesRepField } from '../Prospect'
import { postFilters } from './Filters'
import { TabFilter } from './TabFilter'
import { ZevoyList } from '../styles'

export const HubButtonField = ({ source }: TextFieldProps) => {
  const record = useRecordContext()
  const value = get(record, source!)
  return <HubInviteButton label={' Hub'} id={value} url={'organization'} />
}

export const CardsLinkField = ({ source }: TextFieldProps) => {
  const record = useRecordContext()
  const value = get(record, source!)
  return (
    <Link
      to={`/admin/card?filter={"organizationID": "${value}"}`}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      cards
    </Link>
  )
}

export const TagsLinkField = ({ source }: TextFieldProps) => {
  const record = useRecordContext()
  const value = get(record, source!)
  return (
    <Link
      to={`/admin/tag?filter={"organizationID": "${value}"}`}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      tags
    </Link>
  )
}

export const FundingTypeField = (_: { label: string }) => {
  const record = useRecordContext()
  const fundingHistory = get(record, 'fundingHistory')
  const funding = last<{ type: string }>(fundingHistory)
  return <>{funding?.type}</>
}

export const OrganizationList = () => {
  return (
    <List filters={postFilters}>
      <ZevoyList>
        <TabFilter />
        <Datagrid
          rowClick={(id: Identifier) => `/organization/${id}/details`}
          bulkActionButtons={false}
        >
          <HubButtonField source="id" label="" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="status" sortable={false} />
          <TextField source="country" sortable={false} />
          <TextField source="plan.type" label="Type" sortable={false} />
          <SalesRepField source="salesRep" label="Sale rep." />
          <DateField source="KYCSessions[0].signedAt" label="Contract date" sortable={false} />
          <FundingTypeField label="Funding type" />
          <CardsLinkField label="Cards" source="id" />
          <TagsLinkField label="Tags" source="id" />
        </Datagrid>
      </ZevoyList>
    </List>
  )
}
