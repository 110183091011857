import {
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  List,
  Link,
  TextField,
  TextFieldProps,
  useRecordContext,
  TopToolbar,
  ExportButton,
  Identifier,
  Button,
} from 'react-admin'
import get from 'lodash/get'
import ContentAdd from '@mui/icons-material/Add'

import KycInviteButton from '../../../lib/components/KycInviteButton'
import { ZevoyList } from '../styles'
import { postFilters } from '../Organization/Filters'
import { OrganizationStatus } from '../../../GeneratedGraphQLTypes'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { CreateProspect } from '../../Prospects/components/ProspectsList/CreatePropspect/CreateProspect'

export const KYCButtonField = ({ source }: TextFieldProps) => {
  const record = useRecordContext()
  const value = get(record, source!)
  return <KycInviteButton label={'KYC'} token={value} />
}

export const SalesRepField = ({ source = 'salesRep' }: { source?: string; label: string }) => {
  const record = useRecordContext()
  const salesRep = get(record, source!)

  if (!salesRep) {
    return null
  }
  const link = `/admin/salesRep/${salesRep.id}/show`
  return (
    <Link to={link} onClick={(e) => e.stopPropagation()}>
      {salesRep.fullName}
    </Link>
  )
}

const ListActions = () => {
  const navigate = useNavigate()
  return (
    <TopToolbar>
      <Button
        startIcon={<ContentAdd />}
        label="New Prospect"
        onClick={() => navigate('/admin/prospect/new')}
      />
      <ExportButton />
    </TopToolbar>
  )
}

export const ProspectList = () => (
  <>
    <List
      filters={postFilters}
      filter={{ statuses: [OrganizationStatus.Prospect] }}
      actions={<ListActions />}
    >
      <ZevoyList>
        <Datagrid
          rowClick={(id: Identifier) => `/prospect/${id}/details`}
          bulkActionButtons={false}
        >
          <KYCButtonField source="KYCSessions[0].token" label="" />
          <TextField source="name" label="Company name" />
          <SalesRepField source="salesRep" label="Sale rep." />
          <TextField source="country" />
          <DateField source="createTime" />
          <DeleteWithConfirmButton label="Archive" />
        </Datagrid>
      </ZevoyList>
    </List>
    <Routes>
      <Route path="/new" element={<CreateProspect />} />
    </Routes>
  </>
)
