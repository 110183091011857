import create from 'zustand'

const cardsStore = create((set) => ({
  cards: [],
  organizationsDropdown: [],
  selectedOrganization: '',

  setCards: (cards) => set({ cards }),

  setOrganizationsDropdown: (organizationsDropdown) =>
    set((state) => ({
      ...state,
      organizationsDropdown,
    })),

  selectOrganization: (selectedOrganization) => set({ selectedOrganization }),
}))

export default cardsStore
