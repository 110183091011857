import { useNotify, useRecordContext } from 'react-admin'
import { Button, FormControl, MenuItem, Select, Typography } from '@mui/material'
import get from 'lodash/get'
import tw from 'twin.macro'
import { useUpdateCardSegmentMutation, CardSegment } from '../../../../GeneratedGraphQLTypes'
import { useState } from 'react'

const SegmentWrapper = tw.div`flex flex-row items-center gap-2 mt-4`
const ButtonWrapper = tw.div`mt-4`

const segments = [
  { id: CardSegment.SegmentA, name: CardSegment.SegmentA },
  { id: CardSegment.SegmentB, name: CardSegment.SegmentB },
  { id: CardSegment.SegmentC, name: CardSegment.SegmentC },
  { id: CardSegment.SegmentD, name: CardSegment.SegmentD },
  { id: CardSegment.SegmentE, name: CardSegment.SegmentE },
  { id: CardSegment.SegmentF, name: CardSegment.SegmentF },
]

export const SegmentUpdate = ({ source }: { source: string; label: string }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [updateCardSegment] = useUpdateCardSegmentMutation()
  const [segment, setSegment] = useState<CardSegment>(get(record, source!))

  const id = get(record, 'id')
  const value = get(record, source!)
  const updateSegment = async () => {
    updateCardSegment({
      variables: {
        cardID: id,
        segment: segment,
      },
      onCompleted: () => {
        notify('Card segment updated successfully', { type: 'success' })
      },
      onError: () => {
        notify('Card segment updated failed', { type: 'error' })
      },
    })
  }
  return (
    <SegmentWrapper>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Typography>Segment</Typography>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={segment}
          onChange={(value) => setSegment(value.target.value as CardSegment)}
          label="Segment"
          autoWidth
        >
          {segments.map((segment) => (
            <MenuItem value={segment.id}>{segment.name}</MenuItem>
          ))}
        </Select>
        <ButtonWrapper>
          <Button disabled={value === segment} variant="contained" onClick={updateSegment}>
            Update
          </Button>
        </ButtonWrapper>
      </FormControl>
    </SegmentWrapper>
  )
}
