import { useEffect } from 'react'
import { useImmer } from 'use-immer'
import tw from 'twin.macro'

import { CardPotential } from './CardPotential'
import { HasClaimUsers } from './HasClaimUsers'
import { SpendPotential } from './SpendPotential'
import { SubscriptionPotential } from './SubscriptionPotential'
import { CountryKey } from '@zevoy/common/src/types/Generic'
import { OrganizationChannelSelection } from './OrganizationChannelSelection'
import { NilableBoolean, NilableNumber } from './types'

export type Note = {
  cardPotential?: NilableNumber
  spendPotential?: NilableNumber
  claimsUsers?: NilableBoolean
  subscriptionPotential?: NilableBoolean
  channelId: number
}

export const DefaultNote: Note = {
  cardPotential: null as NilableNumber,
  spendPotential: null as NilableNumber,
  claimsUsers: null as NilableBoolean,
  subscriptionPotential: null as NilableBoolean,
  channelId: 2,
}

type Props = {
  note: Note | null | undefined
  country: CountryKey
  onChange: (note: Note) => void
}

const NoteWrapper = tw.div`grid grid-cols-2 gap-4 text-left`

export const OrganizationNote = ({ note, country, onChange }: Props) => {
  const [noteState, handleNoteStateChange] = useImmer<Note>(note ?? DefaultNote)

  useEffect(() => {
    handleNoteStateChange(() => note)
  }, [note, handleNoteStateChange])

  useEffect(() => {
    onChange(noteState)
  }, [noteState, onChange])

  return (
    <NoteWrapper>
      <OrganizationChannelSelection
        channelId={noteState.channelId}
        setChannelId={(value) => {
          handleNoteStateChange((draft) => {
            draft.channelId = value
          })
        }}
      />
      <CardPotential
        cardPotential={noteState.cardPotential}
        setCardPotential={(potential) => {
          handleNoteStateChange((draft) => {
            draft.cardPotential = potential
          })
        }}
      />
      <SpendPotential
        country={country}
        spendPotential={noteState.spendPotential}
        setSpendPotential={(potential) => {
          handleNoteStateChange((draft) => {
            draft.spendPotential = potential
          })
        }}
      />
      <HasClaimUsers
        claimsUsers={noteState.claimsUsers}
        setClaimsUsers={(value) => {
          handleNoteStateChange((draft) => {
            draft.claimsUsers = value
          })
        }}
      />
      <SubscriptionPotential
        subscriptionPotential={noteState.subscriptionPotential}
        setSubscriptionPotential={(value) => {
          handleNoteStateChange((draft) => {
            draft.subscriptionPotential = value
          })
        }}
      />
    </NoteWrapper>
  )
}
