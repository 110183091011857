import React from 'react'
import {
  Admin,
  CoreLayoutProps,
  defaultTheme,
  EditGuesser,
  Resource,
  ShowGuesser,
} from 'react-admin'
import { ContentWrapper, ScreenWrapper } from '../../lib/styles'
import Sidebar from '../Sidebar/Sidebar'
import { CreditApplicationList } from './CreditApplication'
import { dataProvider } from './DataProvider'
import { OrganizationList } from './Organization'
import { ProspectList } from './Prospect'
import { SalesrepList } from './SalesRep'
import { CardRequestsList } from './CardRequests'
import { UserEdit, UsersList } from './ZevoyUsers'
import { CardShow, CardsList } from './Cards'
import { TagList, TagValueList } from './Tags'
import {
  IncidentCommunicationList,
  IncidentCommunicationEdit,
  IncidentCommunicationCreate,
} from './IncidentCommunication'
import { BenefitApplicationEdit, BenefitApplicationList } from './BenefitApplication'
import { MerchantList, MerchantEdit } from './Merchant'

import { Route } from 'react-router-dom'
import { AvailableMerchantIDList } from './AvailableMerchantID'
import {
  PaymentBatchEdit,
  PaymentBatchList,
  PaymentBatchCreate,
  PaymentTransactionList,
  PaymentTransactionCreate,
  PaymentTransactionEdit,
  PaymentTransactionShow,
} from './Payment'

const DefaultLayout = ({ children }: CoreLayoutProps) => <>{children}</>

const myTheme = {
  ...defaultTheme,
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Slussen-Light'].join(','),
  },
  components: {
    ...defaultTheme.components,
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },
  },
}

export function ReactAdminApp() {
  return (
    <ScreenWrapper>
      <Sidebar />
      <ContentWrapper id="scrollable">
        <Admin basename="/admin" theme={myTheme} layout={DefaultLayout} dataProvider={dataProvider}>
          <Resource name="prospect" list={ProspectList} />
          <Resource name="salesRep" list={SalesrepList} show={ShowGuesser} edit={EditGuesser} />
          <Resource name="organization" list={OrganizationList} />
          <Resource name="cardRequest" list={CardRequestsList} />
          <Resource name="card" list={CardsList} show={CardShow} />
          <Resource name="tag" list={TagList} />
          <Resource name="tagValue" list={TagValueList} />
          <Resource name="creditApplication" list={CreditApplicationList} />
          <Resource name="user" list={UsersList} edit={UserEdit} />
          <Resource
            name="incidentMessage"
            list={IncidentCommunicationList}
            edit={IncidentCommunicationEdit}
            create={IncidentCommunicationCreate}
          />
          <Resource
            name="benefitApplication"
            list={BenefitApplicationList}
            edit={BenefitApplicationEdit}
          >
            <Route
              path={'/:applicationID/available-merchant-id'}
              element={<AvailableMerchantIDList />}
            />
          </Resource>
          <Resource name="benefitCategory" />
          <Resource name="merchant" list={MerchantList} edit={MerchantEdit} />
          <Resource
            name="paymentBatch"
            list={PaymentBatchList}
            create={PaymentBatchCreate}
            edit={PaymentBatchEdit}
          />
          <Resource
            name="paymentTransaction"
            list={PaymentTransactionList}
            create={PaymentTransactionCreate}
            edit={PaymentTransactionEdit}
            show={PaymentTransactionShow}
          />
        </Admin>
      </ContentWrapper>
    </ScreenWrapper>
  )
}
