import tw, { styled } from 'twin.macro'

export const InfoWrapper = tw.div`flex flex-col w-full items-start px-5 space-y-5 pt-2 w-full xl:w-2/5`
export const InfoHeader = tw.div`font-slussenSemibold border-b-2 border-opacity-25 w-full flex flex-row justify-start items-center text-sm pb-3`
export const ButtonsSection = tw.div`font-slussenSemibold flex flex-row justify-start items-center`

export const InputWrapper = tw.div`flex flex-col py-1 w-full space-y-3`
export const InputRow = tw.div`flex flex-row w-full space-x-4`
export const InputLabel = tw.label`font-slussenSemibold text-sm text-labelsGrey`
export const ModalInputWrapper = tw.div`flex flex-col py-1 w-full space-y-3`
export const ModalInputWrapperMedium = tw.div`flex flex-col py-1 w-2/3 space-y-3`
export const ModalInputWrapperSmall = tw.div`flex flex-col py-1 w-1/4 space-y-3`
export const PromptInfo = tw.div`font-slussenLight text-sm flex space-y-3 pt-4 pb-4`

export const Button = tw.button`
  justify-self-end bg-white text-sm
  hover:opacity-75 shadow-heavy py-0 px-6 rounded-full border-0
`
export const SaveButton = tw(
  Button,
)`font-slussenSemibold text-sm text-white bg-greyBlue bg-opacity-25 py-2`
export const CancelButton = tw(
  Button,
)`font-slussenSemibold text-sm text-greyBlue bg-white bg-opacity-25 py-2 border-0 shadow-sm`

export const CreditHeaderWrapper = tw.div`flex-grow flex flex-row pt-4 text-xs font-slussenLight`
export const CreditHeaderName = tw.div`w-3/4 font-slussenSemibold pl-12`
export const CreditHeaderLabel = tw(CreditHeaderName)`opacity-50 pl-3`

export const CreditPickerWrapper = tw.div`flex flex-row justify-start pl-6 py-4 space-x-4 justify-start items-center text-sm`
export const CreditFilterOptions = styled.section(({ selected }) => [
  tw`flex flex-row text-menuGrey opacity-50 space-x-2 py-2 hover:bg-lightGrey cursor-default justify-center items-center font-slussenSemibold`,
  selected && tw`font-slussenSemibold border-b-2 border-menuGrey opacity-100`,
])

export const CreditListRow = tw(CreditHeaderWrapper)`
  hover:shadow-heavy rounded-xl py-2 text-sm
`
export const CreditListWrapper = tw.div`
  flex flex-col space-y-2 pt-6
`
// Details

export const DetailsScreenWrapper = tw.section`flex bg-white`
export const DetailsHeader = tw.div`font-slussenSemibold border-b-2 border-opacity-25 w-full flex flex-row justify-start items-center text-base`
export const DetailsWrapper = tw.div`w-full pt-4 h-full pb-4`
export const AdditionalInfoText = tw.div`font-slussenLight text-sm flex-grow w-110`
export const DetailsLabel = tw.div`flex justify-start font-slussenLight text-sm text-darkGrey opacity-50`
export const DetailsLabelCurrency = tw.div`flex font-slussenLight text-sm text-darkGrey opacity-50 justify-end pl-24`
export const DetailsInputWrapper = tw.div`flex flex-col py-1 w-110 space-y-3`
// export const DetailsTextArea = tw.textarea`bg-white font-slussenLight p-2 text-sm shadow-heavy h-24 border-0 w-100`
export const DetailsInviteWrapper = tw.div`flex flex-row bg-white pt-2 pb-4 pl-2 text-sm shadow-heavy rounded-lg focus:outline-none border-0 w-100 items-center`
export const DetailsInviteHeading = tw.div`flex justify-start font-slussenSemibold text-base text-black pt-2 pb-2 pl-2`
export const DetailsInviteText = tw.div`flex justify-start font-slussenLight text-sm text-labelsGrey pl-2`
export const DetailsInviteButton = tw.div`font-slussenSemibold text-sm bg-brightOrange bg-opacity-10 text-brightOrange text-opacity-80 rounded flex justify-center items-center ml-2 mr-2 mt-2 p-1`
export const DetailsInviteCol = tw.div`flex flex-col justify-start items-start w-3/4`
export const DetailsInviteButtonRow = tw.div`flex-row w-full justify-end items-end`
export const DetailsInviteButtonWrapper = tw.div`px-6 h-full`
export const DetailsChevronWrapper = tw.div`flex justify-center items-center mr-2 mt-2 p-1`
export const DetailsUnderline = tw.div`w-110 shadow-heavy`
export const DetailsTwoButtonWrapper = tw.div`
  flex flex-row pt-2 pb-0 space-x-3 items-center w-110 justify-center
`
export const DetailsDropzoneWrapper = tw.div`w-96 justify-center items-center bg-lightGrey rounded-lg font-slussenLight p-10`
export const DetailsCurrencyWrapper = tw.div`w-110 flex flex-row space-x-20`
export const DetailsCurrencyHeader = tw.div`w-110 flex flex-row space-x-24`
export const DetailsTextArea = styled.textarea(({ disabled }) => [
  tw`bg-white font-slussenLight p-2 text-sm shadow-heavy h-24 border-0 w-100`,
  disabled && tw`bg-lightGrey`,
])
export const DetailsFileItem = tw.div`flex w-full bg-lightBlue pt-4 pb-4 rounded-lg`
export const DetailsFileItemName = tw.div`flex w-full font-slussenSemibold pl-4 text-sm cursor-pointer`
export const DetailsFileItemTrash = tw.div`flex w-full justify-end items-end pr-4`
export const DetailsFileItemLink = tw.div`flex justify-start items-start pl-4`
