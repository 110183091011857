import geonamesJson from '../lib/assets/geonames/countryInfoJSON.json'
import organizationsStore from '../components/Organizations/store'
import { PageInfo } from '../GeneratedGraphQLTypes'
import { DateTime } from 'luxon'
import { Option } from '@zevoy/common/src/types/Generic'
import { countriesOptions } from '@zevoy/common/src/constants'
import { SalesRepresentatives } from '../components/Prospects/types'
import { useEffect, useState } from 'react'
import { Id, toast } from 'react-toastify'

// Function for trimming long strings
export const trimString = (string: string, length: number) => {
  return string.slice(0, length) + (string.length > length ? '...' : '')
}

// Function for filtering unique items by id
export const uniqueItems = (items: any[]) => {
  return Array.from(
    items.reduce((map: Map<any, any>, object) => map.set(object.id, object), new Map()).values(),
  )
}

export const getCurrencyForCountry = (iso2CountryCode: string) => {
  const { geonames } = geonamesJson
  const country = geonames.find((geoname) => geoname.countryCode === iso2CountryCode)
  return country?.currencyCode
}

export const getSessionStorageItem = (key: string) => {
  const item = sessionStorage.getItem(key)

  try {
    return item === null ? item : JSON.parse(item)
  } catch {
    return null
  }
}

export const setSessionStorageItem = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const setOrganizationListCursor = (pageInfo: PageInfo) => {
  if (!pageInfo) {
    return
  }

  const { setNextCursor, setNextPage } = organizationsStore()
  setNextPage(pageInfo.hasNextPage)
  setNextCursor(pageInfo.nextCursor)
}

export const generateId = () => {
  return Math.random().toString(36).substr(2, 9)
}

export const addOneMonthToDate = (date: Date) => {
  const newDate = new Date(date)
  newDate.setMonth(newDate.getMonth() + 1)
  return newDate
}

export const roundDateToMonth = (date: Date) => {
  const roundedDate = DateTime.fromJSDate(date).set({
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  return roundedDate.toJSDate()
}

export const convertUTCWithoutChangingTime = (date: Date) => {
  const newDate = new Date(date)

  newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset())
  return DateTime.fromJSDate(newDate, { zone: 'utc' }).toJSDate()
}

export const getSalesRepOptions = (salesReps: SalesRepresentatives): Option[] => {
  if (!salesReps) {
    return []
  }
  return salesReps.map((salesRep) => ({
    label: salesRep?.fullName ?? '',
    value: salesRep?.id ?? '',
  }))
}

export const isFutureDate = (date: Date) => {
  const now = new Date()
  return date > now
}

export const formatMoneyValue = (num: number | undefined | null) => {
  return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export const countries = countriesOptions.map((country) => {
  return { id: country.code, name: `${country.icon} ${country.text}` }
})

export function useLoadingToast(loading: boolean, caption?: string) {
  const [loadingToastId, setLoadingToastId] = useState<Id | null>(null)

  useEffect(() => {
    if (loading) {
      const toastId: Id = toast.loading(caption ?? 'loading')
      setLoadingToastId(toastId)
      return
    }

    if (loadingToastId) {
      toast.dismiss(loadingToastId as Id)
      setLoadingToastId(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
}
