import React from 'react'
import { DetailsHeader, DetailsInputWrapper, DetailsUnderline } from '../styles'
import { UboTable } from './UboTable'
import { sortCollectionByPropertyInAlphabeticalOrder } from '@zevoy/common/src/utils/utils'

export const UboList = ({ KYCSessions }) => {
  if (!KYCSessions) {
    return null
  }

  const unsortedUbos = KYCSessions.reduce((acc, KYCSession) => {
    if (!KYCSession?.hasUBOs) {
      return acc
    }

    return [...acc, ...(KYCSession?.ultimateBenefialOwners ?? [])]
  }, [])

  const ubos = sortCollectionByPropertyInAlphabeticalOrder(unsortedUbos, 'fullName')

  if (!ubos?.length) {
    return null
  }

  return (
    <DetailsInputWrapper>
      <DetailsHeader>Ultimate Beneficial Owners</DetailsHeader>
      <DetailsUnderline />
      <UboTable ubos={ubos} />
    </DetailsInputWrapper>
  )
}
