import React from 'react'
import { CopyButton, CopyButtonContainer, ErrorFrame } from './styled'
import { ReactComponent as Copy } from '../../../assets/icons/copy.svg'

export const ErrorFrameComponent = ({
  errorObject,
  label,
  onCopyClick,
}: {
  errorObject: any
  label?: string
  onCopyClick?: () => void
}) => {
  return (
    <ErrorFrame>
      {label && onCopyClick && (
        <CopyButtonContainer>
          <CopyButton onClick={onCopyClick}>
            {'Copy to clipboard'}
            <Copy />
          </CopyButton>
        </CopyButtonContainer>
      )}

      <pre>{JSON.stringify(errorObject, null, 2)}</pre>
    </ErrorFrame>
  )
}
