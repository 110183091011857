import { gql } from '@apollo/client'
import { client, authQuery } from './apolloClient'

export const getRefreshToken = async (code: string) => {
  return client.mutate({
    mutation: gql`
    mutation getRefreshToken {
      refreshToken(otp: "${code}")
    }
  `,
  })
}

export const getAuthToken = async (token: string) =>
  await client.mutate({
    mutation: gql`
      mutation getAuthToken {
        accessToken(
          RefreshToken: "${token}"
        )
      }
    `,
  })

export const getUsers = async () =>
  await authQuery(
    `
    query getUsers {
      users(
        input: {from: null, limit: null}
        ) {
        totalCount
        edges {
          cursor
          edge {
            id
            name
            roles {
              role
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          nextCursor
          size
          hasNextPage
        }
      }
    }
  `,
    undefined,
  )

export const GET_CURRENT_USER_DATA = gql`
  query getCurrentUserData {
    me {
      id
      fullName
      givenName
      roles {
        role
        organization {
          id
          name
        }
      }
    }
  }
`

export const getOrganizations = async () =>
  await authQuery(
    `
    query getOrganizations {
      organizations(
        input: {from: null, limit: null}
      ) {
        totalCount
        edges {
          edge {
            id
            name
            employeeRange { range }
            status
            invites {
              id
            }
            plan {
              type
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          nextCursor
          size
          hasNextPage
        }
    }
  }
`,
    undefined,
  )

export const getSalesReps = gql`
  query getSalesReps {
    salesRep {
      id
      fullName
      givenName
    }
  }
`
