import React from 'react'
import tw from 'twin.macro'
import { ScopedRole } from '../../../GeneratedGraphQLTypes'
import { ReadableScopedRole, UserRecord } from './UserList'

const UnorderedList = tw.ul`list-none p-0 m-0 gap-2 flex`
const ListItem = tw.li`rounded-xl bg-vividBlue p-1 text-lightGrey text-xs`
// Genererate empty react functional component
export const ScopedRolesList = ({ record }: { record: UserRecord }) => {
  if (!record?.scopedRoles) return null
  const sortedScopedRoles = [...record.scopedRoles].sort((a, b) => a.localeCompare(b))
  return (
    <UnorderedList>
      {sortedScopedRoles.map((scopedRole: ScopedRole) => (
        <ListItem key={scopedRole}>{ReadableScopedRole[scopedRole]}</ListItem>
      ))}
    </UnorderedList>
  )
}
